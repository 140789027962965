export const headingDate = {
    fontWeight: "bold",
    textAlign: "center"
};

export const headingName = {
    fontWeight: "bold"
};

export const viewCell = {
    border: "solid 1px",
    textAlign: "center"
};
export const viewBtn = {
    color: "white"
};

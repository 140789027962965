import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

//STYLES
import * as StyleDesignStyles from "../styles/styleDesign";

import NavBar from "../../components/navBar/frontEnd/navBar";
import Slider from "../../components/slider/frontEnd/slider";
import SimpleSectionCenteredText from "../../components/simpleSectionCenteredText/frontEnd/simpleSectionCentredText";
import HalfPhoto from "../../components/halfPhoto/frontEnd/halfPhoto";
import VideoSection from "../../components/videoSection/frontEnd/video";
import PhotoRow from "../../components/photoRow/frontEnd/photoRow";
import FourSectionsOneRow from "../../components/fourSectionsOneRow/frontEnd/fourSectionsOneRow";
import EventsSlider from "../../components/eventsSlider/eventsSlider";
import Footer from "../../components/footer/footer";

function StyleDesign() {
    return (
        <div>
            <div style={StyleDesignStyles.section}>
                <div style={StyleDesignStyles.tag}>Section 1</div>
                <Slider />
            </div>
            <div style={StyleDesignStyles.section}>
                <div style={StyleDesignStyles.tag}>Section 2</div>
                <SimpleSectionCenteredText />
            </div>
            <div style={StyleDesignStyles.section}>
                <div style={StyleDesignStyles.tag}>Section 3</div>
                <HalfPhoto />
            </div>
            <div style={StyleDesignStyles.section}>
                <div style={StyleDesignStyles.tag}>Section 4</div>
                <FourSectionsOneRow />
            </div>
            <VideoSection />
            <div style={StyleDesignStyles.section}>
                <EventsSlider />
            </div>
            <div style={StyleDesignStyles.section}>
                <div style={StyleDesignStyles.tag}>Section 5</div>
                <PhotoRow />
            </div>
            <Footer />
        </div>
    );
}

export default StyleDesign;

import React, { useState, useEffect } from "react";
import { Row, Col, Form, Dropdown, Button, Modal, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as NewEventStyles from "../styles/event";

//COMPONENTS
import UploadComponent from "../../../../mainStyle/components/uploadComponent/config";

function Event(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const user = useSelector((state) => state.user);

    const [times, setTimes] = useState({ startTimes: [], endTimes: [] });

    const [details, setDetails] = useState({
        title: "",
        titleError: false,
        description: "",
        startDate: moment().toDate(),
        endDate: moment().toDate(),
        allDay: false,
        repeat: false,
        eventType: "repeat",
        repeatType: "",
        numOfWeeks_interval: 0,
        certainWeek_interval: 1,
        certainWeek_day: "",
        startTime: "08:00",
        endTime: "",
        imageFile: "",
        image: false,
        archived: false
    });

    const weeksMenu = [
        {
            id: 1,
            title: "First"
        },
        {
            id: 2,
            title: "Second"
        },
        {
            id: 3,
            title: "Third"
        },
        {
            id: 4,
            title: "Forth"
        }
    ];

    const certainWeekValues = [
        {
            week: 1,
            days: ["01", "02", "03", "04", "05", "06", "07"]
        },
        {
            week: 2,
            days: ["08", "09", "10", "11", "12", "13", "14"]
        },
        {
            week: 3,
            days: ["15", "16", "17", "18", "19", "20", "21"]
        },
        {
            week: 4,
            days: ["22", "23", "24", "25", "26", "27", "28"]
        }
    ];

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
        navigate(-1);
    }

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle(props.modify ? "Calendar - Modify Event" : "Calendar - New Event"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Calendar"));

        const startTimeSlots = [];
        let time = moment().startOf("day");
        const lastTime = moment().endOf("day");
        while (time.isBefore(lastTime)) {
            startTimeSlots.push(time.format("HH:mm"));
            time.add(30, "m");
        }

        const endTimeSlots = [];
        const startTime = moment(details.startTime, "HH:mm");
        startTime.add(30, "m");
        const endTime = moment(startTime);

        while (startTime.isBefore(lastTime)) {
            endTimeSlots.push(startTime.format("HH:mm"));
            startTime.add(30, "m");
        }

        setTimes({ startTimes: startTimeSlots, endTimes: endTimeSlots });

        let certainWeek_inter = 1;
        for (const week of certainWeekValues) {
            if (week.days.includes(moment(details.startDate, "DD/MM/YYYY").format("DD"))) {
                certainWeek_inter = week.week;
            }
        }
        let certainWeek_day = moment(details.startDate, "DD/MM/YYYY").format("dddd");

        setDetails((prevState) => {
            return { ...prevState, certainWeek_interval: certainWeek_inter, certainWeek_day: certainWeek_day, endTime: endTime.format("HH:mm") };
        });

        modifyLoad();
    }, []);

    function modifyLoad() {
        if (props.modify) {
            const data = { id: params.id };

            Axios.post("/pods/calendar/getSingleEvent", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.error == "null") {
                        const event = data.event;
                        let numOfWeeks_inter = 0;
                        let certainWeek_inter = 1;
                        if (event.repeatType == "numOfWeeks") {
                            numOfWeeks_inter = event.interval;
                        }
                        if (event.repeatType == "certainWeek") {
                            certainWeek_inter = event.interval;
                            numOfWeeks_inter = 1;
                        }

                        setDetails((prevState) => {
                            return {
                                ...prevState,
                                title: event.title,
                                startDate: moment(event.startDate, "DD/MM/YYYY").toDate(),
                                endDate: moment(event.endDate, "DD/MM/YYYY").toDate(),
                                description: event.description,
                                allDay: event.timeType == "allDay",
                                startTime: event.timeType == "setTimes" && event.startTime,
                                endTime: event.timeType == "setTimes" && event.endTime,
                                repeat: event.eventType != "single",
                                repeatType: event.repeatType,
                                numOfWeeks_interval: numOfWeeks_inter,
                                certainWeek_interval: certainWeek_inter,
                                certainWeek_day: moment(event.startDate, "DD/MM/YYYY").format("dddd"),
                                image: event.image != "---",
                                imageFile: event.image != "---" && `${params.id}${event.image}`,
                                archived: event.archive == "archived"
                            };
                        });

                        buildEndTimes(event.startTime);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleDateChange(type, date) {
        const values = { ...details };

        values[type] = date;

        //::
        for (const week of certainWeekValues) {
            if (week.days.includes(moment(date, "DD/MM/YYYY").format("DD"))) {
                values.certainWeek_interval = week.week;
            }
        }

        values.certainWeek_day = moment(date, "DD/MM/YYYY").format("dddd");

        setDetails(values);
    }

    function handleTimeCheck(event) {
        const { checked } = event.target;

        setDetails((prevState) => {
            return { ...prevState, allDay: checked };
        });
    }

    function handleRepeatCheck(event) {
        const { checked } = event.target;

        let numOfWeeks_interval = 0;
        let repeatType = "";

        if (checked) {
            numOfWeeks_interval = 1;
            repeatType = "numOfWeeks";
        }

        setDetails((prevState) => {
            return { ...prevState, repeat: checked, numOfWeeks_interval: numOfWeeks_interval, repeatType: repeatType };
        });
    }

    function handleChangeText(event) {
        const { name, value } = event.target;

        setDetails((prevState) => {
            return { ...prevState, [name]: value };
        });
    }

    function handleStartTimeSelect(time) {
        buildEndTimes(time);
        const endTime = moment(time, "HH:mm").add(30, "m");

        setDetails((prevState) => {
            return { ...prevState, startTime: time, endTime: endTime.format("HH:mm") };
        });
    }

    function buildEndTimes(time) {
        const endTime = moment(time, "HH:mm").add(30, "m");
        const lastTime = moment().endOf("day");
        const endTimeSlots = [];
        while (endTime.isBefore(lastTime)) {
            endTimeSlots.push(endTime.format("HH:mm"));
            endTime.add(30, "m");
        }

        setTimes((prevState) => {
            return { ...prevState, endTimes: endTimeSlots };
        });
    }

    function handleEndTimeSelect(time) {
        setDetails((prevState) => {
            return { ...prevState, endTime: time };
        });
    }

    function handleCreateEvent() {
        if (details.title == "") {
            setDetails((prevState) => {
                return { ...prevState, titleError: true };
            });
        } else {
            let timeType = "";
            if (details.allDay) {
                timeType = "allDay";
            } else {
                timeType = "setTimes";
            }

            let interval = "";
            if (details.repeatType == "numOfWeeks") {
                interval = details.numOfWeeks_interval.toString();
            }
            if (details.repeatType == "certainWeek") {
                interval = details.certainWeek_interval.toString();
            }

            const data = {
                userID: user.id,
                title: details.title,
                description: details.description,
                startDate: details.startDate,
                endDate: details.repeat ? details.endDate : details.startDate,
                timeType: timeType,
                startTime: details.allDay ? "---" : details.startTime,
                endTime: details.allDay ? "---" : details.endTime,
                eventType: details.repeat ? "repeat" : "single",
                repeatType: details.repeat ? details.repeatType : "",
                interval: details.repeat ? interval : "",
                image: details.image,
                imageFile: details.imageFile
            };
            Axios.post("/pods/calendar/submitNewEvent", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setModal({ header: "Calendar", message: data.message, open: true });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleUpdateClick() {
        if (details.title == "") {
            setDetails((prevState) => {
                return { ...prevState, titleError: true };
            });
        } else {
            let timeType = "";
            if (details.allDay) {
                timeType = "allDay";
            } else {
                timeType = "setTimes";
            }

            let interval = "";
            if (details.repeatType == "numOfWeeks") {
                interval = details.numOfWeeks_interval.toString();
            }
            if (details.repeatType == "certainWeek") {
                interval = details.certainWeek_interval.toString();
            }

            const data = {
                id: params.id,
                title: details.title,
                description: details.description,
                startDate: details.startDate,
                endDate: details.repeat ? details.endDate : details.startDate,
                timeType: timeType,
                startTime: details.allDay ? "---" : details.startTime,
                endTime: details.allDay ? "---" : details.endTime,
                eventType: details.repeat ? "repeat" : "single",
                repeatType: details.repeat ? details.repeatType : "",
                interval: details.repeat ? interval : ""
            };
            Axios.post("/pods/calendar/updateSingleEvent", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setModal({ header: "Calendar", message: data.message, open: true });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleTitleOnBlur() {
        if (details.title != "") {
            setDetails((prevState) => {
                return { ...prevState, titleError: false };
            });
        }
    }

    function handleRepeatCheckChange(type) {
        setDetails((prevState) => {
            return { ...prevState, repeatType: type };
        });
    }

    function handleCertainWeekChange(week) {
        setDetails((prevState) => {
            return { ...prevState, certainWeek_interval: week };
        });
    }

    function handleDeleteEvent(uuid) {
        setModalYN({
            heading: "Delete Event?",
            message: "Are you sure you want to delete this event?",
            showAccept: true,
            acceptName: "Yes",
            acceptFunction: handleDeleteEvent_Accepted.bind(this, uuid),
            showCancel: true,
            cancelName: "No",
            open: true
        });
    }

    function handleDeleteEvent_Accepted(uuid) {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });

        const data = { uuid: uuid };
        Axios.post("/pods/calendar/deleteEvent", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    navigate(-1);
                }
            })
            .catch((err) => console.log(err));
    }

    function nextFunction(reCallData, files) {
        setDetails((prevState) => {
            return { ...prevState, image: true, imageFile: files[0] };
        });
    }

    function modifyNextFunction(reCallData, files) {
        const data = { userID: reCallData.userID, eventID: reCallData.eventID, files: files };

        Axios.post("/pods/calendar/modifyNextFunction", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    modifyLoad();
                }
            })
            .catch((err) => console.log(err));
    }

    function handleDeleteImage() {
        setModalYN({
            heading: "Delete Image?",
            message: "Are you sure you want delete the image from this event?",
            showAccept: true,
            acceptName: "Yes",
            acceptFunction: handleDeleteImage_Accepted,
            showCancel: true,
            cancelName: "No",
            open: true
        });
    }

    function handleDeleteImage_Accepted() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });

        const data = { userID: user.id, eventID: params.id };
        Axios.post("/pods/calendar/deleteEventImage", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    modifyLoad();
                }
            })
            .catch((err) => console.log());
    }

    return (
        <div style={NewEventStyles.body}>
            {details.archived && (
                <div>
                    <Row>
                        <Col style={NewEventStyles.archivedNotice}>This event is archived</Col>
                    </Row>
                </div>
            )}
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={NewEventStyles.formTitles}>Title:</Form.Label>
                                <Form.Control
                                    isInvalid={details.titleError}
                                    type="text"
                                    name="title"
                                    value={details.title}
                                    onChange={handleChangeText}
                                    onBlur={handleTitleOnBlur}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a title</Form.Control.Feedback>
                            </Form.Group>
                            <br />
                            <Form.Group>
                                <Form.Label style={NewEventStyles.formTitles}>Description:</Form.Label>
                                <Form.Control as="textarea" rows={5} name="description" value={details.description} onChange={handleChangeText} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            Image:
                            {props.modify ? (
                                <div>
                                    <UploadComponent
                                        btnText={details.image ? "Update" : "Upload"}
                                        isImage={true}
                                        fileType={"n/a"}
                                        fileLimit={1}
                                        fileSize={8}
                                        multiple={false}
                                        reCallData={{ userID: user.id, eventID: params.id }}
                                        directoryPath={`temp/${user.id}`}
                                        nextFunction={modifyNextFunction}
                                        fileMessage={"Images only"}
                                        showFileText
                                    />
                                </div>
                            ) : (
                                <div>
                                    <UploadComponent
                                        isImage={true}
                                        fileType={"n/a"}
                                        fileLimit={1}
                                        fileSize={8}
                                        multiple={false}
                                        reCallData={{}}
                                        directoryPath={`temp/${user.id}`}
                                        nextFunction={nextFunction}
                                        fileMessage={"Images only"}
                                        showFileText
                                    />
                                </div>
                            )}
                        </Col>
                        {details.image && (
                            <Col sm={2} style={NewEventStyles.imageDeleteContainer}>
                                <Button variant="danger" onClick={handleDeleteImage}>
                                    <i className="fa-solid fa-trash-can"></i>
                                </Button>
                            </Col>
                        )}
                    </Row>

                    {details.image && (
                        <div>
                            <br />
                            <Row>
                                <Col>
                                    {props.modify ? (
                                        <Image style={NewEventStyles.image} src={`/content/events/${details.imageFile}`} />
                                    ) : (
                                        <Image style={NewEventStyles.image} src={`/content/temp/${user.id}/${details.imageFile}`} />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    )}
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={NewEventStyles.formTitles}>Date:</Form.Label> <br />
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={details.startDate}
                                    onChange={(date: Date) => handleDateChange("startDate", date)}
                                />
                            </Form.Group>
                        </Col>
                        <Col style={NewEventStyles.columnRight}>
                            {props.modify && (
                                <Button variant="danger" onClick={handleDeleteEvent.bind(this, params.id)}>
                                    <i className="fa-solid fa-trash-can"></i>
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <br />
                    <Form.Group>
                        <Form.Label style={NewEventStyles.formTitles}>Time:</Form.Label> <br />
                        <Form.Check type="checkbox" label="All Day" name="allDay" checked={details.allDay} onChange={handleTimeCheck} />
                    </Form.Group>
                    <Form.Group>
                        <div style={!details.allDay ? NewEventStyles.show : NewEventStyles.hidden}>
                            <Row>
                                <Col sm={2}>
                                    <Form.Label style={NewEventStyles.formDropdownTitle}>Start:</Form.Label>
                                </Col>
                                <Col>
                                    <Dropdown>
                                        <Dropdown.Toggle>{details.startTime}</Dropdown.Toggle>
                                        <Dropdown.Menu style={NewEventStyles.dropdownMenu}>
                                            {times.startTimes.map((time, index) => {
                                                return (
                                                    <Dropdown.Item key={index} onClick={handleStartTimeSelect.bind(this, time)}>
                                                        {time}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col sm={2}>
                                    <Form.Label style={NewEventStyles.formDropdownTitle}>End:</Form.Label>
                                </Col>
                                <Col>
                                    <Dropdown>
                                        <Dropdown.Toggle>{details.endTime}</Dropdown.Toggle>
                                        <Dropdown.Menu style={NewEventStyles.dropdownMenu}>
                                            {times.endTimes.map((time, index) => {
                                                return (
                                                    <Dropdown.Item key={index} onClick={handleEndTimeSelect.bind(this, time)}>
                                                        {time}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label style={NewEventStyles.formTitles}>Recurrence</Form.Label> <br />
                        <Form.Check
                            type="checkbox"
                            label="This event does repeats"
                            name="repeat"
                            checked={details.repeat}
                            onChange={handleRepeatCheck}
                        />
                        {details.repeat && (
                            <div>
                                <br />
                                <div>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col sm={1} style={NewEventStyles.recurrRadio}>
                                                    <Form.Check
                                                        type="radio"
                                                        name="numOfWeeks"
                                                        checked={details.repeatType == "numOfWeeks" ? true : false}
                                                        onChange={handleRepeatCheckChange.bind(this, "numOfWeeks")}
                                                        label=" "
                                                    />
                                                </Col>
                                                <Col style={NewEventStyles.repeatNumSection}>
                                                    Every
                                                    <Form.Control
                                                        type="text"
                                                        name="numOfWeeks_interval"
                                                        onChange={handleChangeText}
                                                        value={details.numOfWeeks_interval}
                                                        style={NewEventStyles.repeatNumTextBox}
                                                        maxLength={2}
                                                    />
                                                    weeks
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col sm={1} style={NewEventStyles.recurrRadio}>
                                                    <Form.Check
                                                        type="radio"
                                                        name="certainWeek"
                                                        checked={details.repeatType == "certainWeek" ? true : false}
                                                        onChange={handleRepeatCheckChange.bind(this, "certainWeek")}
                                                        label=" "
                                                    />
                                                </Col>
                                                <Col style={NewEventStyles.repeatNumSection}>
                                                    Every {weeksMenu.find((week) => week.id == details.certainWeek_interval).title}{" "}
                                                    {details.certainWeek_day}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label style={NewEventStyles.formTitles}>Until:</Form.Label> <br />
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={details.endDate}
                                                    onChange={(date: Date) => handleDateChange("endDate", date)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )}
                    </Form.Group>

                    <br />
                    {props.modify ? <Button onClick={handleUpdateClick}>Update</Button> : <Button onClick={handleCreateEvent}>Create</Button>}
                </Col>
            </Row>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Event;

export const body = {
    paddingTop: "20px",
    paddingRight: "20px",
    paddingLeft: "20px"
};

//event view
export const title = {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: "bold"
};

export const imageContainer = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const image = {
    width: "70%"
};

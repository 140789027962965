import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

//STYLES
import * as ContactPodStyles from "../styles/contactPod";
import * as adminLoginStyles from "../../security/styles/login"; //CROSS-OVER POD LINK

// const { REACT_APP_ReCAPTCHA_KEY } = process.env;

function ContactPod(props) {
    const reRef = useRef();
    const [names, setNames] = useState({
        one: "1",
        two: "",
        three: "",
        four: ""
    });

    const [errorMessage, setErrorMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const [details, setDetails] = useState({
        name: "",
        email: "",
        address: "",
        telephone: "",
        showDetails: false
    });

    useEffect(() => {
        onStart();
    }, []);

    function onStart() {
        const data = { id: props.id };
        Axios.post("/adminPods/pageViewer/contactPod/onLoad", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setNames({
                        one: data.security.one,
                        two: data.security.two,
                        three: data.security.three,
                        four: data.security.four
                    });

                    setDetails({
                        name: data.details.name,
                        email: data.details.email,
                        address: data.details.address,
                        telephone: data.details.telephone,
                        showDetails: data.details.name != "" || data.details.email != "" || data.details.address != "" || data.details.telephone != ""
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleSendForm() {
        setErrorMessage("");
        const one = document.getElementById(names.one).value;
        const two = document.getElementById(names.two).value;
        const three = document.getElementById(names.three).value;
        const four = document.getElementById(names.four).value;
        const token = reRef.current.getValue();

        if (one == "") {
            setErrorMessage("Please enter a name");
            return;
        }
        if (two == "") {
            setErrorMessage("Please enter an email");
            return;
        }
        if (three == "") {
            setErrorMessage("Please enter a subject");
            return;
        }
        if (four == "") {
            setErrorMessage("Please enter a message");
            return;
        }

        if (token == "") {
            setErrorMessage("Please check if you're a human");
        } else {
            const data = {
                one: one,
                two: two,
                three: three,
                four: four,
                token: token,
                id: props.id
            };
            Axios.post("/adminPods/pageViewer/contactPod/onSubmitForm", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "Yes") {
                        setErrorMessage(data.message);
                    } else {
                        console.log(data);
                        setSubmitted(true);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    return (
        <div>
            <div style={ContactPodStyles.submitMessage}>
                <strong>Contact Form</strong>
            </div>
            {submitted ? (
                <div style={ContactPodStyles.submitMessage}>
                    <br />
                    <h2>Thank you for your query</h2>
                    <br />
                    <h2>Someone will get back in touch!</h2>
                </div>
            ) : (
                <div>
                    {props.width <= 4 ? (
                        <div>
                            {details.showDetails && (
                                <div>
                                    <p style={ContactPodStyles.submitMessage}>Please use the contact details below or fill in the form</p>
                                    <Row>
                                        <Col>{details.name != "" && `Name: ${details.name}`}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{details.email != "" && `Email: ${details.email}`}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{details.address != "" && `Address: ${details.address}`}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{details.telephone != "" && `Telephone: ${details.telephone}`}</Col>
                                    </Row>
                                    <br />
                                </div>
                            )}
                            <Row>
                                <Col>Name:</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control type="text" id={names.one} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>Email:</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control type="text" id={names.two} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>Subject:</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control type="text" id={names.three} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>Message:</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control as="textarea" rows={3} id={names.four} />
                                </Col>
                            </Row>
                            <br />
                        </div>
                    ) : (
                        <div>
                            {details.showDetails && (
                                <div>
                                    <p style={ContactPodStyles.submitMessage}>Please use the contact details below or fill in the form</p>
                                    <Row>
                                        <Col>{details.name != "" && `Name: ${details.name}`}</Col>
                                        <Col>{details.email != "" && `Email: ${details.email}`}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{details.address != "" && `Address: ${details.address}`}</Col>
                                        <Col>{details.telephone != "" && `Telephone: ${details.telephone}`}</Col>
                                    </Row>
                                    <br />
                                </div>
                            )}
                            <Row>
                                <Col sm={3} style={ContactPodStyles.headings}>
                                    Name:
                                </Col>
                                <Col sm={9}>
                                    <Form.Control type="text" id={names.one} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col sm={3} style={ContactPodStyles.headings}>
                                    Email:
                                </Col>
                                <Col sm={9}>
                                    <Form.Control type="text" id={names.two} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col sm={3} style={ContactPodStyles.headings}>
                                    Subject:
                                </Col>
                                <Col sm={9}>
                                    <Form.Control type="text" id={names.three} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col sm={3} style={ContactPodStyles.headings}>
                                    Message:
                                </Col>
                                <Col sm={9}>
                                    <Form.Control as="textarea" rows={3} id={names.four} />
                                </Col>
                            </Row>
                            <br />
                        </div>
                    )}
                    <Row>
                        <Col style={ContactPodStyles.sendBtn}>
                            <div style={ContactPodStyles.reCAPTCHA}>
                                <ReCAPTCHA sitekey={""} ref={reRef} />
                            </div>
                            <br />
                            <p style={ContactPodStyles.errorMessage}>{errorMessage}</p>
                            <Button variant="warning" style={adminLoginStyles.loginBtns} onClick={handleSendForm}>
                                Send
                            </Button>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default ContactPod;

import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import Axios from "axios";
import { useDispatch } from "react-redux";

//ACTIONS
import * as NavBarActions from "../../../../envStore/actions/navBar";

//STYLES
import * as SecondPageStyles from "./styles";

//COMPONENTS
import PageViewer from "../../../../../administration/pods/pageViewer/pageViewer";
import FrontEndMainContact from "../../../../../administration/pods/contacts/files/frontEndMainContact";
import EnvironmentPodsMap from "../../../../pods/environmentPodsMap";

function SecondPage(props) {
    const dispatch = useDispatch();

    const [fileName, setFileName] = useState("");

    useEffect(() => {
        dispatch(NavBarActions.UpdateTextColor("white"));
        Axios.post("/style/subPagesPhotos/loadPhoto")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setFileName(data.fileName);
                }
            })
            .catch((err) => console.log(err));
    }, [props.pageID]);

    return (
        <div>
            <Image src={`/content/mainStyle/subPagesPhotos/${fileName}`} style={SecondPageStyles.topImage} />
            {props.element ? (
                <div>
                    {props.element == "mainContact" && <FrontEndMainContact />}
                    {props.element == "calendar" && <EnvironmentPodsMap podCode="CPOD_CALENDAR_FREND" />}
                </div>
            ) : (
                <div>
                    <PageViewer pod={props.pod} pageID={props.pageID} />
                </div>
            )}
        </div>
    );
}

export default SecondPage;

export const body = {
  paddingRight: "30px",
  paddingTop: "20px",
};

export const titleSize = {
  fontSize: "1.5rem",
};

export const textGroup = {
  display: "flex",
  flex: "1",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "10px",
};

export const counter = {
  textAlign: "right",
  color: "red",
};

export const columnRight = {
  textAlign: "right",
};

export const updateButton = {
  transition: "all 0.2s ease-out",
};

export const editorWrapper = {
  backgroundColor: "white",
  width: "100%",
};

export const editorStyle = {
  minHeight: "150px",
  maxHeight: "150px",
  overflowY: "scroll",
  cursor: "text",
};

import React from "react";
import { Image } from "react-bootstrap";

//STYLES
import * as EventsSliderStyles from "./styles";

function EventSlot(props) {
    return (
        <div
            style={{
                ...EventsSliderStyles.eventSlot,
                ...props.transitionStyle,
                width: `${props.eventSlotWidth}px`,
                marginLeft: `${props.slotPosition}px`
            }}
        >
            <div style={EventsSliderStyles.smallContainer}>
                <strong>{props.data.title}</strong>
                <strong>{props.data.date}</strong>
            </div>
            {props.data.image != "---" && (
                <div style={EventsSliderStyles.imageContainer}>
                    <Image src={props.data.image} style={EventsSliderStyles.image} />
                </div>
            )}
            <div style={EventsSliderStyles.timeContainer}>{props.data.time}</div>
        </div>
    );
}

export default EventSlot;

import React, { useState, useEffect } from "react";
import { Modal, Button, Dropdown, Row, Col } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as BlogPostsViewerStyles from "../styles/blogPostsViewer";

function BlogSettings(props) {
    const [order, setOrder] = useState("newest");
    const [view, setView] = useState("index");

    useEffect(() => {
        if (props.open) {
            const data = { uuid: props.uuid };
            Axios.post("/pods/CPOD_BLOGS_ADMIN/getBlogSettings", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setOrder(data.settings.blogOrder);
                        setView(data.settings.blogView);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [props.open]);

    function CapsFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function handleOrderItem(item) {
        setOrder(item);
    }

    function handleViewItem(item) {
        setView(item);
    }

    function handleSaveBtn() {
        const data = { uuid: props.uuid, order: order, view: view };
        Axios.post("/pods/CPOD_BLOGS_ADMIN/saveSettings", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    props.close();
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div>
            <Modal show={props.open} onHide={props.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={3} style={BlogPostsViewerStyles.blogSettingsTitles}>
                            Order:
                        </Col>
                        <Col>
                            <Dropdown>
                                <Dropdown.Toggle>{`${CapsFirstLetter(order)} First`}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleOrderItem.bind(this, "newest")}>Newest First</Dropdown.Item>
                                    <Dropdown.Item onClick={handleOrderItem.bind(this, "oldest")}>Oldest First</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3} style={BlogPostsViewerStyles.blogSettingsTitles}>
                            View:
                        </Col>
                        <Col>
                            <Dropdown>
                                <Dropdown.Toggle>{`${CapsFirstLetter(view)} View`}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleViewItem.bind(this, "index")}>Index View</Dropdown.Item>
                                    <Dropdown.Item onClick={handleViewItem.bind(this, "display")}>Display View</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSaveBtn}>Save</Button>
                    <Button onClick={props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default BlogSettings;

//import * as Colors from "../../../../../../../../../globalStyles/administratorColors";
import * as Colors from "../../../../globalStyles/administratorColors";

export const body = {
    paddingRight: "30px",
    paddingTop: "20px"
};

export const columns = {
    textAlign: "center",
    fontSize: "1.5rem"
};

export const templateTitle = {
    fontSize: "1.5rem"
};

export const templateBorder = {
    border: "1px solid black"
};

export const textColumns = {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "10px"
};

export const columnsHeading = {
    textAlign: "center",
    fontWeight: "bold"
};

export const sectionsHeadings = {
    textAlign: "center",
    fontWeight: "bold",
    backgroundColor: Colors.primary,
    color: "white",
    marginBottom: "10px"
};

export const counter = {
    textAlign: "right",
    color: "red"
};

export const columnRight = {
    textAlign: "right"
};

export const updateButton = {
    transition: "all 0.2s ease-out"
};

import React from "react";
import { Row, Col } from "react-bootstrap";

//STYLES
import * as FooterStyles from "./styles";

function Footer() {
    return (
        <div style={FooterStyles.body}>
            <div className="titleFont" style={FooterStyles.title}>
                St Giles
            </div>
            <br />
            <br />
            <div style={FooterStyles.produced}>
                Produced by High-View Studios, <br />
                GDPR on this website is covered by High-View Studios&apos; policies
            </div>
        </div>
    );
}

export default Footer;

import React, { useState, useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

//ACTIONS
import * as NavBarActions from "../../../../envStore/actions/navBar";

//STYLES
import * as AlbumViewerStyles from "../styles/albumViewer";

//COMPONENTS
import ImageSlot from "./imageSlot";

function AlbumViewer(props) {
    const params = useParams();
    const dispatch = useDispatch();

    const [view, setView] = useState("va");
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(0);

    useEffect(() => {
        dispatch(NavBarActions.UpdateTextColor("black"));

        const data = { album: params.name };
        Axios.post("/pods/CPOD_PHOTOGALLERY_FREND/getAlbumImages", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setImages(data.images);
                }
            })
            .catch((err) => console.log(err));
    }, [params.name]);

    function handleSetView(view) {
        setView(view);
    }

    function handleThumbnailOnClick(index) {
        setSelectedImage(index);
    }

    function handleNextPhoto() {
        let newSelect = selectedImage + 1;

        if (newSelect > images.length - 1) {
            newSelect = 0;
        }

        setSelectedImage(newSelect);
        document.querySelector(`#photo-${newSelect}`).scrollIntoView({ behavior: "smooth", block: "nearest" });
    }

    function handlePreviousPhoto() {
        let newSelect = selectedImage - 1;

        if (newSelect < 0) {
            newSelect = images.length - 1;
        }

        setSelectedImage(newSelect);
        document.querySelector(`#photo-${newSelect}`).scrollIntoView({ behavior: "smooth", block: "nearest" });
    }

    function handleViewAllImageClick(id) {
        setSelectedImage(id);
        setView("vo");
        setTimeout(() => {
            document.querySelector(`#photo-${id}`).scrollIntoView({ behavior: "smooth", block: "nearest" });
        }, 100);
    }

    const viewAllView = (
        <div>
            <Row>
                {images.map((image, index) => {
                    return (
                        <Col sm={3} key={index}>
                            <ImageSlot album={params.name} image={image} onClick={handleViewAllImageClick} id={index} />
                        </Col>
                    );
                })}
            </Row>
        </div>
    );

    const viewOneView = (
        <div>
            <Row>
                <Col sm={2} style={AlbumViewerStyles.sideColumn}>
                    <i className="fa-solid fa-chevron-left fa-2xl" style={AlbumViewerStyles.direcionPointer} onClick={handlePreviousPhoto}></i>
                </Col>
                <Col sm={8}>
                    <Row>
                        <Col style={AlbumViewerStyles.viewOneMiddle}>
                            <Image src={`/content/gallery/${params.name}/${images[selectedImage]}`} style={AlbumViewerStyles.image} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={AlbumViewerStyles.imagesPanel}>
                            {images.map((image, index) => {
                                return (
                                    <div key={index} style={AlbumViewerStyles.thumbnailContainer}>
                                        <Image
                                            id={`photo-${index}`}
                                            src={`/content/gallery/${params.name}/${image}`}
                                            style={selectedImage == index ? AlbumViewerStyles.thumbnailSelected : AlbumViewerStyles.thumbnail}
                                            onClick={handleThumbnailOnClick.bind(this, index)}
                                        />
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>
                </Col>
                <Col sm={2} style={AlbumViewerStyles.sideColumn}>
                    <i className="fa-solid fa-chevron-right fa-2xl" style={AlbumViewerStyles.direcionPointer} onClick={handleNextPhoto}></i>
                </Col>
            </Row>
        </div>
    );

    return (
        <div style={AlbumViewerStyles.body}>
            <Row>
                <Col sm={2} style={AlbumViewerStyles.leftColumn}>
                    <div
                        style={
                            view == "va"
                                ? { ...AlbumViewerStyles.viewAllButton, backgroundColor: "blue", color: "white" }
                                : AlbumViewerStyles.viewAllButton
                        }
                        onClick={handleSetView.bind(this, "va")}
                    >
                        View All
                    </div>
                    <div
                        style={
                            view == "vo"
                                ? { ...AlbumViewerStyles.viewOneButton, backgroundColor: "blue", color: "white" }
                                : AlbumViewerStyles.viewOneButton
                        }
                        onClick={handleSetView.bind(this, "vo")}
                    >
                        View One
                    </div>
                </Col>
                <Col>
                    {view == "va" && viewAllView}
                    {view == "vo" && viewOneView}
                </Col>
                <Col sm={2}></Col>
            </Row>
        </div>
    );
}

export default AlbumViewer;

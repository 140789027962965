import React, { useState } from "react";
import { Row, Col, Image, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";

import * as MediaActions from "../../../store/actions/media";
import * as GS_contentSettingsActions from "../../../store/actions/globalSettings/GS_contentSettings";

import Media_MoveModal from "./moveModal";

//STYLES
import * as CardStyles from "./styles/card";
import * as UploadStyles from "./styles/upload";

//IMAGE
import dirIcon from "../../../public/images/DirectoryIcon.png";
import goUpIcon from "../../../public/images/goUp.png";

function Media_Card(props) {
    const [over, setOver] = useState(false);
    const [imageOver, setImageOver] = useState(false);

    const media = useSelector((state) => state.media);

    const GS_contentSettings = useSelector((state) => state.GS_contentSettings);
    const dispatch = useDispatch();

    const [imagePreview, setImagePreview] = useState({
        imageUrl: "",
        open: false
    });

    function handleCloseImagePreview() {
        setImagePreview((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [moveFileModal, setMoveFileModal] = useState({
        fileUrl: "",
        open: false
    });

    function handleCloseFileMove() {
        setMoveFileModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: "",
        error: false
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleOnMoveOver() {
        setOver(true);
    }

    function handleOnMoveLeave() {
        setOver(false);
    }

    function handleImageOnMoveOver() {
        setImageOver(true);
    }

    function handleImageOnMoveLeave() {
        setImageOver(false);
    }

    function handleDirectoryClick(directory) {
        const newPath = `${media.currentURI}/${directory}`;
        dispatch(MediaActions.UpdateCurrentUri(newPath));
        props.getDirectoryFiles(newPath);
    }

    function handleDirectoryUpClick() {
        const newPath = `${media.currentURI}/..`;
        props.getDirectoryFiles(newPath);
    }

    function truncateString(str, size) {
        return str.length > size ? str.substr(0, size - 1) + "..." : str;
    }

    function handleOpenImagePreview(url) {
        setImagePreview({ imageUrl: url, open: true });
    }

    function handleDeleteImage(imageUrl) {
        setModalYN({
            heading: "Delete Image",
            message: "Are you sure you want to delete this image?",
            showAccept: true,
            acceptName: "Yes",
            acceptFunction: acceptDeleteImage.bind(this, imageUrl),
            showCancel: true,
            cancelName: "No",
            open: true
        });
    }

    function handleDeleteDirectory(dirUrl) {
        setModalYN({
            heading: "Delete Image",
            message: "Are you sure you want to delete this directory and it's contents?",
            showAccept: true,
            acceptName: "Yes",
            acceptFunction: acceptDeleteImage.bind(this, dirUrl),
            showCancel: true,
            cancelName: "No",
            open: true
        });
    }

    function acceptDeleteImage(imageUrl) {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });

        const data = {
            imageUrl: imageUrl,
            directory: media.currentURI
        };
        Axios.post("/adminPods/ADMIN_MEDIA/deleteImage", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    dispatch(MediaActions.UpdateFiles(data.files.entries, data.files.empty, data.files.subDir));
                    dispatch(MediaActions.UpdateCardAndRowTotals(data.files.entries.length));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleOpenMoveFile(file) {
        dispatch(MediaActions.Update_Move_File(file));
        dispatch(MediaActions.Update_Move_FilePath(media.currentURI));
        setMoveFileModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleMoveFileClick() {
        const data = {
            filename: media.move_file,
            currentLocation: media.currentURI,
            newLocation: media.move_filePath
        };
        Axios.post("/adminPods/ADMIN_MEDIA/moveFile", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setMoveFileModal((prevState) => {
                        return { ...prevState, open: false };
                    });
                    dispatch(MediaActions.UpdateCurrentUri(media.move_filePath));
                    props.getDirectoryFiles(media.move_filePath);
                } else {
                    setModal({
                        error: true,
                        header: "Move File",
                        message: data.message,
                        open: true
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleInsertImageClick(url) {
        props.insertImageClick(url);
        handleCloseImagePreview();
    }

    return (
        <div onMouseOver={handleOnMoveOver} onMouseLeave={handleOnMoveLeave}>
            {props.show ? (
                <div>
                    {props.directory || props.up ? (
                        <div>
                            {props.directory ? (
                                //Directory Icon
                                <div>
                                    {props.editable ? (
                                        <div>
                                            <Row>
                                                <Col style={!over ? CardStyles.btnsHidden : CardStyles.buttons}>
                                                    <Button title="Move" onClick={handleOpenMoveFile.bind(this, props.text)}>
                                                        <i className="fa-solid fa-angle-right"></i>
                                                    </Button>
                                                    <Button title="Delete" onClick={handleDeleteDirectory.bind(this, props.url)}>
                                                        <i className="fas fa-trash-alt"></i>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ) : null}

                                    <Row>
                                        <Col style={imageOver ? CardStyles.enlargeText : CardStyles.enlargeTextHidden}>Click to Enter</Col>
                                    </Row>
                                    <Row>
                                        <Col style={CardStyles.dirImgContainer} onClick={handleDirectoryClick.bind(this, props.text)}>
                                            <Image
                                                style={CardStyles.dirPic}
                                                src={dirIcon}
                                                onMouseOver={handleImageOnMoveOver}
                                                onMouseLeave={handleImageOnMoveLeave}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            style={!over ? CardStyles.dirTextContainer : CardStyles.dirTextContainerUnderlined}
                                            onClick={handleDirectoryClick.bind(this, props.text)}
                                        >
                                            {truncateString(props.text, 20)}
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                //GoUp Icon
                                <div>
                                    <Row>
                                        <Col style={CardStyles.directoryTop} onClick={handleDirectoryUpClick}></Col>
                                    </Row>
                                    <Row>
                                        <Col style={CardStyles.emptyEnlargeTextContainter}></Col>
                                    </Row>
                                    <Row>
                                        <Col style={CardStyles.dirImgContainer} onClick={handleDirectoryUpClick}>
                                            <Image style={CardStyles.dirPic} src={goUpIcon} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={CardStyles.dirTextContainer} onClick={handleDirectoryUpClick}></Col>
                                    </Row>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            {props.editable ? (
                                <div>
                                    <Row>
                                        <Col style={!over ? CardStyles.btnsHidden : CardStyles.buttons}>
                                            <Button title="Move" onClick={handleOpenMoveFile.bind(this, props.text)}>
                                                <i className="fa-solid fa-angle-right"></i>
                                            </Button>
                                            <Button title="Delete" onClick={handleDeleteImage.bind(this, props.url)}>
                                                <i className="fas fa-trash-alt"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            ) : null}

                            <Row>
                                <Col style={imageOver ? CardStyles.enlargeText : CardStyles.enlargeTextHidden}>Click to Enlarge</Col>
                            </Row>
                            <Row>
                                <Col style={CardStyles.imgContainer}>
                                    <Image
                                        style={CardStyles.img}
                                        src={`/content/${props.url}`}
                                        onClick={handleOpenImagePreview.bind(this, props.url)}
                                        onMouseOver={handleImageOnMoveOver}
                                        onMouseLeave={handleImageOnMoveLeave}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={!over ? CardStyles.textContainerHidden : CardStyles.textContainer}>{truncateString(props.text, 20)}</Col>
                            </Row>
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <Row>
                        <Col style={CardStyles.emptyTopContainer}></Col>
                    </Row>
                    <Row>
                        <Col style={CardStyles.emptyEnlargeTextContainter}></Col>
                    </Row>
                    <Row>
                        <Col style={CardStyles.emptyPicContainer}></Col>
                    </Row>
                    <Row>
                        <Col style={CardStyles.emptyBtnContainer}></Col>
                    </Row>
                </div>
            )}
            <Modal size="lg" show={imagePreview.open} onHide={handleCloseImagePreview}>
                <Modal.Header closeButton>
                    <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {media.gallerySideBar_open ? (
                        <div style={CardStyles.insertImageButton}>
                            <Button onClick={handleInsertImageClick.bind(this, imagePreview.imageUrl)}>Insert</Button>
                        </div>
                    ) : null}
                    Image Location: {imagePreview.imageUrl.replace("/media", "")}
                    <Image style={CardStyles.imagePreviewWidth} src={`/content/${imagePreview.imageUrl}`} />
                </Modal.Body>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={moveFileModal.open} onHide={handleCloseFileMove}>
                <Modal.Header closeButton>
                    <Modal.Title>Move File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Media_MoveModal />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseFileMove}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleMoveFileClick}>
                        Move
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Media_Card;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "axios";

//COMPONENTS
import SubPageBase from "../../subPagesPhotos/frontEnd/subPageBase";

function Navigation() {
    const params = useParams();
    const navigate = useNavigate();
    const [pageID, setPageID] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const data = { name: params.name };
        Axios.post("/style/SPOD_PHOTOROW_ADMIN/frontEnd/getPageID", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setPageID(data.pageID);
                    setIsLoaded(true);
                } else if (data.error == "Yes") {
                    navigate(-1);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    return <div>{isLoaded && <SubPageBase pod={"SPOD_PHOTOROW_ADMIN"} pageID={pageID} />}</div>;
}

export default Navigation;

export const cardBody = {
    width: "200px",
    height: "200px",
    border: "1px solid black",
    cursor: "pointer",
    zIndex: "0",
    position: "relative"
};

export const imageBody = {
    width: "200px",
    height: "225px",
    border: "1px solid black",
    zIndex: "0",
    position: "relative"
};

export const cardTopBackground = {
    // border: "1px solid black",
    width: "175px",
    height: "25px"
};

export const cardPinBackground = {
    // border: "1px solid black",
    width: "25px",
    height: "25px",
    position: "absolute",
    right: "0",
    top: "0",
    cursor: "pointer"
};

export const cardMainBackground = {
    // border: "1px solid black",
    width: "200px",
    height: "175px"
};

export const pin = {
    width: "20px",
    height: "20px",
    border: "1px solid black",
    position: "absolute",
    borderRadius: "20px",
    bottom: "0px",
    cursor: "pointer"
};

export const pinned = {
    width: "20px",
    height: "20px",
    border: "1px solid black",
    backgroundColor: "yellow",
    position: "absolute",
    borderRadius: "20px",
    bottom: "0px",
    cursor: "pointer"
};

export const imageCardTopHidden = {
    height: "50px",
    visibility: "hidden"
};

export const imageCardTopShow = {
    height: "50px",
    visibility: "visible",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
};

export const cardPicture = {
    height: "135px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const cardText = {
    height: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const albumImage = {
    width: "130px",
    height: "130px"
};

export const column = {
    height: "300px",
    display: "flex",
    flex: "1",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FAF3F3"
};

export const section = {
    border: "3px solid red",
    width: "70%",
    height: "80%",
    cursor: "pointer"
};

export const imageContainer = {
    width: "100%",
    height: "80%",
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
};

export const textContainer = {
    width: "100%",
    height: "20%",
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
};

export const image = {
    width: "80%",
    height: "80%"
};

import * as ReducerActions from "../actions/navBar";

const initialState = {
    textColor: "white"
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReducerActions.UPDATE_TEXT_COLOR:
            return { ...state, textColor: action.value };
        default:
            return state;
    }
};

export default reducer;

import * as ReducerActions from "../../actions/globalSettings/GS_contentSettings";

const initialState = {
    images: [],
    deleted: [],
    id: "",

    imageEdit_Open: false,
    imageEdit_ID: -1
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReducerActions.GSCONTENTSETTINGS_UPDATEIMAGES:
            return { ...state, images: action.value };
        case ReducerActions.GSCONTENTSETTINGS_UPDATEID:
            return { ...state, id: action.value };
        case ReducerActions.GSCONTENTSETTINGS_INSERTDELETED:
            return { ...state, deleted: [...state.deleted, action.value] };
        case ReducerActions.GSCONTENTSETTINGS_IMAGEEDIT_OPEN:
            return { ...state, imageEdit_Open: true, imageEdit_ID: action.id };
        case ReducerActions.GSCONTENTSETTINGS_IMAGEEDIT_CLOSE:
            return { ...state, imageEdit_Open: false, imageEdit_ID: -1 };
        default:
            return state;
    }
};

export default reducer;

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as CookieBannerStyles from "./styles";

function CookieBanner(props) {
    const [overButtons, setOverButtons] = useState({
        accept: false,
        decline: false
    });
    const [top, setTop] = useState(-30);

    useEffect(() => {
        if (props.show == 0) {
            setTop(0);
            props.changeTop("down");
        }
    }, [props.show]);

    function handleGoOverText(event) {
        const { id } = event.target;

        setOverButtons((prevState) => {
            return { ...prevState, [id]: true };
        });
    }

    function handleLeaveText(event) {
        const { id } = event.target;

        setOverButtons((prevState) => {
            return { ...prevState, [id]: false };
        });
    }

    function handleClick() {
        Axios.post("/pods/cookieBanner/changeStatus")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setTop(-30);
                    props.changeTop();
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={{ ...CookieBannerStyles.body, top: `${top}px` }}>
            <Row>
                <Col sm={4}></Col>
                <Col sm={4} style={CookieBannerStyles.cookieText}>
                    This website uses cookies
                </Col>
                <Col sm={1}>
                    <span
                        id="accept"
                        style={overButtons.accept ? CookieBannerStyles.overText : CookieBannerStyles.normalText}
                        onMouseEnter={handleGoOverText}
                        onMouseLeave={handleLeaveText}
                        onClick={handleClick}
                    >
                        Accept
                    </span>
                </Col>
                <Col sm={1}>
                    <span
                        id="decline"
                        style={overButtons.decline ? CookieBannerStyles.overText : CookieBannerStyles.normalText}
                        onMouseEnter={handleGoOverText}
                        onMouseLeave={handleLeaveText}
                        onClick={handleClick}
                    >
                        Decline
                    </span>
                </Col>
                <Col sm={2}></Col>
            </Row>
        </div>
    );
}

export default CookieBanner;

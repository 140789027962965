import React, { useState, useEffect } from "react";
import { Row, Col, ListGroup, Button, Modal, Image } from "react-bootstrap";
import Axios from "axios";
import { useDispatch } from "react-redux";

//COMPONENTS
import UploadComponent from "../../uploadComponent/config";

//STYLES
import * as ConfigStyles from "./styles";

//ACTIONS
//import * as GS_navSettingsActions from "../../../../../../../../../../store/actions/globalSettings/GS_navSettings";
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

function Config() {
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(0);
    const dispatch = useDispatch();

    const modalYNDefaults = {
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    };
    const [modalYN, setModalYN] = useState(modalYNDefaults);

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Style Configuration - Sub Pages (Top Photo)"));
        loadFiles();
    }, []);

    function loadFiles() {
        Axios.post("/style/subPagesPhotos/load")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setFiles(data.files);
                }
            })
            .catch((err) => console.log(err));
    }

    function registerFiles(reCallData, files) {
        const data = { files: files };
        Axios.post("/style/subPagesPhotos/registerFiles", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    loadFiles();
                }
            })
            .catch((err) => console.log(err));
    }

    function handleFileOnClick(uuid) {
        setSelectedFile(uuid);
    }

    function handleOpenDeleteModal() {
        setModalYN({
            open: true,
            heading: "Delete File",
            message: "Are you sure you want to delete this file?",
            acceptFunction: handleDeleteFile,
            acceptName: "Yes",
            showAccept: true,
            cancelName: "No",
            showCancel: true
        });
    }

    function handleDeleteFile() {
        setModalYN(modalYNDefaults);
        if (selectedFile != 0) {
            const data = { uuid: selectedFile };
            Axios.post("/style/subPagesPhotos/deleteFile", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.error == "null") {
                        loadFiles();
                        setSelectedFile(0);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    return (
        <div style={ConfigStyles.body}>
            <br />
            <UploadComponent
                isImage={true}
                fileType={"n/a"}
                fileLimit={4}
                fileSize={6}
                multiple={true}
                directoryPath={"mainStyle/subPagesPhotos"}
                nextFunction={registerFiles}
                fileMessage={"Images only"}
                showFileText
            />
            <br />
            <Row>
                <Col>
                    <div style={ConfigStyles.scrollable200}>
                        <ListGroup>
                            {files.map((file, index) => {
                                return (
                                    <ListGroup.Item
                                        variant={selectedFile == file.uuid ? "primary" : ""}
                                        action
                                        key={index}
                                        onClick={handleFileOnClick.bind(this, file.uuid)}
                                    >
                                        {file.filename}
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    </div>
                </Col>
                <Col>
                    <Button onClick={handleOpenDeleteModal}>Delete</Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    {selectedFile != 0 && (
                        <Image
                            src={`/content/mainStyle/subPagesPhotos/${files.find((file) => file.uuid == selectedFile).filename}`}
                            style={ConfigStyles.image}
                        />
                    )}
                </Col>
            </Row>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Config;

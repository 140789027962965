export const body = {
    backgroundColor: "#FAF3F3",
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const innerText = {
    width: "60%",
    padding: "5px",
    fontSize: "20px",
    borderBottom: "5px solid #DA7F8F",
    marginTop: "40px",
    marginBottom: "40px"
};

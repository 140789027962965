import * as Colors from "../../../../globalStyles/administratorColors";

export const body = {
    paddingRight: "20px",
    paddingTop: "30px"
};

export const columnRight = {
    textAlign: "right"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white"
};

export const cardStyleHeadings = {
    backgroundColor: Colors.primary,
    color: "white",
    fontWeight: "bold"
};

export const cardText = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
};

export const noLinksText = {
    textAlign: "center",
    fontWeight: "bold"
};

export const body = {
    paddingTop: "20px",
    paddingRight: "30px"
};

export const uploadView = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
};

export const filesTableRow = {
    padding: "5px"
};

export const filesTableheading = {
    color: "red"
};

export const errorModalColor = {
    color: "red"
};

export const successModalColor = {
    color: "green"
};

export const columnPadding = {
    padding: "5px"
};

export const pin = {
    width: "20px",
    height: "20px",
    border: "1px solid black",
    position: "absolute",
    borderRadius: "20px",
    right: "20px",
    cursor: "pointer"
};

export const pinned = {
    width: "20px",
    height: "20px",
    border: "1px solid black",
    backgroundColor: "yellow",
    position: "absolute",
    borderRadius: "20px",
    right: "20px",
    cursor: "pointer"
};

export const columnRight = {
    position: "relative"
};

//MOVE MODAL
export const albumBody = {
    height: "175px",
    border: "1px solid black",
    cursor: "pointer"
};

export const cardPicture = {
    height: "135px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const cardText = {
    height: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const albumImage = {
    width: "130px",
    height: "130px"
};

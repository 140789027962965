export const cardBody = {
    width: "200px",
    height: "200px",
    border: "1px solid black",
    padding: "5px",
    cursor: "pointer"
};

export const cardPicture = {
    height: "150px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const cardText = {
    height: "50px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const image = {
    width: "130px",
    height: "130px"
};

export const albumImage1 = {
    position: "absolute"
};

export const albumImage2 = {
    width: "130px",
    height: "130px",
    position: "absolute",
    top: "10px",
    left: "10px"
};

export default function pageNavigation(navigate, page) {
    switch (page) {
        case "about-stGiles":
            return navigate(`/${page}`);
        case "about-worship":
            return navigate(`/${page}`);
        case "about-visitorInfomation":
            return navigate(`/${page}`);
        case "about-findUs":
            return navigate(`/${page}`);
        case "contactUs":
            return navigate(`/${page}`);
        case "home":
            return navigate(`/`);
        case "photo-gallery":
            return navigate("/photo-gallery");
        case "events":
            return navigate("/events");
        default:
            return console.log("nothing!!");
    }
}

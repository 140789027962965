import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as AddBlogStyles from "../styles/addBlog";

function AddModal(props) {
    const defaultValues = {
        title: "",
        valid: false,
        maxLength: 50,
        remainingLength: 50,
        formTouched: false
    };

    const [data, setData] = useState(defaultValues);

    useEffect(() => {
        setData(defaultValues);
    }, [props]);

    function handleSubmitForm() {
        if (!data.valid) {
            setData((prevState) => {
                return { ...prevState, formTouched: true };
            });
        } else {
            const axiosData = { title: data.title };
            Axios.post("/pods/CPOD_BLOGS_ADMIN/addBlog", axiosData)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        props.close();
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleOnTextChange(event) {
        const { value } = event.target;

        let isValid = false;
        if (value.length > 0) {
            isValid = true;
        }

        const remainingLength = data.maxLength - value.length;

        setData((prevState) => {
            return { ...prevState, title: value, valid: isValid, remainingLength: remainingLength };
        });
    }

    return (
        <div>
            <Modal show={props.open} onHide={props.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Blog</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Title:</Form.Label>
                        <Form.Control
                            isInvalid={data.formTouched && !data.valid ? true : false}
                            type="text"
                            maxLength={data.maxLength}
                            value={data.title}
                            onChange={handleOnTextChange}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a title!</Form.Control.Feedback>
                    </Form.Group>
                    <div style={AddBlogStyles.counterText}>
                        {data.remainingLength}/{data.maxLength}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSubmitForm}>Add</Button>
                    <Button onClick={props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AddModal;

export const GSCONTENTSETTINGS_UPDATEIMAGES = "GSCONTENTSETTINGS_UPDATEIMAGES";
export const GSCONTENTSETTINGS_UPDATEID = "GSCONTENTSETTINGS_UPDATEID";
export const GSCONTENTSETTINGS_INSERTDELETED = "GSCONTENTSETTINGS_INSERTDELELTED";
export const GSCONTENTSETTINGS_IMAGEEDIT_OPEN = "GSCONTENTSETTINGS_IMAGEEDIT_OPEN";
export const GSCONTENTSETTINGS_IMAGEEDIT_CLOSE = "GSCONTENTSETTINGS_IMAGEEDIT_CLOSE";

export const UpdateImages = (image) => {
    return (dispatch, getState) => {
        const GS_contentSettings = getState().GS_contentSettings;

        const newArray = [...GS_contentSettings.images, image];

        dispatch({
            type: GSCONTENTSETTINGS_UPDATEIMAGES,
            value: newArray
        });
    };
};

export const RefreashImages = (val) => {
    return {
        type: GSCONTENTSETTINGS_UPDATEIMAGES,
        value: []
    };
};

export const UpdateImageID = (val) => {
    return {
        type: GSCONTENTSETTINGS_UPDATEID,
        value: val
    };
};

export const UpdateImageURL = (id, url) => {
    return (dispatch, getState) => {
        const GS_contentSettings = getState().GS_contentSettings;

        const newArray = [];

        for (const [index, image] of GS_contentSettings.images.entries()) {
            if (index == id) {
                const newImage = image;
                newImage.url = url;
                newImage.exists = true;
                newArray.push(newImage);
            } else {
                newArray.push(image);
            }
        }

        dispatch({
            type: GSCONTENTSETTINGS_UPDATEIMAGES,
            value: newArray
        });
    };
};

export const DeleteImage = (id) => {
    return (dispatch, getState) => {
        const GS_contentSettings = getState().GS_contentSettings;

        const newArray = [];

        for (const [index, image] of GS_contentSettings.images.entries()) {
            if (image.uuid != id) {
                newArray.push(image);
            } else if (image.uuid != "null") {
                dispatch({
                    type: GSCONTENTSETTINGS_INSERTDELETED,
                    value: { ...image, status: "delete" }
                });
            }
        }

        dispatch({
            type: GSCONTENTSETTINGS_UPDATEIMAGES,
            value: newArray
        });
    };
};

export const changeImageSize = (id, measurement, value) => {
    return (dispatch, getState) => {
        const GS_contentSettings = getState().GS_contentSettings;

        const newArray = [];

        for (const [index, image] of GS_contentSettings.images.entries()) {
            if (image.uuid == id) {
                const newImage = image;
                newImage[measurement] = value;
                newArray.push(newImage);
            } else {
                newArray.push(image);
            }
        }

        dispatch({
            type: GSCONTENTSETTINGS_UPDATEIMAGES,
            value: newArray
        });
    };
};

export const ChangeImagePositing = (id, position) => {
    return (dispatch, getState) => {
        const GS_contentSettings = getState().GS_contentSettings;

        const newArray = [];

        for (const [index, image] of GS_contentSettings.images.entries()) {
            if (image.uuid == id) {
                const newImage = image;
                newImage.positioning = position;
                newArray.push(newImage);
            } else {
                newArray.push(image);
            }
        }

        dispatch({
            type: GSCONTENTSETTINGS_UPDATEIMAGES,
            value: newArray
        });
    };
};

export const LoadImagesFromDatabase = (images) => {
    return {
        type: GSCONTENTSETTINGS_UPDATEIMAGES,
        value: images
    };
};

export const EditImage_Open = (id) => {
    return {
        type: GSCONTENTSETTINGS_IMAGEEDIT_OPEN,
        id: id
    };
};

export const EditImage_Close = () => {
    return {
        type: GSCONTENTSETTINGS_IMAGEEDIT_CLOSE
    };
};

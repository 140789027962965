import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { Row, Col, Image } from "react-bootstrap";

import "../globalStyles/customNavCSSStyles.css";

//STYLES
import * as UserRouteSystemStyles from "./styles/userRouteSystem";

//ACTIONS
import * as UserActions from "../../store/actions/user";
import * as GS_generalSettings from "../../store/actions/globalSettings/GS_generalSettings";
import * as CalendarActions from "../envStore/actions/calendar";

//STYLE COMPONENTS
import CookieBanner from "../pods/cookieBanner/cookieBanner";
import NavBar from "../mainStyle/components/navBar/frontEnd/navBar";
import SliderNavigation from "../mainStyle/components/slider/frontEnd/navigation";
import PhotoRowNavigation from "../mainStyle/components/photoRow/frontEnd/navigation";
import FourSectionsNavigation from "../mainStyle/components/fourSectionsOneRow/frontEnd/navigation";
import PhotoGallery from "../pods/photoGallery/frontEnd/files/galleryViewer";
import PhotoGalleryAlbumViewer from "../pods/photoGallery/frontEnd/files/albumViewer";

//PAGES
import HomePage from "./homePage";
import SubPageBase from "../mainStyle/components/subPagesPhotos/frontEnd/subPageBase";

//FROM ADMIN CORE SIDE
import AdminBanner from "../../administration/pods/adminBanner/adminBanner";
import SiteOffline from "../../administration/pods/siteOffline/files/siteOffline";

function UserRouteSystem() {
    const user = useSelector((state) => state.user);

    const [isLoaded, setIsLoaded] = useState(false);
    const [isOffline, setIsOffline] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        onOpen();
    }, []);

    function onOpen() {
        Axios.post("/pods/userRouter/getOpeningData")
            .then((res) => {
                const data = res.data;
                console.log(data);
                dispatch(GS_generalSettings.UpdateMainContactPageVisible(data.settings.mainContactPage.value == "true"));
                dispatch(GS_generalSettings.UpdateSiteOffline(data.settings.siteOffline.value == "true"));
                dispatch(GS_generalSettings.UpdateSiteOfflineMessage(data.settings.siteOffline.subValue));
                dispatch(CalendarActions.UpdateCalendarView(data.calendarView));
                setIsOffline(data.settings.siteOffline.value == "true");

                if (user.auth && user.type !== "registered") {
                    dispatch(UserActions.UpdateAdminSignedIn(true));
                }

                //MEMBERSHIP EXTRA POD
                if (user.requestedPassword) {
                    navigate("/membership/changePassword");
                }

                setIsLoaded(true);
            })
            .catch((err) => console.log(err));
    }

    function offlineUserCheck(role) {
        if (role == "modifier" || role == "registered" || role == "") {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div>
            {isLoaded && (
                <div>
                    {isOffline && !offlineUserCheck(user.type) ? (
                        <div>
                            <SiteOffline />
                        </div>
                    ) : (
                        <div>
                            {user.adminSignedIn ? <AdminBanner /> : null}
                            {/* <CookieBanner /> */}
                            <NavBar />
                            <Row>
                                <Col>
                                    <Row>
                                        <Col style={UserRouteSystemStyles.mainBody}>
                                            <Routes>
                                                <Route path="/" element={<HomePage />} />
                                                <Route path="/about-stGiles" element={<SubPageBase pageID={1} />} />
                                                <Route path="/about-worship" element={<SubPageBase pageID={2} />} />
                                                <Route path="/about-visitorInfomation" element={<SubPageBase pageID={3} />} />
                                                <Route path="/about-findUs" element={<SubPageBase pageID={4} />} />
                                                <Route path="/events/*" element={<SubPageBase element="calendar" />} />
                                                <Route path="/photo-gallery" element={<PhotoGallery />} />
                                                <Route path="/photo-gallery/album/:name" element={<PhotoGalleryAlbumViewer />} />
                                                <Route path="/contactUs" element={<SubPageBase element="mainContact" />} />
                                                <Route path="/slider/:name" element={<SliderNavigation />} />
                                                <Route path="/introductions/:name" element={<PhotoRowNavigation />} />
                                                <Route path="/sections/:name" element={<FourSectionsNavigation />} />
                                            </Routes>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default UserRouteSystem;

export const body = {
    paddingRight: "30px"
};

export const formTitles = {
    fontSize: "20px"
};

export const formDropdownTitle = {
    height: "50px",
    lineHeight: "50px",
    fontSize: "20px",
    margin: "auto",
    paddingTop: "auto",
    textAlign: "right"
};

export const dropdownMenu = {
    maxHeight: "200px",
    overflowY: "auto"
};

export const show = {
    visibility: "visible"
};

export const hidden = {
    visibility: "hidden"
};

export const repeatNumSection = {
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    alignItems: "center"
};

export const repeatNumTextBox = {
    width: "50px",
    textAlign: "center"
};

export const recurrRadio = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const columnRight = {
    textAlign: "right"
};

export const archivedNotice = {
    textAlign: "center",
    fontWeight: "bold",
    color: "red"
};

export const image = {
    width: "200px",
    height: "200px"
};

export const imageDeleteContainer = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const body = {
    // height: "200px"
};

export const image = {
    backgroundSize: "cover",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer"
};

export const heading = {
    color: "white",
    textAlign: "center",
    transition: "all 0.5s ease-out"
};

export const text = {
    color: "white",
    textAlign: "center",
    fontSize: "25px",
    paddingBottom: "10px",
    transition: "all 1s ease-out"
};

import * as ReducerActions from "../actions/calendar";

const initialState = {
    view: "calendar"
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReducerActions.UPDATE_CALENDAR_VIEW:
            return { ...state, view: action.value };
        default:
            return state;
    }
};

export default reducer;

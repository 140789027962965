import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Row, Col, Card } from "react-bootstrap";

//STYLES
import * as ListViewStyles from "../styles/listView";

//COMPONENTS
import EventView from "./eventView";

function ListView(props) {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        Axios.post("/pods/events/loadListViewEvents")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    console.log(data);
                    setEvents(data.events);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div style={ListViewStyles.body}>
            {events.map((event, index) => {
                return (
                    <div key={index}>
                        <EventView event={event} />
                        <br />
                    </div>
                );
            })}
        </div>
    );
}

export default ListView;

import * as Colors from "../../../../globalStyles/administratorColors";

export const body = {
    paddingRight: "30px"
};

export const columnRight = {
    textAlign: "right"
};

export const counterContainer = {
    textAlign: "right",
    color: "red"
};

export const errorText = {
    color: "red"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white"
};

export const reviewText = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
};

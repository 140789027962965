export const body = {
    paddingTop: "20px",
    paddingRight: "30px"
};

export const podFolderError = {
    textAlign: "center",
    fontWeight: "bold",
    color: "red"
};

export const columnRight = {
    textAlign: "right"
};

export const columnPadding = {
    padding: "5px"
};

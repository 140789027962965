import React, { useState, useEffect, useRef } from "react";
import { Image } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as AlbumSlideShowStyles from "../styles/albumSlideShow";

function AlbumSlideShow(props) {
    const slideRef = useRef();

    const [isLoaded, setIsLoaded] = useState(false);

    const [height, setHeight] = useState(0);

    const [slide1Position, setSlide1Position] = useState(0);
    const [slide2Position, setSlide2Position] = useState(0);

    const [images, setImages] = useState([]);

    const [image1, setImage1] = useState("");
    const [image2, setImage2] = useState("");

    const [transitionsStyles, setTransitionsStyles] = useState({});

    useEffect(() => {
        if (slideRef.current != null) {
            setSlide2Position(slideRef.current.offsetWidth);
            setHeight(Math.floor((slideRef.current.offsetWidth / 100) * 75));

            const data = { album: props.album };
            Axios.post("/pods/CPOD_PHOTOGALLERY_FREND/getAlbumImages", data)
                .then((res) => {
                    const data = res.data;
                    setImages(data.images);

                    setImage1([data.images[0]]);
                    setImage2([data.images[1]]);

                    setTimeout(() => {
                        startAnimation(data.images, 0);
                    }, 100);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    function startAnimation(data, currentData) {
        setImage1(data[currentData]);
        setImage2(data[currentData + 1]);

        setTransitionsStyles({ transition: "all 2s ease-out" });

        setTimeout(() => {
            if (slideRef.current != null) {
                setSlide1Position(0 - slideRef.current.offsetWidth);

                setSlide2Position(0);
                setTimeout(() => {
                    ResetSliderWithPhoto(data, currentData + 1);
                }, 2000);
            }
        }, 5000);
    }

    function ResetSliderWithPhoto(data, currentData) {
        if (slideRef.current != null) {
            setTransitionsStyles({});
            setImage1(data[currentData]);

            let nextData = currentData + 1;
            if (nextData > data.length - 1) {
                nextData = 0;
            }
            setImage2(data[nextData]);

            setSlide1Position(0);
            setSlide2Position(slideRef.current.offsetWidth);

            setTimeout(() => {
                RestartAnimation(data, nextData);
            }, 4000);
        }
    }

    function RestartAnimation(data, nextData) {
        if (slideRef.current != null) {
            setTransitionsStyles({ transition: "all 2s ease-out" });

            setTimeout(() => {
                setSlide1Position(0 - slideRef.current.offsetWidth);

                setSlide2Position(0);
                setTimeout(() => {
                    ResetSliderWithPhoto(data, nextData);
                }, 2000);
            }, 1000);
        }
    }

    return (
        <div style={{ ...AlbumSlideShowStyles.body, height: `${height}px` }}>
            <div
                ref={slideRef}
                style={{
                    ...AlbumSlideShowStyles.imageContainer,
                    marginLeft: slide1Position,
                    backgroundImage: `url(/content/gallery/${props.album}/${image1})`,
                    ...transitionsStyles
                }}
            ></div>
            <div
                style={{
                    ...AlbumSlideShowStyles.imageContainer,
                    marginLeft: slide2Position,
                    backgroundImage: `url(/content/gallery/${props.album}/${image2})`,
                    ...transitionsStyles
                }}
            ></div>
        </div>
    );
}

export default AlbumSlideShow;

import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

//COMPONENTS
import StyleMenu from "./files/styleMenu";

//STYLE COMPONENTS
import NavBarStyles from "../components/navBar/admin/config";
import SlideShowConfig from "../components/slider/admin/config";
import SimpleSectionCenteredTextConfig from "../components/simpleSectionCenteredText/admin/config";
import HalfPhotoAdmin from "../components/halfPhoto/admin/config";
import FourSectionsOneRowAdmin from "../components/fourSectionsOneRow/admin/config";
import PhotoRowAdmin from "../components/photoRow/admin/config";
import SubPagesPhotosAdmin from "../components/subPagesPhotos/admin/config";

function PodRouter() {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    return (
        <div>
            {isLoaded && (
                <div>
                    <Routes>
                        <Route path="/" element={<StyleMenu />} />
                        <Route path="/navBar" element={<NavBarStyles />} />
                        <Route path="/slider" element={<SlideShowConfig />} />
                        <Route path="/simpleSectionCentredText" element={<SimpleSectionCenteredTextConfig />} />
                        <Route path="/halfPhoto" element={<HalfPhotoAdmin />} />
                        <Route path="/fourSectionsOneRow" element={<FourSectionsOneRowAdmin />} />
                        <Route path="/photoRow" element={<PhotoRowAdmin />} />
                        <Route path="/subPagesPhotos" element={<SubPagesPhotosAdmin />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;

export const body = {
    paddingRight: "30px"
};

export const imageText = {
    fontSize: "1.5rem"
};

export const counter = {
    color: "red",
    textAlign: "right"
};

export const columnRight = {
    textAlign: "right"
};

export const image = {
    width: "100%",
    height: "100%"
};

export const updateButton = {
    transition: "all 0.2s ease-out"
};

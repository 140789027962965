import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import Controller from "./files/controller";

function PodRouter() {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <div>
            {loaded && (
                <div>
                    <Routes>
                        <Route exact path="/" element={<Controller />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;

import React, { useState, useEffect, useRef } from "react";
import { Image } from "react-bootstrap";
import moment from "moment";
import Axios from "axios";

//STYLES
import * as EventsSliderStyles from "./styles";

//COMPONENTS
import EventSlot from "./eventSlot";

function EventsSlider() {
    const outerContainer = useRef(null);
    const [loaded, setLoaded] = useState(false);
    const [eventSlotWidth, setEventSlotWidth] = useState(0);

    const [slot1Position, setSlot1Position] = useState(0);
    const [slot2Position, setSlot2Position] = useState(0);
    const [slot3Position, setSlot3Position] = useState(0);
    const [slot4Position, setSlot4Position] = useState(0);

    const [eventSlot1, setEventSlot1] = useState(0);
    const [eventSlot2, setEventSlot2] = useState(1);
    const [eventSlot3, setEventSlot3] = useState(2);
    const [eventSlot4, setEventSlot4] = useState(3);

    const [transitionStyle, setTransitionStyle] = useState({});

    const [eventsContainerWidth, setEventContainerWidth] = useState(3);

    const [data, setData] = useState([]);

    useEffect(() => {
        getEvents();
        setLoaded(true);
    }, []);

    function getEvents() {
        const data = { periodStartDate: moment().format("DD/MM/YYYY"), periodEndDate: moment().add(1, "w").format("DD/MM/YYYY") };
        Axios.post("/pods/events/getEventsForSlider", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setData(data.events);
                    console.log(data.events);
                    if (outerContainer.current != null) {
                        const slotWidth = (outerContainer.current.offsetWidth / 100) * 30;
                        setEventSlotWidth(slotWidth);

                        setSlot2Position(slotWidth);
                        setSlot3Position(slotWidth * 2);
                        setSlot4Position(slotWidth * 3);

                        if (data.events.length > 0 && data.events.length < 3) {
                            setEventContainerWidth(data.events.length);
                        }

                        if (data.events.length > 3) {
                            setTimeout(() => {
                                setTransitionStyle({ transition: "all 2s ease-out" });
                                setSlot1Position(0 - slotWidth);
                                setSlot2Position(0);
                                setSlot3Position(slotWidth);
                                setSlot4Position(slotWidth * 2);
                                setTimeout(() => {
                                    loadNextEvents(data.events, 0);
                                }, 2000);
                            }, 3000);
                        }
                    }
                }
            })
            .catch((err) => console.log(err));
    }

    function loadNextEvents(events, currentEvent) {
        if (outerContainer.current != null) {
            let cEvent = currentEvent;
            const newEvents = [];
            for (let i = 0; i < 4; i++) {
                if (cEvent < events.length - 1) {
                    cEvent = cEvent + 1;
                    newEvents.push(cEvent);
                } else {
                    cEvent = 0;
                    newEvents.push(cEvent);
                }
            }
            setEventSlot1(newEvents[0]);
            setEventSlot2(newEvents[1]);
            setEventSlot3(newEvents[2]);
            setEventSlot4(newEvents[3]);

            setTransitionStyle({});

            const slotWidth = (outerContainer.current.offsetWidth / 100) * 30;
            setSlot1Position(0);
            setSlot2Position(slotWidth);
            setSlot3Position(slotWidth * 2);
            setSlot4Position(slotWidth * 3);

            setTimeout(() => {
                const nextEvent = currentEvent + 1;
                if (nextEvent > events.length - 1) {
                    RestartAnimation(events, 0);
                } else {
                    RestartAnimation(events, nextEvent);
                }
            }, 3000);
        }
    }

    function RestartAnimation(events, currentEvent) {
        if (outerContainer.current != null) {
            const slotWidth = (outerContainer.current.offsetWidth / 100) * 30;

            setTransitionStyle({ transition: "all 2s ease-out" });
            setSlot1Position(0 - slotWidth);
            setSlot2Position(0);
            setSlot3Position(slotWidth);
            setSlot4Position(slotWidth * 2);
            setTimeout(() => {
                loadNextEvents(events, currentEvent);
            }, 2000);
        }
    }

    return (
        <div>
            {loaded && (
                <div style={EventsSliderStyles.body}>
                    <h1>
                        <strong>Events</strong>
                    </h1>{" "}
                    <br />
                    <div ref={outerContainer} style={EventsSliderStyles.outerSection}>
                        <div>
                            {data.length == 0 ? (
                                <div>
                                    <strong>No events to show this week</strong>
                                </div>
                            ) : (
                                <div style={{ ...EventsSliderStyles.slideshowContainer, width: `${eventSlotWidth * eventsContainerWidth}px` }}>
                                    <EventSlot
                                        transitionStyle={transitionStyle}
                                        data={data[eventSlot1]}
                                        eventSlotWidth={eventSlotWidth}
                                        slotPosition={slot1Position}
                                    />
                                    {data.length > 1 && (
                                        <EventSlot
                                            transitionStyle={transitionStyle}
                                            data={data[eventSlot2]}
                                            eventSlotWidth={eventSlotWidth}
                                            slotPosition={slot2Position}
                                        />
                                    )}
                                    {data.length > 2 && (
                                        <EventSlot
                                            transitionStyle={transitionStyle}
                                            data={data[eventSlot3]}
                                            eventSlotWidth={eventSlotWidth}
                                            slotPosition={slot3Position}
                                        />
                                    )}
                                    {data.length > 3 && (
                                        <EventSlot
                                            transitionStyle={transitionStyle}
                                            data={data[eventSlot4]}
                                            eventSlotWidth={eventSlotWidth}
                                            slotPosition={slot4Position}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <br /> <br />
                </div>
            )}
        </div>
    );
}

export default EventsSlider;

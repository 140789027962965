import React, { useState, useEffect } from "react";
import Axios from "axios";

//STYLES
import * as VideoSectionStyles from "./styles";

function VideoSection() {
    const [url, setUrl] = useState("");

    useEffect(() => {
        // Axios.post("/style/videoSection/load")
        //     .then((res) => {
        //         const data = res.data;
        //         if (data.error == "null") {
        //             setUrl(data.url);
        //         }
        //     })
        //     .catch((err) => console.log(err));
    }, []);

    return (
        <div style={VideoSectionStyles.body}>
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/GLmTsky4ag4?si=de2q_mBR0eXGQkZD"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            ></iframe>
        </div>
    );
}

export default VideoSection;

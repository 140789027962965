import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import Axios from "axios";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import htmlToDraftjs from "html-to-draftjs";
import DOMPurify from "dompurify";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ConfigStyles from "./styles";

function Config() {
    const dispatch = useDispatch();
    const [buttonColor, setButtonColor] = useState("primary");
    const [data, setData] = useState({
        donateMenuText: "",
        donate_option1Name: "",
        donate_option1URL: "",
        donate_option2Name: "",
        donate_option2URL: "",
        socialLinks_facebook: "",
        socialLinks_youtube: ""
    });

    const nameMaxLength = 20;
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Style Configuration - Navigation Bar"));

        Axios.post("/style/navBar/load")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setData(data.info);
                }
                setEditorState(stringToDraftjs(data.info.donateMenuText));
            })
            .catch((err) => console.log(err));
    }, []);

    function stringToDraftjs(text) {
        const blocksFromHTML = htmlToDraftjs(text);
        const { contentBlocks, entityMap } = blocksFromHTML;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        return EditorState.createWithContent(contentState);
    }

    function handleTextChange(event) {
        const { name, value } = event.target;

        const temp = { ...data };

        temp[name] = value;

        setData(temp);
    }

    function handleUpdate() {
        const info = { data: data };
        Axios.post("/style/navBar/update", info)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setButtonColor("success");
                    setTimeout(() => {
                        setButtonColor("primary");
                    }, 2000);
                }
            })
            .catch((err) => console.log(err));
    }

    const handleEditorChange = (editorState) => {
        setEditorState(editorState);
        setData((prevState) => {
            return { ...prevState, donateMenuText: DOMPurify.sanitize(draftjsToHtml(convertToRaw(editorState.getCurrentContent()))) };
        });
    };

    return (
        <div style={ConfigStyles.body}>
            <Row>
                <Col>
                    <p style={ConfigStyles.titleSize}>
                        <strong>Donate Menu:</strong>
                    </p>
                    <Form.Group>
                        <Form.Label>Menu Text</Form.Label>
                        <Editor
                            editorState={editorState}
                            toolbar={{
                                options: [
                                    "inline",
                                    "blockType",
                                    "fontSize",
                                    "fontFamily",
                                    "list",
                                    "textAlign",
                                    "colorPicker",
                                    "link",
                                    "embedded",
                                    "emoji",
                                    "remove",
                                    "history"
                                ]
                            }}
                            onEditorStateChange={handleEditorChange}
                            wrapperStyle={ConfigStyles.editorWrapper}
                            editorStyle={ConfigStyles.editorStyle}
                        />
                        {/* <Form.Control as="textarea" rows={3} name="donateMenuText" value={""} onChange={handleTextChange} /> */}
                    </Form.Group>
                    <br />
                    <strong>Option 1:</strong>
                    <br /> <br />
                    <Form.Group style={ConfigStyles.textGroup}>
                        <Form.Label>Name: </Form.Label>
                        <Form.Control
                            type="text"
                            name="donate_option1Name"
                            maxLength={nameMaxLength}
                            value={data.donate_option1Name}
                            onChange={handleTextChange}
                        />
                    </Form.Group>
                    <div style={ConfigStyles.counter}>
                        {data.donate_option1Name.length}/{nameMaxLength}
                    </div>
                    <br />
                    <Form.Group style={ConfigStyles.textGroup}>
                        <Form.Label>URL: </Form.Label>
                        <Form.Control type="text" name="donate_option1URL" value={data.donate_option1URL} onChange={handleTextChange} />
                    </Form.Group>
                    <br />
                    <strong>Option 2:</strong>
                    <br />
                    <br />
                    <Form.Group style={ConfigStyles.textGroup}>
                        <Form.Label>Name: </Form.Label>
                        <Form.Control
                            type="text"
                            name="donate_option2Name"
                            maxLength={nameMaxLength}
                            value={data.donate_option2Name}
                            onChange={handleTextChange}
                        />
                    </Form.Group>
                    <div style={ConfigStyles.counter}>
                        {data.donate_option2Name.length}/{nameMaxLength}
                    </div>
                    <br />
                    <Form.Group style={ConfigStyles.textGroup}>
                        <Form.Label>URL: </Form.Label>
                        <Form.Control type="text" name="donate_option2URL" value={data.donate_option2URL} onChange={handleTextChange} />
                    </Form.Group>
                </Col>
                <Col>
                    <p style={ConfigStyles.titleSize}>
                        <strong>Social Links:</strong>
                    </p>
                    <strong>Facebook:</strong>
                    <br /> <br />
                    <Form.Group style={ConfigStyles.textGroup}>
                        <Form.Label>URL: </Form.Label>
                        <Form.Control type="text" name="socialLinks_facebook" value={data.socialLinks_facebook} onChange={handleTextChange} />
                    </Form.Group>
                    <br /> <br />
                    <strong>YouTube:</strong>
                    <br /> <br />
                    <Form.Group style={ConfigStyles.textGroup}>
                        <Form.Label>URL: </Form.Label>
                        <Form.Control type="text" name="socialLinks_youtube" value={data.socialLinks_youtube} onChange={handleTextChange} />
                    </Form.Group>
                    <br />
                    <div style={ConfigStyles.columnRight}>
                        <Button onClick={handleUpdate} style={ConfigStyles.updateButton} variant={buttonColor}>
                            Update
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Config;

export const body = {
    paddingTop: "85px",
    backgroundColor: "#FAF3F3",
    minHeight: "100vh"
};

export const leftColumn = {
    textAlign: "center"
};

export const viewAllButton = {
    color: "blue",
    padding: "5px",
    border: "1px solid blue",
    borderRadius: "10px 0px 0px 10px",
    cursor: "pointer",
    display: "inline-block"
};

export const viewOneButton = {
    color: "blue",
    padding: "5px",
    border: "1px solid blue",
    borderRadius: "0px 10px 10px 0px",
    cursor: "pointer",
    display: "inline-block"
};

export const image = {
    width: "500px",
    maxHeight: "500px"
};

export const viewOneMiddle = {
    height: "500px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const sideColumn = {
    fontSize: "2rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const imagesPanel = {
    borderStyle: "inset",
    height: "100px",
    display: "flex",
    // flexDirection: "row",
    overflow: "auto"
};

export const thumbnailContainer = {
    width: "100px",
    height: "100%",
    padding: "10px"
};

export const thumbnail = {
    width: "90px",
    height: "100%",
    cursor: "pointer"
};

export const thumbnailSelected = {
    width: "90px",
    height: "100%",
    cursor: "pointer",
    border: "4px solid red"
};

export const direcionPointer = {
    cursor: "pointer"
};

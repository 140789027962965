import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Col, Row, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as BlogPostsViewerStyles from "../styles/blogPostsViewer";

//COMPONENTS
import BlogSettings from "./blogSettings";

function BlogPostsViewer() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [posts, setPosts] = useState([]);

  const [blogSettings, setBlogSettings] = useState({
    open: false,
    uuid: "",
  });

  function handleBlogSettingsOpen() {
    setBlogSettings((prevState) => {
      return { ...prevState, open: true, uuid: params.blogID };
    });
  }

  function handleBlogSettingsClose() {
    setBlogSettings((prevState) => {
      return { ...prevState, open: false };
    });
  }

  useEffect(() => {
    dispatch(GS_navSettingsActions.UpdateSubSelected("Blogs"));

    dispatch(GS_navSettingsActions.UpdateSizing(3));

    loadPosts();
  }, []);

  function loadPosts() {
    const data = { uuid: params.blogID };

    Axios.post("/pods/CPOD_BLOGS_ADMIN/loadPosts", data)
      .then((res) => {
        const data = res.data;
        if (data.error == "null") {
          dispatch(
            GS_navSettingsActions.UpdateTitle(
              `Blog - ${data.blogDetails.title}`
            )
          );
          setPosts(data.posts);
        }
      })
      .catch((err) => console.log(err));
  }

  function handleAddPostClick() {
    navigate("./newPost");
  }

  function handleClickOnPost(uuid) {
    navigate(`./modifyPost/${uuid}`);
  }

  return (
    <div style={BlogPostsViewerStyles.body}>
      <Row>
        <Col>
          <Button onClick={handleAddPostClick}>Add Post</Button>
        </Col>
        <Col style={BlogPostsViewerStyles.settingsBtn}>
          <Button onClick={handleBlogSettingsOpen}>
            <i className="fa-solid fa-gear"></i>
          </Button>
        </Col>
      </Row>
      <br />
      <div style={BlogPostsViewerStyles.editorialContainer}>
        {posts.map((item, index) => {
          return (
            <div key={index}>
              <Card
                style={BlogPostsViewerStyles.cardStyle}
                onClick={handleClickOnPost.bind(this, item.uuid)}
              >
                <Card.Body>
                  <Row>
                    <Col>
                      <strong style={BlogPostsViewerStyles.cardHeadings}>
                        Title:
                      </strong>{" "}
                      {item.title}
                    </Col>
                    <Col>
                      <strong style={BlogPostsViewerStyles.cardHeadings}>
                        Start Publishing:
                      </strong>{" "}
                      {item.publishStart}
                    </Col>
                    <Col>
                      <strong style={BlogPostsViewerStyles.cardHeadings}>
                        End Publishing:
                      </strong>{" "}
                      {item.publishEnd == "" ? "No End Date" : item.publishEnd}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <br />
            </div>
          );
        })}
      </div>
      <BlogSettings
        open={blogSettings.open}
        close={handleBlogSettingsClose}
        uuid={blogSettings.uuid}
      />
    </div>
  );
}

export default BlogPostsViewer;

import * as ReducerActions from "../actions/media";

const initialState = {
    files: [],
    currentURI: "media/home",
    cardsPerRow: [],
    numberOfRows: [],
    galleryWidth: 0,
    empty: false,
    subDir: false,
    podDirectoryExists: true,

    move_directories: [],
    move_cardsPerRow: [],
    move_numberOfRows: [],
    move_width: 0,
    move_file: "",
    move_filePath: "",

    gallerySideBar_open: false,
    gallerySideBar_apiLocation: "",
    gallerySideBar_pod: ""
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReducerActions.MEDIA_UPDATE_FILES:
            return {
                ...state,
                files: action.files,
                empty: action.empty,
                subDir: action.subDir
            };
        case ReducerActions.MEDIA_UPDATE_CURRENTURI:
            return { ...state, currentURI: action.value };
        case ReducerActions.MEDIA_UPDATE_GALLERYWIDTH:
            return { ...state, galleryWidth: action.value };
        case ReducerActions.MEDIA_UPDATE_CARDANDROWS:
            return { ...state, cardsPerRow: action.cards, numberOfRows: action.rows };
        case ReducerActions.MEDIA_UPDATE_WIDTHROWCARDS:
            return {
                ...state,
                galleryWidth: action.galleryWidth,
                cardsPerRow: action.cards,
                numberOfRows: action.rows
            };
        case ReducerActions.MEDIA_UPDATE_MOVE_DIRECTORIES:
            return { ...state, move_directories: action.value };
        case ReducerActions.MEDIA_UPDATE_PODDIRECTORYEXISTS:
            return { ...state, podDirectoryExists: false };
        case ReducerActions.MEDIA_UPDATE_MOVE_WIDTH:
            return { ...state, move_width: action.value };
        case ReducerActions.MEDIA_UPDATE_MOVE_CARDSANDROWS:
            return {
                ...state,
                move_cardsPerRow: action.cards,
                move_numberOfRows: action.rows
            };
        case ReducerActions.MEDIA_UPDATE_MOVE_WIDTHROWCARDS:
            return {
                ...state,
                move_width: action.moveWidth,
                move_cardsPerRow: action.cards,
                move_numberOfRows: action.rows
            };
        case ReducerActions.MEDIA_UPDATE_MOVE_FILE:
            return { ...state, move_file: action.value };
        case ReducerActions.MEDIA_UPDATE_MOVE_FILEPATH:
            return { ...state, move_filePath: action.value };

        case ReducerActions.MEDIA_UPDATE_GALLERYSIDEBAR_OPEN:
            return { ...state, gallerySideBar_apiLocation: action.apiLocation, gallerySideBar_pod: action.pod, gallerySideBar_open: action.open };
        case ReducerActions.MEDIA_UPDATE_GALLERYSIDEBAR_CLOSE:
            return { ...state, gallerySideBar_apiLocation: "", gallerySideBar_pod: "", gallerySideBar_open: false };
        default:
            return state;
    }
};

export default reducer;

import * as Colors from "../../../../globalStyles/administratorColors";

export const body = {
    padding: "30px"
};

export const addButton = {
    textAlign: "right"
};

export const titlesCardStyle = {
    backgroundColor: Colors.primary,
    color: "white"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const blogsContainer = {
    height: "200px",
    overflow: "auto",
    overflowY: "hidden"
};

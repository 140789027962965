import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Axios from "axios";
import { useDispatch } from "react-redux";

//ACTIONS
import * as NavBarActions from "../../../../envStore/actions/navBar";

//STYLES
import * as ViewerStyles from "../styles/galleryViewer";

//COMPONENTS
import AlbumSlot from "./albumSlot";

function GalleryViewer(props) {
    const dispatch = useDispatch();

    const [albums, setAlbums] = useState([]);

    useEffect(() => {
        dispatch(NavBarActions.UpdateTextColor("black"));

        Axios.post("/pods/CPOD_PHOTOGALLERY_FREND/getAlbums")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setAlbums(data.albums);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div style={ViewerStyles.body}>
            {albums.length > 0 ? (
                <div>
                    {" "}
                    <Row>
                        <Col sm={2}></Col>
                        <Col>
                            <Row>
                                {albums.map((album, index) => {
                                    return (
                                        <Col key={index} sm={3}>
                                            <AlbumSlot title={album.title} firstPics={album.firstPics} />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                </div>
            ) : (
                <div style={ViewerStyles.errorMessage}>There are no photo albums to show</div>
            )}
        </div>
    );
}

export default GalleryViewer;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

//ACTIONS
import * as NavBarActions from "../envStore/actions/navBar";

//COMPONENTS
import Slider from "../mainStyle/components/slider/frontEnd/slider";
import SimpleSectionCenteredText from "../mainStyle/components/simpleSectionCenteredText/frontEnd/simpleSectionCentredText";
import HalfPhoto from "../mainStyle/components/halfPhoto//frontEnd/halfPhoto";
import VideoSection from "../mainStyle/components/videoSection/frontEnd/video";
import PhotoRow from "../mainStyle/components/photoRow/frontEnd/photoRow";
import FourSectionsOneRow from "../mainStyle/components/fourSectionsOneRow/frontEnd/fourSectionsOneRow";
import EventsSlider from "../mainStyle/components/eventsSlider/eventsSlider";
import Footer from "../mainStyle/components/footer/footer";

function HomePage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(NavBarActions.UpdateTextColor("white"));
    }, []);

    return (
        <div>
            <Slider />
            <SimpleSectionCenteredText />
            <HalfPhoto />
            <FourSectionsOneRow />
            <VideoSection />
            <EventsSlider />
            <PhotoRow />
            <Footer />
        </div>
    );
}

export default HomePage;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Modal, Card, Row, Col } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
//import * as GS_navSettingsActions from "../../../../../../../../../../store/actions/globalSettings/GS_navSettings";
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ConfigStyles from "./styles";

function Config() {
    const dispatch = useDispatch();
    const [buttonColor, setButtonColor] = useState("primary");

    const [editorial, setEditorial] = useState({ id: 0, title: "" });

    const [editorialModal, setEditorialModal] = useState({
        open: false,
        editorials: []
    });

    function handleEditorialModalClose() {
        setEditorialModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Style Configuration - Introduction"));

        Axios.post("/style/simpleSectionCenteredText/load")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setEditorial({ id: data.editorialUUID, title: data.editorialTitle });
                }
            })
            .catch((err) => console.log(err));
    }, []);

    function handleOpenEditorialModal() {
        Axios.post("/adminPods/contacts/getEditorials")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setEditorialModal((prevState) => {
                        return { ...prevState, editorials: data.editorials, open: true };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleOnUpdate() {
        const data = { editorialUUID: editorial.id };
        Axios.post("/style/simpleSectionCenteredText/update", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setButtonColor("success");
                    setTimeout(() => {
                        setButtonColor("primary");
                    }, 2000);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleEditorialClick(uuid) {
        const title = editorialModal.editorials.find((editorial) => editorial.uuid == uuid).title;
        setEditorial({ id: uuid, title: title });
        handleEditorialModalClose();
    }

    function handleRemoveEditorialClick() {
        setEditorial({ id: 0, title: "" });
        handleEditorialModalClose();
    }

    return (
        <div style={ConfigStyles.body}>
            <Row>
                <Col sm="auto">
                    <h4>Connect an Editorial to this element, Editorial:</h4>
                </Col>
            </Row>
            <Row>
                <Col sm="auto">
                    <h4>Editorial:</h4>
                </Col>
                <Col style={ConfigStyles.editorial} sm="auto">
                    <h5>{editorial.title}</h5>
                </Col>
            </Row>
            <br />
            <Button onClick={handleOpenEditorialModal}>Add Editorial</Button>
            <br />
            <div style={ConfigStyles.columnRight}>
                <Button style={ConfigStyles.updateButton} variant={buttonColor} onClick={handleOnUpdate}>
                    Update
                </Button>
            </div>
            {/* EDITORIAL MODAL */}
            <Modal show={editorialModal.open} onHide={handleEditorialModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add an Editorial</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editorialModal.editorials.map((editorial, index) => {
                        return (
                            <div key={index}>
                                <Card style={ConfigStyles.cardStyle} onClick={handleEditorialClick.bind(this, editorial.uuid)}>
                                    <Card.Body>
                                        <Row>
                                            <Col>{editorial.title}</Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <br />
                            </div>
                        );
                    })}
                    <Card style={ConfigStyles.removeCardStyle} onClick={handleRemoveEditorialClick}>
                        <Card.Body>
                            <Row>
                                <Col>Remove Editorial</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleEditorialModalClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Config;

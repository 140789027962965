import React from "react";

//PODS
//--ADMINISTRATOR
import HVSAdminLoginControl from "./administration/HVSLoginForm/loginControl";

//--ADMIN
import CalendarAdmin from "./calendar/admin/podRouter";
import BlogAdmin from "./blogs/admin/podRouter";
import PhotoGaleryAdmin from "./photoGallery/admin/podRouter";
import PDFLibraryAdmin from "./pdfLibrary/admin/podRouter";
import ReviewsAdmin from "./reviews/admin/podRouter";

//SEGMENTED MENUS
import PhotoGallerySegmentedMenu from "./photoGallery/admin/files/segmentedMenu";

//--FRONTEND
import CalendarFrontEnd from "./calendar/frontEnd/podRouter";
import PhotoGalleryFrontEnd from "./photoGallery/frontEnd/files/galleryViewer";
import PDFLibraryFrontEnd from "./pdfLibrary/frontEnd/files/viewer";
import PhotoGalleryPodViews from "./photoGallery/frontEnd/files/podViews";

//--ADMIN-STYLES
import StyleConfiguration from "../mainStyle/admin/podRouter";

function EnvironmentPodsMap(props) {
    return (
        <div>
            {props.podCode == "loginForm" && <HVSAdminLoginControl />}
            {/* podCode > component */}

            {/* ADMIN */}
            {props.podCode == "CPOD_CALENDAR_ADMIN" && <CalendarAdmin />}
            {props.podCode == "CPOD_BLOGS_ADMIN" && <BlogAdmin />}
            {props.podCode == "CPOD_PHOTOGALLERY_ADMIN" && <PhotoGaleryAdmin />}
            {props.podCode == "STYLINGCONFIG" && <StyleConfiguration />}
            {props.podCode == "CPOD_PDFLIBRARY_ADMIN" && <PDFLibraryAdmin />}
            {props.podCode == "CPOD_REVIEWS_ADMIN" && <ReviewsAdmin />}

            {/* FRONTEND */}
            {props.podCode == "CPOD_CALENDAR_FREND" && <CalendarFrontEnd />}
            {props.podCode == "CPOD_PHOTOGALLERY_FREND" && <PhotoGalleryFrontEnd />}
            {props.podCode == "CPOD_PDFLIBRARY_FREND" && <PDFLibraryFrontEnd />}
        </div>
    );
}

export function EnvironmentPodsMap_PageViewer(props) {
    return (
        <div>{props.podCode == "CPOD_PHOTOGALLERY_FEND" && <PhotoGalleryPodViews podCodeExtra={props.podCodeExtra} addInfo={props.addInfo} />}</div>
    );
}

export function SegmentedMenus(code) {
    return <div>{code == "CPOD_PHOTOGALLERY_FEND_SEGMENTED" && <PhotoGallerySegmentedMenu />}</div>;
}

export default EnvironmentPodsMap;

import React, { useState, useRef } from "react";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as UploadComponentStyles from "./styles";

function UploadComponent(props) {
    // const [files, setFiles] = useState(null);
    const hiddenInput = useRef(null);

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: "",
        error: false
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [failedFilesModal, setFailedFilesModal] = useState({
        files: [],
        open: false
    });

    function handleCloseFailedFilesModal() {
        setFailedFilesModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function onInputChange(e) {
        CheckData(e.target.files);
    }

    function CheckData(files) {
        let localFiles = [];

        for (const [key, file] of Object.entries(files)) {
            localFiles.push({
                id: key,
                name: file.name,
                size: file.size,
                type: file.type
            });
        }

        const checkData = {
            files: localFiles,
            isImage: props.isImage,
            fileType: props.fileType,
            fileSize: props.fileSize,
            fileLimit: props.fileLimit,
            directoryPath: props.directoryPath
        };

        Axios.post("/style/upload/checkFileExists", checkData)
            .then((res) => {
                const checkingData = res.data;
                if (checkingData.limitError == "Yes") {
                    setModal({
                        error: true,
                        header: "File Limit Error",
                        message: `You can only upload ${props.fileLimit} file(s) at a time!`,
                        open: true
                    });
                } else {
                    handleFileUpload(localFiles, checkingData, files);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleFileUpload(localFiles, fileData, files) {
        const uploadFormData = new FormData();
        uploadFormData.append("directoryPath", props.directoryPath);
        const uploadConfig = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };

        localFiles.forEach((lFile) => {
            const file = fileData.files.find((file) => file.id === lFile.id);
            if (file == null) {
                uploadFormData.append("files", files[lFile.id]);
            }
        });

        Axios.post("/style/upload/uploadFiles", uploadFormData, uploadConfig)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.name === "MulterError") {
                    setModal({
                        error: true,
                        header: "File Upload: Error",
                        message: data.message,
                        open: true
                    });
                } else {
                    props.nextFunction(props.reCallData, data.uploadedFiles);
                    if (fileData.failed) {
                        setFailedFilesModal({ files: fileData.files, open: true });
                    } else {
                        setModal({
                            error: false,
                            header: "File Upload: Success!",
                            message: data.message,
                            open: true
                        });
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function hanldeOpenHiddenInput() {
        hiddenInput.current.click();
    }

    return (
        <div>
            <div style={UploadComponentStyles.body}>
                <Button onClick={hanldeOpenHiddenInput}>{props.btnText ? props.btnText : "Upload"}</Button>
                {props.showFileText && (
                    <strong style={UploadComponentStyles.uploadText}>
                        {props.fileSize}MB max per file, {props.fileLimit} files per upload
                        {props.fileMessage != "" && `, ${props.fileMessage}`}
                    </strong>
                )}
            </div>
            <Form.Control
                type="file"
                ref={hiddenInput}
                name="photo"
                style={UploadComponentStyles.input}
                onChange={onInputChange}
                multiple={props.multiple}
            />
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton style={modal.error ? UploadComponentStyles.errorModalColor : UploadComponentStyles.successModalColor}>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={failedFilesModal.open} onHide={handleCloseFailedFilesModal}>
                <Modal.Header closeButton style={UploadComponentStyles.filesTableheading}>
                    <Modal.Title>Upload: Failed Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        The following files could not be uploaded as to the reasons given
                        <br />
                        <table border="1px" width="100%">
                            <thead>
                                <tr>
                                    <th>File</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {failedFilesModal.files.map((file, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={UploadComponentStyles.filesTableRow}>{file.file}</td>
                                            <td style={UploadComponentStyles.filesTableRow}>{file.message}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <br />
                        <strong>Spaces in file names will be replaced by a &#39;_&#39; (underscore)</strong>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseFailedFilesModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UploadComponent;

import * as Colors from "../../../../globalStyles/administratorColors";

export const body = {
    paddingRight: "30px",
    paddingTop: "20px"
};

export const columnRight = {
    textAlign: "right"
};

export const editorial = {
    display: "flex",
    flextDirection: "row",
    flex: "1",
    alignItems: "center"
};

export const updateButton = {
    transition: "all 0.2s ease-out"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const removeCardStyle = {
    backgroundColor: "red",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
    textAlign: "center"
};

import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import Controller from "./files/controller";
import AlbumView from "./files/albumView";
import Links from "./files/links";

function PodRouter() {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <div>
            {loaded && (
                <div>
                    <Routes>
                        <Route exact path="/" element={<Controller />} />
                        <Route path="/album/:name" element={<AlbumView />} />
                        <Route path="/links" element={<Links />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;

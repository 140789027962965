import * as Colors from "../../../../globalStyles/environmentColors";

export const body = {
    padding: "30px"
};

export const settingsBtn = {
    textAlign: "right"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const editorialContainer = {
    paddingTop: "20px"
};

export const cardHeadings = {
    color: "yellow"
};

export const blogSettingsTitles = {
    margin: "auto"
};

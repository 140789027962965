import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

//COMPONENTS
import CalendarView from "./files/calendarView";
import ListView from "./files/listView";

function PodRouter() {
    const [isLoaded, setIsLoaded] = useState(false);
    const calendarSettings = useSelector((state) => state.calendar);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    return (
        <div>
            {isLoaded && (
                <div>
                    <Routes>
                        {calendarSettings.view == "calendar" && <Route path="/" element={<CalendarView />} />}
                        {calendarSettings.view == "list" && <Route path="/" element={<ListView />} />}
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;

import React from "react";
import { Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

//STYLES
import * as SlotStyles from "../styles/albumSlot";

//IMAGES
import albumImage from "../../../../../public/environmentPods/photoGallery/Ablum icon.png";

function AlbumSlot(props) {
    const navigate = useNavigate();

    function handleAlbumClick(name) {
        navigate(`./album/${name}`);
    }

    return (
        <div style={SlotStyles.cardBody} onClick={handleAlbumClick.bind(this, props.title)}>
            <div style={SlotStyles.cardPicture}>
                {props.firstPics.length == 1 && (
                    <div style={SlotStyles.albumImage1}>
                        <Image src={`/content/gallery/${props.title}/${props.firstPics[0]}`} style={SlotStyles.image} />
                    </div>
                )}
                {props.firstPics.length == 2 && (
                    <div style={SlotStyles.albumImage1}>
                        <Image src={`/content/gallery/${props.title}/${props.firstPics[1]}`} style={SlotStyles.image} />
                        <div style={SlotStyles.albumImage2}>
                            <Image src={`/content/gallery/${props.title}/${props.firstPics[0]}`} style={SlotStyles.image} />
                        </div>
                    </div>
                )}
                {props.firstPics.length == 3 && (
                    <div style={SlotStyles.albumImage1}>
                        <Image src={`/content/gallery/${props.title}/${props.firstPics[2]}`} style={SlotStyles.image} />
                        <div style={SlotStyles.albumImage2}>
                            <Image src={`/content/gallery/${props.title}/${props.firstPics[1]}`} style={SlotStyles.image} />
                            <div style={SlotStyles.albumImage2}>
                                <Image src={`/content/gallery/${props.title}/${props.firstPics[0]}`} style={SlotStyles.image} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div style={SlotStyles.cardText}>{props.title.replace("_", " ")}</div>
        </div>
    );
}

export default AlbumSlot;

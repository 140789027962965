import React, { useState, useEffect } from "react";
import { Row, Col, ListGroup, Button, Modal, Image, Dropbox, Form } from "react-bootstrap";
import Axios from "axios";
import { useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import Switch from "react-switch";
import { CompactPicker } from "react-color";
import DOMPurify from "dompurify";
import draftjsToHtml from "draftjs-to-html";
import htmlToDraftjs from "html-to-draftjs";

//COMPONENTS
import UploadComponent from "../../uploadComponent/config";

//STYLES
import * as ConfigStyles from "./styles";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

function Config() {
    const [slides, setSlides] = useState([]);
    const [buttonColor, setButtonColor] = useState("primary");

    const [selectedSlide, setSelectedSlide] = useState(-1);
    const dispatch = useDispatch();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const modalYNDefaults = {
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    };
    const [modalYN, setModalYN] = useState(modalYNDefaults);

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [buttonStyleModal, setButtonStyleModal] = useState({
        open: false,
        text: "",
        textColor: "#FFFFFF",
        borderColor: "#FFFFFF",
        backColor: "#0000FF",
        hover: {}
    });

    function handleCloseButtonStyleModal() {
        setButtonStyleModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [textColorModal, setTextColorModal] = useState(false);

    function handleTextColorModal() {
        setTextColorModal(false);
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Style Configuration - Slider"));
        loadFiles();
    }, []);

    function loadFiles() {
        Axios.post("/style/SPOD_SLIDER_ADMIN/load")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    for (const file of data.files) {
                        file.status = "current";
                        file.showBtn = file.showBtn == "true";
                    }

                    setSlides(data.files.sort((a, b) => a.orderPosition - b.orderPosition));
                }
            })
            .catch((err) => console.log(err));
    }

    function registerFiles(reCallData, files) {
        const data = { files: files };
        Axios.post("/style/SPOD_SLIDER_ADMIN/registerFiles", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    console.log(data);
                    for (const file of data.newFiles) {
                        const temp = [...slides];
                        temp.push(file);
                        setSlides(temp);
                        console.log(temp);
                    }
                }
            })
            .catch((err) => console.log(err));
    }

    function updateFile(reCallData, file) {
        const data = { file: file, deleteFile: reCallData.deleteFile, uuid: reCallData.uuid };
        Axios.post("/style/SPOD_SLIDER_ADMIN/updateImage", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    const temp = [...slides];

                    temp[data.newData.uuid].picFilename = data.newData.file;

                    setSlides(temp);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleFileOnClick(uuid) {
        setSelectedSlide(uuid);

        setEditorState(stringToDraftjs(slides[uuid].text));
    }

    function handleOpenDeleteModal() {
        setModalYN({
            open: true,
            heading: "Delete File",
            message: "Are you sure you want to delete this file?",
            acceptFunction: handleDeleteFile,
            acceptName: "Yes",
            showAccept: true,
            cancelName: "No",
            showCancel: true
        });
    }

    function handleDeleteFile() {
        setModalYN(modalYNDefaults);
        if (selectedSlide != 0) {
            const data = { uuid: slides[selectedSlide].uuid };
            Axios.post("/style/SPOD_SLIDER_ADMIN/deleteFile", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.error == "null") {
                        const temp = [...slides];
                        temp.splice(selectedSlide, 1);
                        setSlides(temp);

                        setSelectedSlide(-1);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleMoveFile(movement) {
        if (selectedSlide != -1) {
            const temp = [...slides];

            if (movement == "up") {
                if (selectedSlide != 0) {
                    temp[selectedSlide].orderPosition = parseInt(temp[selectedSlide].orderPosition) - 1;
                    temp[selectedSlide].status = "update";

                    temp[selectedSlide - 1].orderPosition = parseInt(temp[selectedSlide - 1].orderPosition) + 1;
                    temp[selectedSlide - 1].status = "update";

                    setSelectedSlide(selectedSlide - 1);
                }
            }

            if (movement == "down") {
                if (selectedSlide != slides.length - 1) {
                    temp[selectedSlide].orderPosition = parseInt(temp[selectedSlide].orderPosition) + 1;
                    temp[selectedSlide].status = "update";

                    temp[selectedSlide + 1].orderPosition = parseInt(temp[selectedSlide + 1].orderPosition) - 1;
                    temp[selectedSlide + 1].status = "update";
                    setSelectedSlide(selectedSlide + 1);
                }
            }

            setSlides(temp.sort((a, b) => a.orderPosition - b.orderPosition));
        }
    }

    const handleEditorChange = (editorState) => {
        setEditorState(editorState);
        const temp = [...slides];

        temp[selectedSlide].text = DOMPurify.sanitize(draftjsToHtml(convertToRaw(editorState.getCurrentContent())));
        temp[selectedSlide].status = "update";

        setSlides(temp);
    };

    function handleSlideShowButtonCheck(checked) {
        const temp = [...slides];

        temp[selectedSlide].showBtn = checked;
        temp[selectedSlide].status = "update";

        setSlides(temp);
    }

    function handleTextColorChange(color) {
        const temp = [...slides];

        temp[selectedSlide].textColor = color;
        temp[selectedSlide].status = "update";

        setSlides(temp);
        setTextColorModal(false);
    }

    function handleOpenTextColorModal() {
        setTextColorModal(true);
    }

    function handleColorChange(color, field) {
        setButtonStyleModal((prevState) => {
            return { ...prevState, [field]: color };
        });
    }

    function stringToDraftjs(text) {
        const blocksFromHTML = htmlToDraftjs(text);
        const { contentBlocks, entityMap } = blocksFromHTML;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        return EditorState.createWithContent(contentState);
    }

    function handleOnUpdate() {
        const data = { content: slides };
        Axios.post("/style/SPOD_SLIDER_ADMIN/updateContent", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setButtonColor("success");
                    setTimeout(() => {
                        setButtonColor("primary");
                    }, 2000);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleSlideTextChange(event) {
        const { value } = event.target;

        const temp = [...slides];

        temp[selectedSlide].btnText = value;
        temp[selectedSlide].status = "update";

        setSlides(temp);
    }

    function handleOpenButtonStyleModal() {
        let text = "Example";
        if (slides[selectedSlide].btnText != "") {
            text = slides[selectedSlide].btnText;
        }

        setButtonStyleModal((prevState) => {
            return {
                ...prevState,
                text: text,
                textColor: slides[selectedSlide].btnTextColor,
                borderColor: slides[selectedSlide].btnBorderColor,
                backColor: slides[selectedSlide].btnBackColor,
                open: true
            };
        });
    }

    function buttonStyling_ExampleButtonOnEnter() {
        setButtonStyleModal((prevState) => {
            return { ...prevState, hover: { boxShadow: "2px 2px 5px grey" } };
        });
    }

    function buttonStyling_ExampleButtonOnLeave() {
        setButtonStyleModal((prevState) => {
            return { ...prevState, hover: { boxShadow: "" } };
        });
    }

    function handleTrasparentClick() {
        setButtonStyleModal((prevState) => {
            return { ...prevState, backColor: "transparent" };
        });
    }

    function handleButtonStyleOkButton() {
        const temp = [...slides];

        temp[selectedSlide].btnTextColor = buttonStyleModal.textColor;
        temp[selectedSlide].btnBorderColor = buttonStyleModal.borderColor;
        temp[selectedSlide].btnBackColor = buttonStyleModal.backColor;
        temp[selectedSlide].status = "update";

        setSlides(temp);

        setButtonStyleModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    return (
        <div style={ConfigStyles.body}>
            <br />
            <Row>
                <Col>
                    {selectedSlide == -1 ? (
                        <UploadComponent
                            isImage={true}
                            fileType={"n/a"}
                            fileLimit={4}
                            fileSize={4}
                            multiple={true}
                            directoryPath={"mainStyle/slider"}
                            nextFunction={registerFiles}
                            fileMessage={"Images only"}
                            showFileText
                            btnText="Add Image"
                        />
                    ) : (
                        <UploadComponent
                            isImage={true}
                            fileType={"n/a"}
                            fileLimit={1}
                            fileSize={4}
                            multiple={true}
                            reCallData={{ deleteFile: slides[selectedSlide].picFilename, uuid: slides[selectedSlide].uuid }}
                            directoryPath={"mainStyle/slider"}
                            nextFunction={updateFile}
                            fileMessage={"Images only"}
                            showFileText
                            btnText="Update Image"
                        />
                    )}
                </Col>
                <Col style={ConfigStyles.contentNotice}>
                    <strong>Images are saved automatically, content saved on &apos;Update&apos; button </strong>
                </Col>
            </Row>
            <br />
            <Row>
                <Col sm={1}>
                    <div style={ConfigStyles.scrollable300}>
                        <ListGroup>
                            {slides.map((slide, index) => {
                                return (
                                    <div key={index} style={ConfigStyles.thumbnailContainer} onClick={handleFileOnClick.bind(this, index)}>
                                        <Image src={`/content/mainStyle/slider/${slide.picFilename}`} style={ConfigStyles.thumbnail} />
                                        <br />
                                    </div>
                                );
                            })}
                        </ListGroup>
                    </div>
                </Col>
                <Col sm={5}>
                    {selectedSlide != -1 && (
                        <Image src={`/content/mainStyle/slider/${slides[selectedSlide].picFilename}`} style={ConfigStyles.image} />
                    )}
                </Col>
                <Col sm={6}>
                    {selectedSlide != -1 && (
                        <div>
                            <Row>
                                <Col>
                                    Text:
                                    <Editor
                                        editorState={editorState}
                                        toolbar={{
                                            options: []
                                        }}
                                        onEditorStateChange={handleEditorChange}
                                        wrapperStyle={ConfigStyles.editorWrapper}
                                        editorStyle={ConfigStyles.editorStyle}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col style={ConfigStyles.showButtonSection}>
                                    Show Button:
                                    <Switch
                                        className="customCSS-Content-New-Item-Switch"
                                        onChange={handleSlideShowButtonCheck}
                                        checked={slides[selectedSlide].showBtn}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                    />
                                </Col>
                                <Col style={ConfigStyles.columnRight}>
                                    <Button onClick={handleOpenTextColorModal}>Text Colour</Button>
                                    <div style={{ ...ConfigStyles.colorPreview, backgroundColor: slides[selectedSlide].textColor }}></div>
                                </Col>
                            </Row>
                            <br />
                            {slides[selectedSlide].showBtn && (
                                <Row>
                                    <Col style={ConfigStyles.alignHoz}>
                                        Button Text:
                                        <Form.Control type="text" value={slides[selectedSlide].btnText} onChange={handleSlideTextChange} />
                                    </Col>
                                    <Col style={ConfigStyles.alignHoz}>
                                        <Button onClick={handleOpenButtonStyleModal}>Button Style</Button>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    )}
                </Col>
            </Row>
            <Row>
                <Col sm={1}></Col>
                <Col>
                    {selectedSlide != -1 && (
                        <div>
                            <Button onClick={handleMoveFile.bind(this, "up")}>Move Up</Button>
                            <Button onClick={handleMoveFile.bind(this, "down")}>Move Down</Button>
                            <Button onClick={handleOpenDeleteModal}>Delete</Button>
                        </div>
                    )}
                </Col>
                <Col style={ConfigStyles.columnRight}>
                    <Button onClick={handleOnUpdate} style={ConfigStyles.updateButton} variant={buttonColor}>
                        Update
                    </Button>
                </Col>
            </Row>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
            <Modal show={textColorModal} onHide={handleTextColorModal} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Text Colour</Modal.Title>
                </Modal.Header>
                <Modal.Body style={ConfigStyles.textColorModalStyle}>
                    <CompactPicker
                        color={"#000000"}
                        onChangeComplete={(color) => {
                            handleTextColorChange(color.hex);
                        }}
                    />
                </Modal.Body>
            </Modal>
            <Modal show={buttonStyleModal.open} onHide={handleCloseButtonStyleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Button Style</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <strong>Back Colour:</strong>
                            <Form.Check
                                label="Transparent"
                                checked={buttonStyleModal.backColor == "transparent"}
                                readOnly
                                onClick={handleTrasparentClick}
                            />
                            <CompactPicker
                                color={buttonStyleModal.backColor}
                                onChangeComplete={(color) => {
                                    handleColorChange(color.hex, "backColor");
                                }}
                            />
                            <br /> <br />
                            <strong>Text Colour:</strong>
                            <CompactPicker
                                color={buttonStyleModal.textColor}
                                onChangeComplete={(color) => {
                                    handleColorChange(color.hex, "textColor");
                                }}
                            />
                            <br /> <br />
                            <strong>Border Colour:</strong>
                            <CompactPicker
                                color={buttonStyleModal.borderColor}
                                onChangeComplete={(color) => {
                                    handleColorChange(color.hex, "borderColor");
                                }}
                            />
                        </Col>
                        <Col style={ConfigStyles.buttonStyle_button}>
                            <div>
                                <div
                                    style={{
                                        ...ConfigStyles.buttonStyle_btnExample,
                                        ...buttonStyleModal.hover,
                                        color: buttonStyleModal.textColor,
                                        border: `${buttonStyleModal.borderColor} solid 2px`,
                                        background: buttonStyleModal.backColor
                                    }}
                                    onMouseEnter={buttonStyling_ExampleButtonOnEnter}
                                    onMouseLeave={buttonStyling_ExampleButtonOnLeave}
                                >
                                    {buttonStyleModal.text.toUpperCase()}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleButtonStyleOkButton}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Config;

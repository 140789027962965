export const body = {
    width: "100vw",
    height: "75px",
    position: "fixed",
    left: "0",
    transition: "all 0.5s ease-out",
    zIndex: "2"
};

export const fullBody = {};

export const logoContainer = {
    padding: "10px",
    paddingLeft: "30px"
};

export const logo = {
    width: "50px",
    height: "60px"
};

export const buttonContainer = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "bold",
    cursor: "pointer"
};

export const telContainer = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "bold"
};

export const donateButton = {
    backgroundColor: "red",
    padding: "10px",
    fontSize: "20px",
    borderRadius: "25px",
    color: "white"
};

export const menuCover = {
    backgroundColor: "#FAF3F3",
    borderBottom: "3px solid #DA7F8F"
};

export const subMenuItem = {
    height: "50px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "20px",
    cursor: "pointer"
};

export const hrLine = {
    backgroundColor: "#DA7F8F",
    border: "none",
    height: "2px",
    opacity: "1"
};

export const subMenuItemHover = {
    height: "50px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "20px",
    cursor: "pointer",
    fontWeight: "bold"
};

export const menu = {
    transition: "transform 0.1s",
    position: "absolute",
    top: "10px",
    left: "10px",
    zIndex: "2",
    width: "200px"
};

export const menuTriangleHolder = {
    width: "200px",
    height: "20px",
    backgroundColor: "transparent",
    position: "relative",
    top: "-20px",
    zIndex: "0"
};

export const triangle = {
    //triangle on top of the sub menu
    width: "0",
    height: "0",
    border: "10px solid transparent",
    borderTop: "0",
    borderBottom: "20px solid #FAF3F3",
    position: "relative",
    left: "20px"
};

export const menuTriangle = {
    //little triangle next to text
    transition: "all 0.5s ease-out",
    width: "0",
    height: "0",
    border: "5px solid transparent",
    borderBottom: "0",
    position: "relative",
    left: "5px"
};

export const donateMenu = {
    transition: "transform 0.1s",
    position: "absolute",
    width: "300px"
};

export const donateTriangleHolder = {
    width: "300px",
    height: "20px",
    backgroundColor: "transparent",
    position: "relative",
    top: "-20px",
    zIndex: "0"
};

export const donateTriangle = {
    width: "0",
    height: "0",
    border: "10px solid transparent",
    borderTop: "0",
    borderBottom: "20px solid #FAF3F3",
    position: "relative",
    top: "0px",
    left: "185px",
    zIndex: "2"
};

export const donateTextBody = {
    paddingLeft: "20px"
};

export const socalSide = {
    position: "relative",
    top: "50px",
    width: "fit-content"
};

export const socialIcon = {
    width: "50px",
    height: "50px",
    fontSize: "35px",
    color: "white",
    display: "flex",
    flex: "1",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0px 10px 10px 0px",
    marginTop: "10px",
    cursor: "pointer"
};

import React, { useState } from "react";
import { Col, Image, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

//STYLES
import * as SlotStyles from "../styles/slot";

//IMAGES
import albumImage from "../../../../../public/environmentPods/photoGallery/Ablum icon.png";

function ImageSlot(props) {
    const navigate = useNavigate();

    const [over, setOver] = useState(false);

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleMouseEnter() {
        setOver(true);
    }

    function handleMouseLeave() {
        setOver(false);
    }

    function handleDelete(imageUrl) {
        setModalYN({
            heading: "Delete Image",
            message: "Are you sure you want to delete this image?",
            showAccept: true,
            acceptName: "Yes",
            acceptFunction: acceptDeleteImage.bind(this, imageUrl),
            showCancel: true,
            cancelName: "No",
            open: true
        });
    }

    function acceptDeleteImage(imageUrl) {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });

        const data = {
            imageUrl: imageUrl,
            album: props.album
        };
        Axios.post("/pods/CPOD_PHOTOGALLERY_ADMIN/deleteImage", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    props.albumload();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleOpenMoveModal(currentAlbum, imageURL) {
        props.openMoveModal(currentAlbum, imageURL);
    }

    return (
        <div style={SlotStyles.imageBody} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div style={over ? SlotStyles.imageCardTopShow : SlotStyles.imageCardTopHidden}>
                <Button onClick={handleOpenMoveModal.bind(this, props.album, props.name)}>
                    <i className="fa-solid fa-angle-right"></i>
                </Button>
                <Button onClick={handleDelete.bind(this, props.name)}>
                    <i className="fas fa-trash-alt"></i>
                </Button>
            </div>
            <div style={SlotStyles.cardPicture}>
                <br />
                <Image src={`/content/gallery/${props.album}/${props.name}`} style={SlotStyles.albumImage} />
            </div>
            <div style={SlotStyles.cardText}>{props.name}</div>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ImageSlot;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import Axios from "axios";

//COMPONENTS
import UploadComponent from "../../uploadComponent/config";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ConfigStyles from "./styles";

function Config() {
    const dispatch = useDispatch();

    const [data, setData] = useState({
        section1Text: "",
        section1Image: "",
        section2Text: "",
        section2Image: "",
        section3Text: "",
        section3Image: "",
        section4Text: "",
        section4Image: ""
    });

    const [buttonColor, setButtonColor] = useState("primary");

    const fileDetails = {
        fileSize: 8,
        fileMessage: "Images only"
    };

    const maxLength = 25;

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Style Configuration - Four Sections"));
        onLoad();
    }, []);

    function onLoad() {
        Axios.post("/style/SPOD_FOURSECTIONSONEROW_ADMIN/load")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    const temp = {
                        section1Text: data.info.section1Text,
                        section1Image: data.info.section1Image,
                        section2Text: data.info.section2Text,
                        section2Image: data.info.section2Image,
                        section3Text: data.info.section3Text,
                        section3Image: data.info.section3Image,
                        section4Text: data.info.section4Text,
                        section4Image: data.info.section4Image
                    };
                    setData(temp);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleNexFunction(reCallData, file) {
        const data = { file: file, deleteFile: reCallData.deleteFile, sectionImage: reCallData.sectionImage };
        Axios.post("/style/SPOD_FOURSECTIONSONEROW_ADMIN/nextFunction", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    onLoad();
                }
            })
            .catch((err) => console.log(err));
    }

    function handleTextChange(event) {
        const { name, value } = event.target;

        const temp = { ...data };

        temp[name] = value;

        setData(temp);
    }

    function handleUpdate() {
        const updateData = {
            values: {
                section1Text: data.section1Text,
                section2Text: data.section2Text,
                section3Text: data.section3Text,
                section4Text: data.section4Text
            }
        };

        Axios.post("/style/SPOD_FOURSECTIONSONEROW_ADMIN/updateTexts", updateData)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setButtonColor("success");
                    dispatch(GS_navSettingsActions.GetAdminMenu());
                    setTimeout(() => {
                        setButtonColor("primary");
                    }, 2000);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={ConfigStyles.body}>
            <div style={ConfigStyles.templateTitle}>Template:</div>
            <br />
            <div style={ConfigStyles.templateBorder}>
                <Row>
                    <Col style={ConfigStyles.columns}>1</Col>
                    <Col style={ConfigStyles.columns}>2</Col>
                    <Col style={ConfigStyles.columns}>3</Col>
                    <Col style={ConfigStyles.columns}>4</Col>
                </Row>
                <br />
            </div>
            <br />
            <Row>
                <Col style={ConfigStyles.columnsHeading}>Text</Col>
                <Col style={ConfigStyles.columnsHeading}>
                    Image <br /> {fileDetails.fileSize}MB max per file, {fileDetails.fileMessage}
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={ConfigStyles.textColumns}>
                        1:
                        <Form.Control type="text" name="section1Text" maxLength={maxLength} value={data.section1Text} onChange={handleTextChange} />
                    </div>
                    <div style={ConfigStyles.counter}>
                        {data.section1Text.length}/{maxLength}
                    </div>
                </Col>
                <Col style={ConfigStyles.textColumns}>
                    <UploadComponent
                        isImage={true}
                        fileType={"n/a"}
                        fileLimit={1}
                        fileSize={fileDetails.fileSize}
                        multiple={false}
                        reCallData={{ sectionImage: "section1Image", deleteFile: data.section1Image }}
                        directoryPath={"mainStyle/fourSectionsOneRow"}
                        nextFunction={handleNexFunction}
                        fileMessage={fileDetails.fileMessage}
                    />
                    {data.section1Image}
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <div style={ConfigStyles.textColumns}>
                        2:
                        <Form.Control type="text" name="section2Text" maxLength={maxLength} value={data.section2Text} onChange={handleTextChange} />
                    </div>
                    <div style={ConfigStyles.counter}>
                        {data.section2Text.length}/{maxLength}
                    </div>
                </Col>
                <Col style={ConfigStyles.textColumns}>
                    <UploadComponent
                        isImage={true}
                        fileType={"n/a"}
                        fileLimit={1}
                        fileSize={fileDetails.fileSize}
                        multiple={false}
                        reCallData={{ sectionImage: "section2Image", deleteFile: data.section2Image }}
                        directoryPath={"mainStyle/fourSectionsOneRow"}
                        nextFunction={handleNexFunction}
                        fileMessage={fileDetails.fileMessage}
                    />
                    {data.section2Image}
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <div style={ConfigStyles.textColumns}>
                        3:
                        <Form.Control type="text" name="section3Text" maxLength={maxLength} value={data.section3Text} onChange={handleTextChange} />
                    </div>
                    <div style={ConfigStyles.counter}>
                        {data.section3Text.length}/{maxLength}
                    </div>
                </Col>
                <Col style={ConfigStyles.textColumns}>
                    <UploadComponent
                        isImage={true}
                        fileType={"n/a"}
                        fileLimit={1}
                        fileSize={fileDetails.fileSize}
                        multiple={false}
                        reCallData={{ sectionImage: "section3Image", deleteFile: data.section3Image }}
                        directoryPath={"mainStyle/fourSectionsOneRow"}
                        nextFunction={handleNexFunction}
                        fileMessage={fileDetails.fileMessage}
                    />
                    {data.section3Image}
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <div style={ConfigStyles.textColumns}>
                        4: <Form.Control type="text" name="section4Text" value={data.section4Text} onChange={handleTextChange} />
                    </div>
                    <div style={ConfigStyles.counter}>
                        {data.section4Text.length}/{maxLength}
                    </div>
                </Col>
                <Col style={ConfigStyles.textColumns}>
                    <UploadComponent
                        isImage={true}
                        fileType={"n/a"}
                        fileLimit={1}
                        fileSize={fileDetails.fileSize}
                        multiple={false}
                        reCallData={{ sectionImage: "section4Image", deleteFile: data.section4Image }}
                        directoryPath={"mainStyle/fourSectionsOneRow"}
                        nextFunction={handleNexFunction}
                        fileMessage={fileDetails.fileMessage}
                    />
                    {data.section4Image}
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <div style={ConfigStyles.columnRight}>
                        <Button style={ConfigStyles.updateButton} onClick={handleUpdate} variant={buttonColor}>
                            Update
                        </Button>
                    </div>
                </Col>
                <Col></Col>
            </Row>
        </div>
    );
}

export default Config;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Axios from "axios";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ControllerStyles from "../styles/photoGallery";

//COMPONENTS
import GalleryAlbumSlot from "./albumSlot";

function Controller() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dirExist, setDirExist] = useState(false);
    const [albums, setAlbums] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const addModalDefaultValues = {
        open: false,
        title: "",
        valid: false,
        touched: false
    };

    const [addModal, setAddModal] = useState(addModalDefaultValues);

    function handleCloseAddModal() {
        setAddModal(addModalDefaultValues);
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Photo Gallery"));
        dispatch(GS_navSettingsActions.UpdateSelected("Media"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Photo Gallery"));

        load();
    }, []);

    function load() {
        Axios.post("/pods/CPOD_PHOTOGALLERY_ADMIN/checkDirectoryExist")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setDirExist(data.folderError);
                    setAlbums(data.albums);

                    setLoaded(true);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleAddAlbumClick() {
        setAddModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleAddAlbumTextChange(event) {
        const { value } = event.target;

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        setAddModal((prevState) => {
            return { ...prevState, title: value, valid: isValid };
        });
    }

    function handleAddAlubmTouched() {
        setAddModal((prevState) => {
            return { ...prevState, touched: true };
        });
    }

    function handleMoodalAddClick() {
        if (!addModal.valid) {
            setAddModal((prevState) => {
                return { ...prevState, touched: true };
            });
        } else {
            const data = { title: addModal.title.replace(" ", "_") };
            Axios.post("/pods/CPOD_PHOTOGALLERY_ADMIN/addAlbum", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.error == "null") {
                        setAlbums(data.albums);
                        setAddModal(addModalDefaultValues);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleLinksClick() {
        navigate("./links");
    }

    return (
        <div>
            {loaded && (
                <div style={ControllerStyles.body}>
                    {!dirExist ? (
                        <div style={ControllerStyles.podFolderError}>
                            The Pod&apos;s resource folder and/or the global temp folder does not exist, please contact High-View Studios
                        </div>
                    ) : (
                        <div>
                            <Row>
                                <Col style={ControllerStyles.columnRight}>
                                    <Button onClick={handleAddAlbumClick}>Add Album</Button>
                                    <Button onClick={handleLinksClick}>Links</Button>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                {albums.map((album, index) => {
                                    return (
                                        <Col md="auto" style={ControllerStyles.columnPadding} key={index}>
                                            <GalleryAlbumSlot title={album.name} pinned={album.pinned} reloadAlbums={load} />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    )}
                    <Modal show={addModal.open} onHide={handleCloseAddModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Album</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    isInvalid={addModal.touched && !addModal.valid ? true : false}
                                    type="text"
                                    onBlur={handleAddAlubmTouched}
                                    value={addModal.title}
                                    onChange={handleAddAlbumTextChange}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleMoodalAddClick}>Add</Button>
                            <Button onClick={handleCloseAddModal}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
        </div>
    );
}

export default Controller;

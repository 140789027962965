import * as ReducerActions from "../../actions/globalSettings/GS_pageSettings";

const initialState = {
    layout: [],
    editorials: [],
    showEditorials: false,
    insert: { rowID: 0, columnID: 0, colWidth: 0 },
    deletedColumns: [],

    dispatchMeth: null,

    itemsViewer_Open: false,
    itemsViewer_Menu: { title: "", backBtn: false, items: [] },
    itemsViewer_MenuHistory: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReducerActions.GS_PAGESETTINGS_UPDATE_LAYOUT:
            return { ...state, layout: action.value };
        case ReducerActions.GS_PAGESETTINGS_UPDATE_SHOWEDITORIALS:
            return { ...state, showEditorials: action.value };
        case ReducerActions.GS_PAGESETTINGS_UPDATE_EDITORIALS:
            return { ...state, editorials: action.value };
        case ReducerActions.GS_PAGESETTINGS_UPDATE_INSERTDELETED:
            return {
                ...state,
                deletedColumns: [...state.deletedColumns, action.value]
            };
        case ReducerActions.GS_PAGESETTINGS_UPDATE_ITEMVIEWER_OPEN:
            return { ...state, insert: { rowID: action.rowID, columnID: action.colID, colWidth: action.colWidth }, itemsViewer_Open: true };
        case ReducerActions.GS_PAGESETTINGS_UPDATE_ITEMVIEWER_CLOSE:
            return { ...state, itemsViewer_Open: false, itemsViewer_Menu: { title: "", backBtn: false, items: [] }, itemsViewer_MenuHistory: [] };
        case ReducerActions.GS_PAGESETTINGS_UPDATE_ITEMVIEWER_MENU:
            return { ...state, itemsViewer_Menu: action.value };
        case ReducerActions.GS_PAGESETTINGS_UPDATE_ITEMVIEWER_MENUHISTORY_ADD:
            return { ...state, itemsViewer_MenuHistory: [...state.itemsViewer_MenuHistory, action.value] };
        case ReducerActions.GS_PAGESETTINGS_UPDATE_ITEMVIEWER_MENUHISTORY_SET:
            return { ...state, itemsViewer_MenuHistory: action.value };
        default:
            return state;
    }
};

export default reducer;

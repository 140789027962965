import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";

import * as GalleryStyles from "./styles/gallery";
import * as UploadStyles from "./styles/upload";

import Media_Card from "./card";

import * as MediaActions from "../../../store/actions/media";

function Media_Gallery() {
    const media = useSelector((state) => state.media);
    const [podDirectoryExists, setPodDirectoryExists] = useState(true);
    const dispatch = useDispatch();
    const ref = useRef(null);

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: "",
        error: false
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [createDirectoryModal, setCreateDirectoryModal] = useState({
        title: "",
        open: false
    });

    function handleCreateDirectionModalClose() {
        setCreateDirectoryModal({ title: "", open: false });
    }

    let file = 0;

    useEffect(() => {
        dispatch(MediaActions.UpdateGalleryWidth(ref.current.offsetWidth));
        GetDirectoryFiles(media.currentURI);

        const handleResize = () => {
            dispatch(MediaActions.UpdateWidthRowCardTotals(ref.current.offsetWidth));
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function GetDirectoryFiles(dirPath) {
        dispatch(MediaActions.UpdateFiles([], false, false));

        const data = { directory: dirPath };
        Axios.post("/adminPods/ADMIN_MEDIA/getDirectoryFiles", data) //change to ADMIN_MEDIA
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    //NEW CHANGE
                    dispatch(MediaActions.UpdateFiles(data.files.entries, data.files.empty, data.files.subDir));
                    dispatch(MediaActions.UpdateCardAndRowTotals(data.files.entries.length));
                    dispatch(MediaActions.UpdateCurrentUri(data.directory));
                } else {
                    dispatch(MediaActions.UpdatePodDirectoryExsits("false"));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function openDirectoryModal() {
        setCreateDirectoryModal({ title: "", open: true });
    }

    function handleDirectoryTextChange(event) {
        const { value } = event.target;
        setCreateDirectoryModal((prevState) => {
            return { ...prevState, title: value };
        });
    }

    function handleCreateNewDirectory() {
        if (createDirectoryModal.title == "") {
            setModal({
                error: true,
                header: "Create Directiory",
                message: "You need to enter a directory name",
                open: true
            });
        } else {
            setCreateDirectoryModal((prevState) => {
                return { ...prevState, open: false };
            });
            const data = {
                title: createDirectoryModal.title,
                filePath: media.currentURI
            };
            Axios.post("/adminPods/ADMIN_MEDIA/createDirectory", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        //NEW CHANGE
                        dispatch(MediaActions.UpdateFiles(data.files.entries, data.files.empty, data.files.subDir));
                        dispatch(MediaActions.UpdateCardAndRowTotals(data.files.entries.length));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
    return (
        <div style={GalleryStyles.body} ref={ref}>
            <Row>
                <Col style={GalleryStyles.createDirBtn} onClick={openDirectoryModal}>
                    <Button variant="primary">Create Directory</Button>
                </Col>
            </Row>
            {media.empty ? (
                <div style={GalleryStyles.noContentMessage}>
                    <strong>You have no images or sub-directories in this directory</strong>
                    {media.subDir ? (
                        <Row>
                            {media.cardsPerRow.map((card, index) => {
                                if (index == 0) {
                                    return (
                                        <Col key={index} style={GalleryStyles.borders}>
                                            <Media_Card editable={true} show={true} up={true} getDirectoryFiles={GetDirectoryFiles} />
                                        </Col>
                                    );
                                } else {
                                    return (
                                        <Col key={index}>
                                            <Media_Card editable={true} show={false} up={true} getDirectoryFiles={GetDirectoryFiles} />
                                        </Col>
                                    );
                                }
                            })}
                        </Row>
                    ) : null}
                </div>
            ) : (
                <div>
                    {media.numberOfRows.map((row, index) => {
                        return (
                            <Row key={index}>
                                {media.cardsPerRow.map((card, index) => {
                                    const id = file;
                                    file++;

                                    let show = false;
                                    if (id < media.files.length) {
                                        return (
                                            <Col key={card} style={GalleryStyles.borders}>
                                                <Media_Card
                                                    editable={true}
                                                    show={true}
                                                    up={media.files[id].type == "GoUp"}
                                                    directory={media.files[id].type == "Directory"}
                                                    url={`/${media.currentURI}/${media.files[id].name}`}
                                                    text={media.files[id].name}
                                                    getDirectoryFiles={GetDirectoryFiles}
                                                />
                                            </Col>
                                        );
                                    } else {
                                        return (
                                            <Col key={card} style={GalleryStyles.borders}>
                                                <Media_Card editable={true} show={false} getDirectoryFiles={GetDirectoryFiles} />
                                            </Col>
                                        );
                                    }
                                })}
                            </Row>
                        );
                    })}
                </div>
            )}

            <Modal show={createDirectoryModal.open} onHide={handleCreateDirectionModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Directory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Name: <Form.Control type="text" onChange={handleDirectoryTextChange} value={createDirectoryModal.title} />
                    <br />
                    <strong>Spaces in directory names will be replaced by a &#39;_&#39; (underscore)</strong>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCreateDirectionModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCreateNewDirectory}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Media_Gallery;

import React from "react";
import { Row, Col, Card, Image } from "react-bootstrap";

//STYLES
import * as ListViewStyles from "../styles/listView";

function EventView(props) {
    const weeksType = [
        {
            id: "0",
            day: "Sunday"
        },
        {
            id: "1",
            day: "Monday",
            week: "first"
        },
        {
            id: "2",
            day: "Tuesday",
            week: "second"
        },
        {
            id: "3",
            day: "Wednesday",
            week: "third"
        },
        {
            id: "4",
            day: "Thursday",
            week: "forth"
        },
        {
            id: "5",
            day: "Friday"
        },
        {
            id: "6",
            day: "Sunday"
        }
    ];
    return (
        <div>
            <Card>
                <Card.Body>
                    <Row>
                        <Col style={ListViewStyles.title}>{props.event.title}</Col>
                    </Row>
                    <br />
                    {props.event.image == "---" ? (
                        <div>
                            <Row>
                                <Col>{props.event.description}</Col>
                                <Col>
                                    {props.event.eventType == "single" ? (
                                        <div>
                                            <strong>Date:</strong> {props.event.startDate}
                                            <br /> <br />
                                            {props.event.timeType == "allDay" ? (
                                                <div> This is an all day event</div>
                                            ) : (
                                                <div>
                                                    <strong>Time:</strong> {props.event.startTime} - {props.event.endTime}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {props.event.repeatType == "numOfWeeks" ? (
                                                <div>
                                                    <strong>Starting Date: </strong> {props.event.startDate} <br />
                                                    This event occurs every {props.event.interval} weeks until {props.event.endDate} <br /> <br />
                                                    {props.event.timeType == "allDay" ? (
                                                        <div> This is an all day event</div>
                                                    ) : (
                                                        <div>
                                                            <strong>Time:</strong> {props.event.startTime} - {props.event.endTime}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    This event occurs every {weeksType.find((type) => type.id == props.event.interval).week}{" "}
                                                    {weeksType.find((type) => type.id == props.event.dayIndex).day} until {props.event.endDate} <br />{" "}
                                                    <br />
                                                    {props.event.timeType == "allDay" ? (
                                                        <div> This is an all day event</div>
                                                    ) : (
                                                        <div>
                                                            <strong>Time:</strong> {props.event.startTime} - {props.event.endTime}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div>
                            <Row>
                                <Col style={ListViewStyles.imageContainer}>
                                    <Image src={`/content/events/${props.event.uuid}${props.event.image}`} style={ListViewStyles.image} />
                                </Col>
                                <Col>{props.event.description}</Col>
                                <Col>
                                    {props.event.eventType == "single" ? (
                                        <div>
                                            <strong>Date:</strong> {props.event.startDate}
                                            <br /> <br />
                                            {props.event.timeType == "allDay" ? (
                                                <div> This is an all day event</div>
                                            ) : (
                                                <div>
                                                    <strong>Time:</strong> {props.event.startTime} - {props.event.endTime}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {props.event.repeatType == "numOfWeeks" ? (
                                                <div>
                                                    <strong>Starting Date: </strong> {props.event.startDate} <br />
                                                    This event occurs every {props.event.interval} weeks until {props.event.endDate} <br /> <br />
                                                    {props.event.timeType == "allDay" ? (
                                                        <div> This is an all day event</div>
                                                    ) : (
                                                        <div>
                                                            <strong>Time:</strong> {props.event.startTime} - {props.event.endTime}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    This event occurs every {weeksType.find((type) => type.id == props.event.interval).week}{" "}
                                                    {weeksType.find((type) => type.id == props.event.dayIndex).day} until {props.event.endDate} <br />{" "}
                                                    <br />
                                                    {props.event.timeType == "allDay" ? (
                                                        <div> This is an all day event</div>
                                                    ) : (
                                                        <div>
                                                            <strong>Time:</strong> {props.event.startTime} - {props.event.endTime}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
}

export default EventView;

export const body = {
    paddingRight: "30px"
};

export const columns = {
    border: "1px solid black"
};

export const scrollable300 = {
    height: "350px",
    overflow: "auto",
    borderStyle: "inset"
};

export const thumbnailContainer = {
    width: "100%",
    height: "100%",
    padding: "10px"
};

export const thumbnail = {
    width: "100%",
    height: "100%",
    cursor: "pointer"
};

export const image = {
    width: "100%",
    height: "100%"
};

export const editorWrapper = {
    backgroundColor: "white",
    width: "100%",
    padding: "5px"
};

export const editorStyle = {
    minHeight: "150px",
    maxHeight: "150px",
    overflowY: "scroll",
    cursor: "text"
};

export const showButtonSection = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "10px"
};

export const columnRight = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right"
};

export const colorPreview = {
    width: "50px",
    height: "50px",
    border: "solid 1px black"
};

export const contentNotice = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
};

export const updateButton = {
    transition: "all 0.2s ease-out"
};

export const textColorModalStyle = {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "center"
};

export const alignHoz = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
};

export const buttonStyle_button = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const buttonStyle_btnExample = {
    padding: "10px",
    borderRadius: "10px",
    cursor: "pointer"
};

export const buttonStyle_btnExampleHover = {
    padding: "10px",
    borderRadius: "10px",
    cursor: "pointer",
    boxShadow: "2px 2px 5px grey"
};

import React from "react";
import { Offcanvas, Form, Dropdown, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

//ACTIONS
import * as GS_contentSettingsActions from "../../../../store/actions/globalSettings/GS_contentSettings";

//STYLES
import * as EditImageStyles from "../styles/editImage";

function EditImage(props) {
    const dispatch = useDispatch();
    const GS_contentSettings = useSelector((state) => state.GS_contentSettings);

    function handleCloseEditImage() {
        dispatch(GS_contentSettingsActions.EditImage_Close());
    }

    function handleImageSizeChange(event) {
        const { name, value } = event.target;

        dispatch(GS_contentSettingsActions.changeImageSize(GS_contentSettings.imageEdit_ID, name, value));

        props.reloadPreview();
    }

    function handleImagePositioning(event) {
        const { name } = event.target;

        dispatch(GS_contentSettingsActions.ChangeImagePositing(GS_contentSettings.imageEdit_ID, name));

        props.reloadPreview();
    }

    function handleDeleteImage() {
        dispatch(GS_contentSettingsActions.DeleteImage(GS_contentSettings.imageEdit_ID));

        dispatch(GS_contentSettingsActions.EditImage_Close());
    }

    return (
        <div>
            <Offcanvas style={EditImageStyles.canvasBody} show={GS_contentSettings.imageEdit_Open} onHide={handleCloseEditImage} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Image </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {GS_contentSettings.imageEdit_ID > -1 && (
                        <div>
                            Width:
                            <Form.Control
                                style={EditImageStyles.imageSizeBoxes}
                                type="text"
                                name="width"
                                value={GS_contentSettings.images.find((img) => img.uuid == GS_contentSettings.imageEdit_ID).width}
                                maxLength={3}
                                onChange={handleImageSizeChange}
                            />
                            <br />
                            Height:{" "}
                            <Form.Control
                                style={EditImageStyles.imageSizeBoxes}
                                type="text"
                                name="height"
                                value={GS_contentSettings.images.find((img) => img.uuid == GS_contentSettings.imageEdit_ID).height}
                                maxLength={3}
                                onChange={handleImageSizeChange}
                            />
                            <br />
                            Position:
                            <Dropdown>
                                <Dropdown.Toggle>
                                    {GS_contentSettings.images.find((img) => img.uuid == GS_contentSettings.imageEdit_ID).positioning}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item name="left" onClick={handleImagePositioning}>
                                        Left
                                    </Dropdown.Item>
                                    <Dropdown.Item name="right" onClick={handleImagePositioning}>
                                        Right
                                    </Dropdown.Item>
                                    <Dropdown.Item name="" onClick={handleImagePositioning}>
                                        None
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>{" "}
                            <br /> <br />
                            <Button onClick={handleDeleteImage}>Delete Image</Button>
                        </div>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default EditImage;

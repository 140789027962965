export const body = {
    paddingRight: "30px",
    paddingTop: "30px",
    minHeight: "90vh"
};

export const addBtn = {
    textAlign: "right"
};

export const removeBtn = {
    textAlign: "right"
};

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "axios";
import { useDispatch } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";

//STYLES
import * as PodPagesStyles from "../styles/podPages";

//ACTIONS
import * as GS_navSettingsActions from "../../../../store/actions/globalSettings/GS_navSettings";

function PodPages() {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [pages, setPages] = useState([]);

    useEffect(() => {
        const data = { code: params.pod };
        Axios.post(`/style/${params.pod}/getPodPages`, data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setPages(data.pages);

                    dispatch(GS_navSettingsActions.UpdateTitle(`Pages - ${data.title}`));
                    dispatch(GS_navSettingsActions.UpdateSelected("Pages"));
                    dispatch(GS_navSettingsActions.UpdateStatic(false));
                    dispatch(GS_navSettingsActions.UpdateStaticText(""));
                }
            })
            .catch((err) => console.log(err));
    }, []);

    function handlePageClick(pageID) {
        navigate(`../settings/${params.pod}/${pageID}`);
    }

    return (
        <div style={PodPagesStyles.body}>
            {pages.map((page, index) => {
                return (
                    <div key={index}>
                        <Card style={PodPagesStyles.cardStyle} onClick={handlePageClick.bind(this, page.uuid)}>
                            <Card.Body>
                                <Row>
                                    <Col>{page.page}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br />
                    </div>
                );
            })}
        </div>
    );
}

export default PodPages;

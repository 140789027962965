export const topImage = {
    width: "100%",
    height: "50vh"
};

export const body = {
    height: "100vh",
    backgroundColor: "#FAF3F3",
    textAlign: "center",
    paddingTop: "30px"
};

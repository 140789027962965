import React from "react";
import { Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

//STYLES
import * as SlotStyles from "../styles/imageSlot";

function ImageSlot(props) {
    const navigate = useNavigate();

    function handleAlbumClick(name) {
        navigate(`./album/${name}`);
    }

    return (
        <div style={SlotStyles.cardBody}>
            <Image src={`/content/gallery/${props.album}/${props.image}`} style={SlotStyles.image} onClick={props.onClick.bind(this, props.id)} />
        </div>
    );
}

export default ImageSlot;

import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Modal, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as AlbumViewStyles from "../styles/albumView";

//COMPONENTS
import ImageSlot from "./imageSlot";
import UploadComponent from "../../../../mainStyle/components/uploadComponent/config";

//IMAGES
import albumImage from "../../../../../public/environmentPods/photoGallery/Ablum icon.png";

function AlbumView() {
    const dispatch = useDispatch();
    const params = useParams();
    const [images, setImages] = useState([]);
    const [pinned, setPinned] = useState(false);

    const moveModalDefaults = {
        open: false,
        currentAlbum: "",
        imageURL: "",
        albums: []
    };

    const [moveModal, setMoveModal] = useState(moveModalDefaults);

    function handleCloseMoveModal() {
        setMoveModal(moveModalDefaults);
    }

    function handleOpenMoveModal(currentAlbum, imageURL) {
        console.log(currentAlbum, imageURL);
        Axios.post("/pods/CPOD_PHOTOGALLERY_ADMIN/loadAlbums")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setMoveModal((prevState) => {
                        return { ...prevState, currentAlbum, imageURL, albums: data.albums, open: true };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleMoveModal_AlbumClick(album) {
        const data = { currentAlbum: moveModal.currentAlbum, newAlbum: album, imageURL: moveModal.imageURL };
        Axios.post("/pods/CPOD_PHOTOGALLERY_ADMIN/moveImageToAnotherAlbum", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setMoveModal(moveModalDefaults);
                    loadPhotos();
                }
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle(`Photo Gallery - ${params.name.replace("_", " ")}`));
        dispatch(GS_navSettingsActions.UpdateSelected("Media"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Photo Gallery"));

        loadPhotos();
    }, []);

    function loadPhotos() {
        const data = { album: params.name };
        Axios.post("/pods/CPOD_PHOTOGALLERY_ADMIN/findAlbumPhotos", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setImages(data.files);
                    setPinned(data.pinned);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleNextFunction(reCallData, file) {
        loadPhotos();
    }

    function handleClickPin() {
        const data = { name: params.name };
        Axios.post("/pods/CPOD_PHOTOGALLERY_ADMIN/changePin", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    loadPhotos();
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={AlbumViewStyles.body}>
            <Row>
                <Col>
                    <UploadComponent
                        isImage={true}
                        fileType={"n/a"}
                        fileLimit={5}
                        fileSize={8}
                        multiple={false}
                        reCallData={null}
                        directoryPath={`gallery/${params.name}`}
                        nextFunction={handleNextFunction}
                        showFileText
                        fileMessage={"Images only"}
                    />
                </Col>
                <Col style={AlbumViewStyles.columnRight}>
                    <div style={pinned ? AlbumViewStyles.pinned : AlbumViewStyles.pin} onClick={handleClickPin}></div>
                </Col>
            </Row>
            <br />
            <Row>
                {images.map((image, index) => {
                    return (
                        <Col md="auto" style={AlbumViewStyles.columnPadding} key={index}>
                            <ImageSlot name={image} album={params.name} albumload={loadPhotos} openMoveModal={handleOpenMoveModal} />
                        </Col>
                    );
                })}
            </Row>

            {/* MODALS */}
            <Modal size="lg" show={moveModal.open} onHide={handleCloseMoveModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Move</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Select album to move the image to: <br /> <br />
                    <Row>
                        {moveModal.albums.map((album, index) => {
                            if (album != moveModal.currentAlbum) {
                                return (
                                    <Col sm={4} key={index} style={AlbumViewStyles.albumBody} onClick={handleMoveModal_AlbumClick.bind(this, album)}>
                                        <div style={AlbumViewStyles.cardPicture}>
                                            <Image src={albumImage} style={AlbumViewStyles.albumImage} />
                                        </div>
                                        <div style={AlbumViewStyles.cardText}>{album.replace("_", " ")}</div>
                                    </Col>
                                );
                            }
                        })}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseMoveModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AlbumView;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as GS_pageSettingsActions from "../../../../../store/actions/globalSettings/GS_pageSettings";
import * as MediaActions from "../../../../../store/actions/media";

function SegmentedMenu(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        BuildMenu();
    }, [props]);

    function handleAlbumClicked(title) {
        dispatch(
            GS_pageSettingsActions.SetPodToLayout(
                `Photo Gallery Album - ${title.replace("_", " ")}`,
                "false",
                "CPOD_PHOTOGALLERY_FEND",
                "_ALBUM",
                title
            )
        );

        dispatch(GS_pageSettingsActions.ItemsViewer_Close());
    }

    function handleAlbumMenu() {
        Axios.post("/pods/CPOD_PHOTOGALLERY_ADMIN/itemsViewer_loadAlbums")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    const items = [];
                    for (const album of data.menu) {
                        items.push({
                            title: album.title.replace("_", " "),
                            method: handleAlbumClicked.bind(this, album.title)
                        });
                    }

                    const menu = {
                        title: "Photo Gallery",
                        backBtn: true,
                        items: items
                    };

                    dispatch(GS_pageSettingsActions.ItemsViewer_SetMenu(menu));
                }
            })
            .catch((err) => console.log(err));
    }

    function handleOpenGallerySideBar() {
        dispatch(MediaActions.UpdateCurrentUri("gallery"));
        dispatch(MediaActions.UpdateGallerySideBarOpen("pods/CPOD_PHOTOGALLERY_ADMIN", "CPOD_PHOTOGALLERY_FEND", true));
        dispatch(GS_pageSettingsActions.ItemsViewer_Close());
    }

    function BuildMenu() {
        const items = [
            { title: "Insert an Image", method: handleOpenGallerySideBar },
            { title: "Insert an Album (Slideshow)", method: handleAlbumMenu }
        ];

        const menu = {
            title: "Photo Gallery",
            backBtn: true,
            items: items
        };

        dispatch(GS_pageSettingsActions.ItemsViewer_SetMenu(menu));
    }

    return <div></div>;
}

export default SegmentedMenu;

export const GS_PAGESETTINGS_UPDATE_LAYOUT = "GS_PAGESETTINGS_UPDATE_LAYOUT";
export const GS_PAGESETTINGS_UPDATE_EDITORIALS = "GS_PAGESETTINGS_UPDATE_EDITORIALS";
export const GS_PAGESETTINGS_UPDATE_SHOWEDITORIALS = "GS_PAGESETTINGS_UPDATE_SHOWEDITORIALS";
export const GS_PAGESETTINGS_UPDATE_INSERTDELETED = "GS_PAGESETTINGS_UPDATE_INSERTDELETED";
export const GS_PAGESETTINGS_UPDATE_ITEMVIEWER_MENU = "GS_PAGESETTINGS_UPDATE_ITEMVIEWER_MENU";
export const GS_PAGESETTINGS_UPDATE_ITEMVIEWER_OPEN = "GS_PAGESETTINGS_UPDATE_ITEMVIEWER_OPEN";
export const GS_PAGESETTINGS_UPDATE_ITEMVIEWER_CLOSE = "GS_PAGESETTINGS_UPDATE_ITEMVIEWER_CLOSE";
export const GS_PAGESETTINGS_UPDATE_ITEMVIEWER_MENUHISTORY_ADD = "GS_PAGESETTINGS_UPDATE_ITEMVIEWER_MENUHISTORY_ADD";
export const GS_PAGESETTINGS_UPDATE_ITEMVIEWER_MENUHISTORY_SET = "GS_PAGESETTINGS_UPDATE_ITEMVIEWER_MENUHISTORY_SET";

export const SetShowEditorials = (val) => {
    return {
        type: GS_PAGESETTINGS_UPDATE_SHOWEDITORIALS,
        value: val
    };
};

export const SetEditorials = (val) => {
    return {
        type: GS_PAGESETTINGS_UPDATE_EDITORIALS,
        value: val
    };
};

export const InsertNewLayoutRow = () => {
    return (dispatch, getState) => {
        const GS_pageSettings = getState().GS_pageSettings;

        const newArray = GS_pageSettings.layout;
        const newRow = {
            id: GS_pageSettings.layout.length,
            columns: [
                {
                    uuid: "null",
                    id: 0,
                    width: 12,
                    layoutTitle: "",
                    system: "false",
                    podCode: "",
                    podCodeExtra: "",
                    addInfo: "",
                    status: "create"
                }
            ]
        };

        newArray.push(newRow);

        dispatch({
            type: GS_PAGESETTINGS_UPDATE_LAYOUT,
            value: newArray
        });
    };
};

export const InsertNewLayoutRowFromTemplate = (columns) => {
    return (dispatch, getState) => {
        const GS_pageSettings = getState().GS_pageSettings;

        const newArray = GS_pageSettings.layout;
        const newRow = { id: GS_pageSettings.layout.length, columns: columns };

        newArray.push(newRow);

        dispatch({
            type: GS_PAGESETTINGS_UPDATE_LAYOUT,
            value: newArray
        });
    };
};

export const IncreaseWidth = (rowID, columnID) => {
    return (dispatch, getState) => {
        const GS_pageSettings = getState().GS_pageSettings;

        const nextColumnID = columnID + 1;
        const newColumnArray = [];

        for (const [index, col] of GS_pageSettings.layout[rowID].columns.entries()) {
            if (col.id == columnID) {
                const newCol = { ...col, width: col.width + 1 };
                newColumnArray.push(newCol);
            } else if (col.id == nextColumnID) {
                const newCol = { ...col, width: col.width - 1 };
                newColumnArray.push(newCol);
            } else {
                newColumnArray.push(col);
            }
        }

        const newArray = [];
        for (const [index, row] of GS_pageSettings.layout.entries()) {
            if (row.id == rowID) {
                const newRow = { ...row, columns: newColumnArray };
                newArray.push(newRow);
            } else {
                newArray.push(row);
            }
        }

        dispatch({
            type: GS_PAGESETTINGS_UPDATE_LAYOUT,
            value: newArray
        });
    };
};

export const DecreaseWidth = (rowID, columnID) => {
    return (dispatch, getState) => {
        const GS_pageSettings = getState().GS_pageSettings;

        const prevColumnID = columnID - 1;
        const newColumnArray = [];

        for (const [index, col] of GS_pageSettings.layout[rowID].columns.entries()) {
            if (col.id == columnID) {
                const newCol = { ...col, width: col.width + 1 };
                newColumnArray.push(newCol);
            } else if (col.id == prevColumnID) {
                const newCol = { ...col, width: col.width - 1 };
                newColumnArray.push(newCol);
            } else {
                newColumnArray.push(col);
            }
        }

        const newArray = [];
        for (const [index, row] of GS_pageSettings.layout.entries()) {
            if (row.id == rowID) {
                const newRow = { ...row, columns: newColumnArray };
                newArray.push(newRow);
            } else {
                newArray.push(row);
            }
        }

        dispatch({
            type: GS_PAGESETTINGS_UPDATE_LAYOUT,
            value: newArray
        });
    };
};

export const AddNewColumn = (rowID, columnID) => {
    return (dispatch, getState) => {
        const GS_pageSettings = getState().GS_pageSettings;

        const newColumnArray = [];
        let newID = 0;

        for (const [index, col] of GS_pageSettings.layout[rowID].columns.entries()) {
            if (col.id == columnID) {
                const editCol = { ...col, id: newID, width: col.width - 1 };
                newColumnArray.push(editCol);
                newID++;
                const newCol = {
                    uuid: "null",
                    id: newID,
                    width: 1,
                    layoutTitle: "",
                    system: "false",
                    podCode: "",
                    podCodeExtra: "",
                    addInfo: "",
                    status: "create"
                };
                newColumnArray.push(newCol);
            } else {
                newColumnArray.push({ ...col, id: newID });
            }
            newID++;
        }

        const newArray = [];
        for (const [index, row] of GS_pageSettings.layout.entries()) {
            if (row.id == rowID) {
                const newRow = { ...row, columns: newColumnArray };
                newArray.push(newRow);
            } else {
                newArray.push(row);
            }
        }

        dispatch({
            type: GS_PAGESETTINGS_UPDATE_LAYOUT,
            value: newArray
        });
    };
};

export const DeleteColumn = (rowID, columnID) => {
    return (dispatch, getState) => {
        const GS_pageSettings = getState().GS_pageSettings;

        let otherColumnID = 0;

        if (columnID == 0) {
            otherColumnID = columnID + 1;
        } else {
            otherColumnID = columnID - 1;
        }
        const otherColumnWidth = GS_pageSettings.layout[rowID].columns[columnID].width + GS_pageSettings.layout[rowID].columns[otherColumnID].width;
        const newColumnArray = [];
        let newID = 0;

        for (const [index, col] of GS_pageSettings.layout[rowID].columns.entries()) {
            if (col.id != columnID) {
                if (col.id == otherColumnID) {
                    const newCol = { ...col, id: newID, width: otherColumnWidth };
                    newColumnArray.push(newCol);
                    newID++;
                } else {
                    newColumnArray.push({ ...col, id: newID });
                    newID++;
                }
            } else {
                if (col.uuid != "null") {
                    dispatch({
                        type: GS_PAGESETTINGS_UPDATE_INSERTDELETED,
                        value: { ...col, status: "delete" }
                    });
                }
            }
        }

        const newArray = [];
        for (const [index, row] of GS_pageSettings.layout.entries()) {
            if (row.id == rowID) {
                const newRow = { ...row, columns: newColumnArray };
                newArray.push(newRow);
            } else {
                newArray.push(row);
            }
        }

        dispatch({
            type: GS_PAGESETTINGS_UPDATE_LAYOUT,
            value: newArray
        });
    };
};

export const DeleteRow = (rowID) => {
    return (dispatch, getState) => {
        const GS_pageSettings = getState().GS_pageSettings;

        const newRowArray = [];
        let newID = 0;

        for (const [index, row] of GS_pageSettings.layout.entries()) {
            if (row.id != rowID) {
                newRowArray.push({ ...row, id: newID });
                newID++;
            } else {
                if (row.columns[0].uuid != "null") {
                    dispatch({
                        type: GS_PAGESETTINGS_UPDATE_INSERTDELETED,
                        value: { ...row.columns[0], status: "delete" }
                    });
                }
            }
        }

        dispatch({
            type: GS_PAGESETTINGS_UPDATE_LAYOUT,
            value: newRowArray
        });
    };
};

export const SetPodToLayout = (layoutTitle, system, podCode, podCodeExtra, addInfo) => {
    return (dispatch, getState) => {
        const GS_pageSettings = getState().GS_pageSettings;

        const newColumnArray = [];

        for (const [index, col] of GS_pageSettings.layout[GS_pageSettings.insert.rowID].columns.entries()) {
            if (col.id == GS_pageSettings.insert.columnID) {
                const newCol = {
                    ...col,
                    layoutTitle: layoutTitle,
                    system: system,
                    podCode: podCode,
                    podCodeExtra: podCodeExtra,
                    addInfo: addInfo
                };
                newColumnArray.push(newCol);
            } else {
                newColumnArray.push(col);
            }
        }

        const newArray = [];
        for (const [index, row] of GS_pageSettings.layout.entries()) {
            if (row.id == GS_pageSettings.insert.rowID) {
                const newRow = { ...row, columns: newColumnArray };
                newArray.push(newRow);
            } else {
                newArray.push(row);
            }
        }

        dispatch({
            type: GS_PAGESETTINGS_UPDATE_LAYOUT,
            value: newArray
        });
    };
};

export const RemoveItemFromLayout = (rowID, colID) => {
    return (dispatch, getState) => {
        const GS_pageSettings = getState().GS_pageSettings;

        const newColumnArray = [];

        console.log(rowID);

        for (const [index, col] of GS_pageSettings.layout[rowID].columns.entries()) {
            if (col.id == colID) {
                const newCol = { ...col, layoutTitle: "", system: "", podCode: "", podCodeExtra: "", addInfo: "" };
                newColumnArray.push(newCol);
            } else {
                newColumnArray.push(col);
            }
        }

        const newArray = [];
        for (const [index, row] of GS_pageSettings.layout.entries()) {
            if (row.id == rowID) {
                const newRow = { ...row, columns: newColumnArray };
                newArray.push(newRow);
            } else {
                newArray.push(row);
            }
        }

        dispatch({
            type: GS_PAGESETTINGS_UPDATE_LAYOUT,
            value: newArray
        });
    };
};

export const AddLayoutFromDatabase = (layout) => {
    return {
        type: GS_PAGESETTINGS_UPDATE_LAYOUT,
        value: layout
    };
};

export const ItemsViewer_Open = (rowID, colID, colWidth) => {
    return {
        type: GS_PAGESETTINGS_UPDATE_ITEMVIEWER_OPEN,
        rowID: rowID,
        colID: colID,
        colWidth: colWidth
    };
};

export const ItemsViewer_Close = () => {
    return {
        type: GS_PAGESETTINGS_UPDATE_ITEMVIEWER_CLOSE
    };
};

export const ItemsViewer_SetMenu = (menu) => {
    return (dispatch, getState) => {
        dispatch({
            type: GS_PAGESETTINGS_UPDATE_ITEMVIEWER_MENU,
            value: menu
        });
        dispatch({
            type: GS_PAGESETTINGS_UPDATE_ITEMVIEWER_MENUHISTORY_ADD,
            value: menu
        });
    };
};

export const ItemsViewer_MenuBack = () => {
    return (dispatch, getState) => {
        const menuHistory = getState().GS_pageSettings.itemsViewer_MenuHistory;

        dispatch({
            type: GS_PAGESETTINGS_UPDATE_ITEMVIEWER_MENU,
            value: menuHistory[menuHistory.length - 2]
        });

        menuHistory.pop();

        dispatch({
            type: GS_PAGESETTINGS_UPDATE_ITEMVIEWER_MENUHISTORY_SET,
            value: menuHistory
        });
    };
};

export const body = {
    width: "100vw",
    height: "30px",
    position: "fixed",
    left: "0",
    zIndex: "2",
    backgroundColor: "#DA7F8F",
    transition: "all 0.5s ease-out"
};

export const cookieText = {
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
};

export const normalText = {
    color: "black"
};

export const overText = {
    color: "blue",
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer"
};

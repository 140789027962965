import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Form, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ControllerStyles from "../styles/controller";

function Controller() {
    const dispatch = useDispatch();
    const [reviews, setReviews] = useState([]);

    const reviewsModalDefaults = {
        open: false,
        text: "",
        counterMax: 70,
        counter: 70,
        valid: false,
        touched: false,
        edit: false,
        editUUID: ""
    };

    const [reviewsModal, setReviewsModal] = useState(reviewsModalDefaults);

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleCloseReviewsModal() {
        setReviewsModal(reviewsModalDefaults);
    }

    function handleOpenReviewsModal() {
        setReviewsModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Reviews"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Reviews"));

        onLoad();
    }, []);

    function onLoad() {
        Axios.post("/pods/CPOD_REVIEWS_ADMIN/load")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setReviews(data.reviews);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleTextChange(event) {
        const { value } = event.target;

        const counter = reviewsModal.counterMax - value.length;

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        setReviewsModal((prevState) => {
            return { ...prevState, text: value, counter: counter, valid: isValid };
        });
    }

    function handleTouched() {
        setReviewsModal((prevState) => {
            return { ...prevState, touched: true };
        });
    }

    function handleAddReview() {
        if (!reviewsModal.valid) {
            setReviewsModal((prevState) => {
                return { ...prevState, touched: true };
            });
        } else {
            const data = { review: reviewsModal.text };
            Axios.post("/pods/CPOD_REVIEWS_ADMIN/add", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        onLoad();
                        setReviewsModal(reviewsModalDefaults);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleEditReview(uuid) {
        setReviewsModal((prevState) => {
            return {
                ...prevState,
                edit: true,
                editUUID: uuid,
                text: reviews.find((review) => review.uuid == uuid).review,
                valid: true,
                counter: prevState.counterMax - reviews.find((review) => review.uuid == uuid).review.length,
                open: true
            };
        });
    }

    function handleEditUpdate() {
        if (!reviewsModal.valid) {
            setReviewsModal((prevState) => {
                return { ...prevState, touched: true };
            });
        } else {
            const data = { reviewUUID: reviewsModal.editUUID, review: reviewsModal.text };
            Axios.post("/pods/CPOD_REVIEWS_ADMIN/update", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        onLoad();
                        setReviewsModal(reviewsModalDefaults);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleDeleteReview(uuid) {
        setModalYN({
            heading: "Delete Review",
            message: "Are you sure you want to delete this Review?",
            showAccept: true,
            acceptName: "Yes",
            acceptFunction: acceptDelete.bind(this, uuid),
            showCancel: true,
            cancelName: "No",
            open: true
        });
    }

    function acceptDelete(uuid) {
        const data = { reviewUUID: uuid };
        Axios.post("/pods/CPOD_REVIEWS_ADMIN/delete", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    onLoad();
                    setModalYN((prevState) => {
                        return { ...prevState, open: false };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={ControllerStyles.body}>
            <Row>
                <Col style={ControllerStyles.columnRight}>
                    <Button onClick={handleOpenReviewsModal}>Add Review</Button>
                </Col>
            </Row>
            <br />
            {reviews.map((review, index) => {
                return (
                    <div key={index}>
                        <Row>
                            <Col>
                                <Card style={ControllerStyles.cardStyle}>
                                    <Card.Body>
                                        <Row>
                                            <Col sm={8} style={ControllerStyles.reviewText}>
                                                {review.review}
                                            </Col>
                                            <Col sm={2}>
                                                <Button variant="warning" onClick={handleEditReview.bind(this, review.uuid)}>
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </Button>
                                            </Col>
                                            <Col sm={2}>
                                                <Button variant="danger" onClick={handleDeleteReview.bind(this, review.uuid)}>
                                                    <i className="fa-solid fa-trash-can"></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br />
                    </div>
                );
            })}

            <Modal show={reviewsModal.open} onHide={handleCloseReviewsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{reviewsModal.edit ? "Edit Review" : "Add Review"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Control
                            as="textarea"
                            isInvalid={reviewsModal.touched && !reviewsModal.valid ? true : false}
                            maxLength={70}
                            rows={3}
                            value={reviewsModal.text}
                            onChange={handleTextChange}
                            onBlur={handleTouched}
                        />
                        <Row>
                            <Col>
                                {reviewsModal.touched && !reviewsModal.valid && <div style={ControllerStyles.errorText}>Please enter text!</div>}
                            </Col>
                            <Col>
                                <p style={ControllerStyles.counterContainer}>{reviewsModal.counter}</p>
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {reviewsModal.edit ? <Button onClick={handleEditUpdate}>Update</Button> : <Button onClick={handleAddReview}>Add</Button>}
                    <Button onClick={handleCloseReviewsModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Controller;

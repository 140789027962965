export const body = {
    paddingRight: "30px"
};

export const columns = {
    border: "1px solid black"
};

export const scrollable200 = {
    height: "200px",
    overflow: "auto"
};

export const image = {
    width: "100%",
    height: "50vh"
};

export const body = {
    // textAlign: "center",
    marginTop: "20px",
    paddingRight: "30px"
};

export const textSize = {
    fontSize: "2rem"
};

export const editorWrapper = {
    backgroundColor: "white",
    width: "100%"
};

export const editorStyle = {
    minHeight: "250px",
    maxHeight: "250px",
    overflowY: "scroll",
    cursor: "text"
};

export const newItemTitle = {
    display: "flex"
};

export const newItemTitleText = {
    marginRight: "5px"
};

export const saveButton = {
    textAlign: "right"
};

export const insertImageButton = {
    textAlign: "right"
};

export const switchBtn = {
    paddingLeft: "20px"
};

export const settings = {
    paddingRight: "20px"
};

export const scrollable250 = {
    height: "250px",
    overflow: "auto",
    overflowX: "hidden"
};

export const mediaCanvas = {
    width: "300px"
};

export const tabControlledArea = {
    paddingTop: "25px",
    paddingLeft: "10px",
    paddingRight: "10px"
};

export const tab = {
    backgroundColor: "white",
    textAlign: "center",
    fontSize: "1.5rem",
    borderRadius: "25px 25px 0px 0px",
    borderBottom: "2px solid",
    cursor: "pointer"
};

export const selectedTab = {
    textAlign: "center",
    fontSize: "1.5rem",
    borderRadius: "25px 25px 0px 0px",
    backgroundColor: "#163172",
    color: "white",
    fontWeight: "bold",
    borderLeft: "2px solid black",
    borderRight: "2px solid black",
    borderTop: "2px solid black"
};

export const tabContent = {
    backgroundColor: "white",
    height: "350px",
    borderBottom: "2px solid",
    borderLeft: "2px solid",
    borderRight: "2px solid",
    paddingTop: "10px"
};

export const previewContainer = {
    border: "2px solid",
    padding: "10px"
};

export const hidden = {
    visibility: "hidden"
};

export const imageNotFound = {
    color: "red"
};

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Modal, Form, Card } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as LinksStyles from "../styles/links";

function Links() {
    const dispatch = useDispatch([]);
    const [links, setLinks] = useState([]);

    const modalDefaultValues = {
        open: false,
        values: {
            name: "",
            url: ""
        },
        valids: {
            name: false,
            url: false
        },
        touched: {
            name: false,
            url: false
        },
        wholeFrom: false,
        edit: false,
        selectedId: 0
    };
    const [modal, setModal] = useState(modalDefaultValues);

    function handleCloseModal() {
        setModal(modalDefaultValues);
    }

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelFunction: "",
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Photo Gallery - Links"));
        dispatch(GS_navSettingsActions.UpdateSelected("Media"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Photo Gallery"));

        loadLinks();
    }, []);

    function loadLinks() {
        Axios.post("/pods/CPOD_PHOTOGALLERY_ADMIN/getAllLinks")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setLinks(data.links);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleOpenAddLink() {
        setModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleOnTouched(event) {
        const { name } = event.target;

        const temp = { ...modal.touched };

        temp[name] = true;

        setModal((prevState) => {
            return { ...prevState, touched: temp };
        });
    }

    function handleTextChange(event) {
        const { name, value } = event.target;

        const values = { ...modal.values };
        const valids = { ...modal.valids };

        values[name] = value;

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        valids[name] = isValid;

        let isWholeForm = false;
        if (valids.name && valids.url) {
            isWholeForm = true;
        }

        setModal((prevState) => {
            return { ...prevState, values, valids, wholeFrom: isWholeForm };
        });
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(modal.touched).map((k) => ({ [k]: true })));
        setModal((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    function handleAdd() {
        if (!modal.wholeFrom) {
            switchAllTouches();
        } else {
            const data = { name: modal.values.name, url: modal.values.url };
            Axios.post("/pods/CPOD_PHOTOGALLERY_ADMIN/insertLink", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setModal(modalDefaultValues);
                        loadLinks();
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleUpdateClick(id, name, url) {
        const modalEditDefaultValues = {
            open: true,
            values: {
                name: name,
                url: url
            },
            valids: {
                name: true,
                url: true
            },
            touched: {
                name: false,
                url: false
            },
            wholeFrom: true,
            edit: true,
            selectedId: id
        };

        setModal(modalEditDefaultValues);
    }

    function handleUpdate() {
        if (!modal.wholeFrom) {
            switchAllTouches();
        } else {
            const data = { uuid: modal.selectedId, name: modal.values.name, url: modal.values.url };
            Axios.post("/pods/CPOD_PHOTOGALLERY_ADMIN/updateLink", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setModal(modalDefaultValues);
                        loadLinks();
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleDelete(uuid) {
        setModalYN({
            open: true,
            heading: "Delete Link?",
            message: "Are you sure you want to delete this link?",
            acceptFunction: handleDeleteSubmit.bind(this, uuid),
            acceptName: "Yes",
            showAccept: true,
            cancelFunction: handleModalYNClose,
            cancelName: "No",
            showCancel: true
        });
    }

    function handleDeleteSubmit(uuid) {
        const data = { uuid: uuid };
        Axios.post("/pods/CPOD_PHOTOGALLERY_ADMIN/deleteLink", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setModalYN((prevState) => {
                        return { ...prevState, open: false };
                    });
                    loadLinks();
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={LinksStyles.body}>
            <Row>
                <Col style={LinksStyles.columnRight}>
                    <Button onClick={handleOpenAddLink}>Add Link</Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Card style={LinksStyles.cardStyleHeadings}>
                        <Card.Body>
                            <Row>
                                <Col sm={3}>Name</Col>
                                <Col sm={5}>URL</Col>
                                <Col sm={2}>Edit</Col>
                                <Col sm={2}>Delete</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    {links.length > 0 ? (
                        <div>
                            {links.map((link, index) => {
                                return (
                                    <div key={index}>
                                        <Card style={LinksStyles.cardStyle}>
                                            <Card.Body>
                                                <Row>
                                                    <Col sm={3} style={LinksStyles.cardText}>
                                                        {link.name}
                                                    </Col>
                                                    <Col sm={5} style={LinksStyles.cardText}>
                                                        {link.url}
                                                    </Col>
                                                    <Col sm={2} style={LinksStyles.cardText}>
                                                        <Button
                                                            variant="warning"
                                                            onClick={handleUpdateClick.bind(this, link.uuid, link.name, link.url)}
                                                        >
                                                            <i className="fa-solid fa-pen-to-square"></i>
                                                        </Button>
                                                    </Col>
                                                    <Col sm={2} style={LinksStyles.cardText} onClick={handleDelete.bind(this, link.uuid)}>
                                                        <Button variant="danger">
                                                            <i className="fa-solid fa-trash-can"></i>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        <br />
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div style={LinksStyles.noLinksText}>You have no links</div>
                    )}
                </Col>
            </Row>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.edit ? "Edit Link" : "Add Link"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={3}>Name:</Col>
                        <Col>
                            <Form.Group>
                                <Form.Control
                                    isInvalid={modal.touched.name && !modal.valids.name ? true : false}
                                    type="text"
                                    name="name"
                                    value={modal.values.name}
                                    onChange={handleTextChange}
                                    onBlur={handleOnTouched}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a name!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={3}>URL:</Col>
                        <Col>
                            <Form.Group>
                                <Form.Control
                                    isInvalid={modal.touched.url && !modal.valids.url ? true : false}
                                    type="text"
                                    name="url"
                                    value={modal.values.url}
                                    onChange={handleTextChange}
                                    onBlur={handleOnTouched}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a name!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>{modal.edit ? <Button onClick={handleUpdate}>Update</Button> : <Button onClick={handleAdd}>Add</Button>}</Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.cancelFunction}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Links;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Card, Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ControllerStyles from "../styles/controller";

function Controller() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [archivedEvents, setArchivedEvents] = useState([]);
    const [showArchivedEvents, setShowArchivedEvents] = useState(false);

    const [settingsModal, setSettingsModal] = useState({
        open: false,
        view: "calendar",
        backInTime: "numOfWeeks",
        backInTimeNum: "0",
        showEventPictures: true,
        sliderView: "all"
    });

    function handleCloseSettingsModal() {
        setSettingsModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Calendar"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Calendar"));
        getEvents();
    }, []);

    function getEvents() {
        Axios.post("/pods/calendar/getEvents")
            .then((res) => {
                const data = res.data;
                console.log(data);
                setEvents(data.events);
                setArchivedEvents(data.archivedEvents);

                let backInTime: "";
                let backInTimeNum: "";

                if (data.settings.backInTime != "unlimited") {
                    backInTime = "numOfWeeks";
                    backInTimeNum = data.settings.backInTime;
                } else {
                    backInTime = data.settings.backInTime;
                }

                setSettingsModal((prevState) => {
                    return {
                        ...prevState,
                        view: data.settings.view,
                        backInTime: backInTime,
                        backInTimeNum: backInTimeNum,
                        showEventPictures: data.settings.showEventPictures == true,
                        sliderView: data.settings.sliderView
                    };
                });
            })
            .catch((err) => console.log(err));
    }

    function handleNewEventClick() {
        navigate("./newEvent");
    }
    function handleEventClick(id) {
        navigate(`./modifyEvent/${id}`);
    }

    function handleOpenSettingsModal() {
        setSettingsModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleChangeEventView(event) {
        const { name } = event.target;

        setSettingsModal((prevState) => {
            return { ...prevState, view: name };
        });
    }

    function handleChangeBackInTime(event) {
        const { name } = event.target;

        let backInTimeNumber = "";
        if (name == "numOfWeeks") {
            backInTimeNumber = "0";
        }

        setSettingsModal((prevState) => {
            return { ...prevState, backInTime: name, backInTimeNum: backInTimeNumber };
        });
    }

    function handleNumOfWeeksChange(event) {
        const { value } = event.target;

        setSettingsModal((prevState) => {
            return { ...prevState, backInTimeNum: value };
        });
    }

    function handleChangeShowEventPictures(event) {
        const { checked } = event.target;

        setSettingsModal((prevState) => {
            return { ...prevState, showEventPictures: checked };
        });
    }

    function handleUpdateSettings() {
        const data = {
            view: settingsModal.view,
            backInTime: settingsModal.backInTime == "numOfWeeks" ? settingsModal.backInTimeNum : settingsModal.backInTime,
            showEventPictures: settingsModal.showEventPictures.toString(),
            sliderView: settingsModal.sliderView
        };
        Axios.post("/pods/calendar/updateSettings", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setSettingsModal((prevState) => {
                        return { ...prevState, open: false };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleShowArchivedEvents(value) {
        setShowArchivedEvents(value);
    }

    function handleDeleteAllArchiveEvents() {
        setModalYN({
            heading: "Delete Events?",
            message: "Are you sure you want to all the archieved events?",
            showAccept: true,
            acceptName: "Yes",
            acceptFunction: handleDeleteAllArchiveEvents_Accept,
            showCancel: true,
            cancelName: "No",
            open: true
        });
    }

    function handleDeleteAllArchiveEvents_Accept() {
        Axios.post("/pods/calendar/deleteAllArchievedEvents")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setModalYN((prevState) => {
                        return { ...prevState, open: false };
                    });
                    setShowArchivedEvents(false);
                    getEvents();
                }
            })
            .catch((err) => console.log(err));
    }

    function handleChangeSliderView(event) {
        const { name } = event.target;

        setSettingsModal((prevState) => {
            return { ...prevState, sliderView: name };
        });
    }

    return (
        <div style={ControllerStyles.body}>
            <Row>
                <Col style={ControllerStyles.newEventBtn}>
                    <Button onClick={handleOpenSettingsModal}>Settings</Button>
                    {showArchivedEvents ? (
                        <Button variant="danger" onClick={handleDeleteAllArchiveEvents}>
                            Delete All
                        </Button>
                    ) : (
                        <Button onClick={handleNewEventClick}>New Event</Button>
                    )}
                </Col>
            </Row>
            <Row style={ControllerStyles.topRow}>
                <Col>
                    <Card style={ControllerStyles.cardBodyHeadings}>
                        <Card.Body>
                            <Row>
                                <Col>Title</Col>
                                <Col>Start Date</Col>
                                <Col>Start Time</Col>
                                <Col>End Time</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    {archivedEvents.length > 0 && !showArchivedEvents ? (
                        <div>
                            <Card style={ControllerStyles.cardBody} onClick={handleShowArchivedEvents.bind(this, true)}>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <strong>Archived Events</strong>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <br />
                        </div>
                    ) : (
                        <div>
                            {archivedEvents.length > 0 && (
                                <div>
                                    <Card style={ControllerStyles.cardBody} onClick={handleShowArchivedEvents.bind(this, false)}>
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <strong>Present Events</strong>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    <br />
                                </div>
                            )}
                        </div>
                    )}

                    <div style={ControllerStyles.eventsBox}>
                        {showArchivedEvents ? (
                            <div>
                                {archivedEvents.map((event, index) => {
                                    return (
                                        <div key={index}>
                                            <Card style={ControllerStyles.cardBody} onClick={handleEventClick.bind(this, event.uuid)}>
                                                <Card.Body>
                                                    <Row>
                                                        <Col>{event.title}</Col>
                                                        <Col>{event.startDate}</Col>
                                                        <Col>{event.timeType == "allDay" ? "All Day" : event.startTime}</Col>
                                                        <Col>{event.timeType != "allDay" && event.endTime}</Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                            <br />
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div>
                                {events.map((event, index) => {
                                    return (
                                        <div key={index}>
                                            <Card style={ControllerStyles.cardBody} onClick={handleEventClick.bind(this, event.uuid)}>
                                                <Card.Body>
                                                    <Row>
                                                        <Col>{event.title}</Col>
                                                        <Col>{event.startDate}</Col>
                                                        <Col>{event.timeType == "allDay" ? "All Day" : event.startTime}</Col>
                                                        <Col>{event.timeType != "allDay" && event.endTime}</Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                            <br />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <Modal show={settingsModal.open} onHide={handleCloseSettingsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>Public View:</Col>
                        <Col>
                            <Form.Check
                                type="radio"
                                label="List"
                                name="list"
                                checked={settingsModal.view == "list"}
                                onChange={handleChangeEventView}
                            />
                            <Form.Check
                                type="radio"
                                label="calendar"
                                checked={settingsModal.view == "calendar"}
                                name="calendar"
                                onChange={handleChangeEventView}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>Calendar: Users can go back </Col>
                        <Col>
                            <Form.Check
                                type="radio"
                                label="Unlimited weeks"
                                name="unlimited"
                                onChange={handleChangeBackInTime}
                                checked={settingsModal.backInTime == "unlimited"}
                            />
                            <Row>
                                <Col sm={1} style={ControllerStyles.radioBtnCenter}>
                                    <Form.Check
                                        type="radio"
                                        label=" "
                                        name="numOfWeeks"
                                        onChange={handleChangeBackInTime}
                                        checked={settingsModal.backInTime == "numOfWeeks"}
                                    />
                                </Col>
                                <Col style={ControllerStyles.numOfWeeksContainer}>
                                    <Form.Control
                                        type="text"
                                        value={settingsModal.backInTimeNum}
                                        maxLength={2}
                                        style={ControllerStyles.numOfWeeksTextBox}
                                        onChange={handleNumOfWeeksChange}
                                    />
                                    months
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>Event Slider on Front Page</Col>
                        <Col>
                            <Form.Check
                                label="Show events with pictures only"
                                checked={settingsModal.showEventPictures}
                                onChange={handleChangeShowEventPictures}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>Slider:</Col>
                        <Col>
                            <Form.Check
                                type="radio"
                                label="View all events"
                                name="all"
                                checked={settingsModal.sliderView == "all"}
                                onChange={handleChangeSliderView}
                            />
                            <Form.Check
                                type="radio"
                                label="View only the current week's events"
                                checked={settingsModal.sliderView == "showWeek"}
                                name="showWeek"
                                onChange={handleChangeSliderView}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleUpdateSettings}>Update</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Controller;

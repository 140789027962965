import React, { useState, useEffect } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as HalfPhotoStyles from "./styles";

//COMPONENTS
import EnvironmentPodsMap from "../../../../pods/environmentPodsMap";

function HalfPhoto() {
    const [article, setArticle] = useState({
        title: "",
        content: ""
    });
    const [image, setImage] = useState("");

    useEffect(() => {
        Axios.post("/style/halfPhoto/load")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setArticle({
                        title: data.info.title,
                        content: data.info.content
                    });
                    setImage(data.info.image);
                }
            })
            .catch((err) => console.log(err));
    }, []);
    return (
        <div style={HalfPhotoStyles.body}>
            <Row>
                <Col>
                    <Image src={`/content/mainStyle/halfPhoto/${image}`} style={HalfPhotoStyles.image} />
                </Col>
                <Col style={HalfPhotoStyles.textHalf}>
                    <div>
                        <h2>{article.title}</h2> <br />
                        {/* <p>{article.content}</p>
                        <Button variant="danger">Donate Now</Button>
                        <Button variant="light">Read More</Button> */}
                        <EnvironmentPodsMap podCode="CPOD_PDFLIBRARY_FREND" />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default HalfPhoto;

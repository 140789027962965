export const body = {
    display: "flex",
    flexDirection: "row",
    flex: "1",
    alignItems: "center"
};

export const errorModalColor = {
    color: "red"
};

export const successModalColor = {
    color: "green"
};

export const filesTableRow = {
    padding: "5px"
};

export const filesTableheading = {
    color: "red"
};

export const input = {
    display: "none"
};

export const uploadText = {
    paddingLeft: "5px"
};

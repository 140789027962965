import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as MediaActions from "../../../../store/actions/media";
import * as GS_contentSettingsActions from "../../../../store/actions/globalSettings/GS_contentSettings";
import * as GS_pageSettingsActions from "../../../../store/actions/globalSettings/GS_pageSettings";

//STYLES
import * as GallerySideBarStyles from "./styles/gallerySideBar";

//COMPONENTS
import Media_Card from "../../../../administration/pods/media/card";

function GallerySideBar(props) {
    const media = useSelector((state) => state.media);
    const GS_contentSettings = useSelector((state) => state.GS_contentSettings);
    const dispatch = useDispatch();

    useEffect(() => {
        if (media.gallerySideBar_open) {
            GetDirectoryFiles(media.currentURI);
        }
    }, [media.gallerySideBar_open]);

    function GetDirectoryFiles(dirPath) {
        dispatch(MediaActions.UpdateFiles([], false, false));

        const data = { directory: dirPath };
        Axios.post(`/${media.gallerySideBar_apiLocation}/getDirectoryFiles`, data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    //NEW CHANGE
                    dispatch(MediaActions.UpdateFiles(data.files.entries, data.files.empty, data.files.subDir));
                    dispatch(MediaActions.UpdateCurrentUri(data.directory));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleCloseSideBar() {
        dispatch(MediaActions.CloseGallerySideBar());
    }

    function handleInsertImage(url) {
        if (media.gallerySideBar_pod == "ADMIN_EDITORIALS") {
            dispatch(GS_contentSettingsActions.UpdateImageURL(GS_contentSettings.id, url));
        }
        if (media.gallerySideBar_pod == "ADMIN_PAGESETTINGS_INSERTIMAGE") {
            const data = { filePath: url };
            Axios.post("/adminPods/ADMIN_MEDIA/getPathFileName", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        dispatch(GS_pageSettingsActions.SetPodToLayout(`Image - ${data.fileName}`, "true", "ADMIN_IMAGE", "", url));
                    }
                })
                .catch((err) => console.log(err));
        }
        if (media.gallerySideBar_pod == "CPOD_PHOTOGALLERY_FEND") {
            const data = { filePath: url };
            Axios.post("/adminPods/ADMIN_MEDIA/getPathFileName", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        dispatch(
                            GS_pageSettingsActions.SetPodToLayout(
                                `Photo Gallery Image - ${data.fileName}`,
                                "false",
                                media.gallerySideBar_pod,
                                "_IMAGE",
                                url
                            )
                        );
                    }
                })
                .catch((err) => console.log(err));
        }
        dispatch(MediaActions.CloseGallerySideBar());
    }

    return (
        <div>
            <Offcanvas style={GallerySideBarStyles.canvasBody} show={media.gallerySideBar_open} onHide={handleCloseSideBar} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Media - Choose an image </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {media.empty ? (
                        <div>
                            {media.subDir ? (
                                <div>
                                    <Row>
                                        <Col>
                                            <Media_Card
                                                editable={false}
                                                show={true}
                                                up={true}
                                                getDirectoryFiles={GetDirectoryFiles}
                                                insertImageClick={handleInsertImage}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div>You haven&#39;t got any images uploaded yet, go to the media section to upload some</div>
                            )}
                        </div>
                    ) : (
                        <div>
                            {media.files.map((file, index) => {
                                return (
                                    <Row key={index}>
                                        <Col>
                                            <Media_Card
                                                editable={false}
                                                show={true}
                                                up={file.type == "GoUp"}
                                                directory={file.type == "Directory"}
                                                url={`/${media.currentURI}/${file.name}`}
                                                text={file.name}
                                                getDirectoryFiles={GetDirectoryFiles}
                                                insertImageClick={handleInsertImage}
                                            />
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default GallerySideBar;

import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import * as MediaActions from "../../../store/actions/media";

function MediaCanvas() {
    useEffect(() => {
        // getDirectoryFiles(media.currentURI);
        // console.log("Open");
    }, []);
    const dispatch = useDispatch();
    const media = useSelector((state) => state.media);

    return <div></div>;
}

export default MediaCanvas;

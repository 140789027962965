import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import DOMPurify from "dompurify";
import HTMLParse from "html-react-parser";
import { useSelector } from "react-redux";

//STYLES
import * as NavBarStyles from "./styles";

//IMAGES
import CrossImageWhite from "./images/cross2.png";
import CrossImageBlack from "./images/cross.png";

//COMPONENTS
import PageNavigation from "./navMethods";
import CookieBanner from "../../../../pods/cookieBanner/cookieBanner";

function NavBar() {
    const navigate = useNavigate();
    const GS_generalSettings = useSelector((state) => state.GS_generalSettings);
    const NavBarSettings = useSelector((state) => state.NavBar);

    const [top, setTop] = useState(0);
    const [cookieBanner, setCookieBanner] = useState(1);

    const [data, setData] = useState({
        donateMenuText: "",
        donate_option1Name: "",
        donate_option1URL: "",
        donate_option2Name: "",
        donate_option2URL: "",
        socialLinks_facebook: "",
        socialLinks_youtube: ""
    });
    const [navColor, setNavColor] = useState("transparent");
    const [aboutMenuScale, setAboutMenuScale] = useState(0);
    const [aboutMenuPosition, setAboutMenuPosition] = useState({
        x: 0,
        y: 0
    });

    const [donateMenuScale, setDonateMenuScale] = useState(0);
    const [donateMenuPosition, setDonateMenuPosition] = useState({
        x: 0,
        y: 0
    });

    const [photoGalleryMenuScale, setPhotoGalleryMenuScale] = useState(0);
    const [photoGalleryMenuPosition, setPhotoGalleryMenuPosition] = useState({
        x: 0,
        y: 0
    });

    const [aboutTriangle, setAboutTriangle] = useState(0);
    const [photoGalleryTriangle, setPhotoGalleryTriangle] = useState(0);

    const [aboutMenuItems, setAboutMenuItems] = useState([
        {
            title: "St Giles'",
            nav: "about-stGiles",
            hovering: false
        },
        {
            title: "Worship",
            nav: "about-worship",
            hovering: false
        },
        {
            title: "Visitor Information",
            nav: "about-visitorInfomation",
            hovering: false
        },
        {
            title: "How to find us",
            nav: "about-findUs",
            hovering: false
        }
    ]);

    const [donateMenuItems, setDonateMenuItems] = useState([
        {
            hovering: false
        },
        {
            hovering: false
        }
    ]);

    const [photoGalleryMenuShow, setPhotoGalleryMenuShow] = useState(false);

    const [photoGalleryItems, setPhotoGalleryItems] = useState({
        albums: [],
        links: []
    });

    const aboutMenu = useRef(null);
    const donateMenu = useRef(null);
    const photoGalleryMenu = useRef(null);

    useEffect(() => {
        window.addEventListener("scroll", checkHeight);

        setAboutMenuPosition({
            x: aboutMenu.current.getBoundingClientRect().x,
            y: 75
        });

        setPhotoGalleryMenuPosition({
            x: photoGalleryMenu.current.getBoundingClientRect().x,
            y: 75
        });

        setDonateMenuPosition({
            x: 30,
            y: 90
        });

        Axios.post("/style/navBar/frontEnd/load")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    console.log(data);
                    setData(data.info);
                    setCookieBanner(data.cookieBanner);

                    if (data.gallery_albums.length > 0 || data.gallery_links.length > 0) {
                        setPhotoGalleryMenuShow(true);
                        const albums = [];
                        const links = [];
                        if (data.gallery_albums.length > 0) {
                            for (const album of data.gallery_albums) {
                                albums.push({
                                    title: album.name.replace("_", " "),
                                    nav: album.name,
                                    hovering: false
                                });
                            }
                            albums.push({
                                title: "Show All Albums",
                                nav: "viewAll",
                                hovering: false
                            });
                        }
                        for (const link of data.gallery_links) {
                            links.push({
                                title: link.name,
                                nav: link.url,
                                hovering: false
                            });
                        }

                        setPhotoGalleryItems({ albums, links });
                    }
                }
            })
            .catch((err) => console.log(err));

        return () => {
            window.removeEventListener("scroll", checkHeight);
        };
    }, []);

    function checkHeight() {
        if (window.scrollY > 0) {
            setNavColor("rgba(60, 54, 51,0.5)");
        } else {
            setNavColor("transparent");
        }
    }

    function handleAboutOnMouseEnter() {
        setAboutMenuScale(1);
        setAboutTriangle(180);
    }

    function handleAboutOnMouseLeave() {
        setAboutMenuScale(0);
        setAboutTriangle(0);
    }

    function handleDonateOnMouseEnter() {
        setDonateMenuScale(1);
    }

    function handleDonateOnMouseLeave() {
        setDonateMenuScale(0);
    }

    function handlePhotoGalleryOnMouseEnter() {
        setPhotoGalleryMenuScale(1);
        setPhotoGalleryTriangle(180);
    }

    function handlePhotoGalleryOnMouseLeave() {
        setPhotoGalleryMenuScale(0);
        setPhotoGalleryTriangle(0);
    }

    function handleOnMouseEnter_AboutMenu(id) {
        const menu = { ...aboutMenuItems };

        menu[id].hovering = true;

        setAboutMenuItems(menu);
    }

    function handleOnMouseLeave_AboutMenu(id) {
        const menu = { ...aboutMenuItems };

        menu[id].hovering = false;

        setAboutMenuItems(menu);
    }

    function handleOnMouseEnter_DonateMenu(id) {
        const menu = { ...donateMenuItems };

        menu[id].hovering = true;

        setDonateMenuItems(menu);
    }

    function handleOnMouseLeave_DonateMenu(id) {
        const menu = { ...donateMenuItems };

        menu[id].hovering = false;

        setDonateMenuItems(menu);
    }

    function handleOnMouseEnter_PhotoGalleryMenu(type, id) {
        const menu = [...photoGalleryItems[type]];

        menu[id].hovering = true;

        setPhotoGalleryItems((prevState) => {
            return { ...prevState, [type]: menu };
        });
    }

    function handleOnMouseLeave_PhotoGalleryMenu(type, id) {
        const menu = [...photoGalleryItems[type]];

        menu[id].hovering = false;

        setPhotoGalleryItems((prevState) => {
            return { ...prevState, [type]: menu };
        });
    }

    function handleLinkSecondPageClick() {
        navigate("./secondPage");
    }

    function handleLinkClick_about(id) {
        PageNavigation(navigate, aboutMenuItems[id].nav);
    }

    function handleOpenLink(link) {
        if (link.includes("http")) {
            window.open(link, "_blank");
        } else {
            window.open(`https://${link}`, "_blank");
        }
    }

    function handleLinkClick(nav) {
        PageNavigation(navigate, nav);
    }

    function handleMenuItemLinkClick_PhotoGallery(url) {
        console.log(url);
        if (url.includes("https://") || url.includes("http://")) {
            window.open(url, "_blank");
        } else {
            window.open(`https://${url}`, "_blank");
        }
    }

    function handleMenuItemAlbumClick_PhotoGallery(nav) {
        if (nav == "viewAll") {
            navigate("./photo-gallery");
        } else {
            navigate(`./photo-gallery/album/${nav}`);
        }
    }

    function handleChangeTop(direction) {
        if (direction == "up") {
            setTop(0);
        }
        if (direction == "down") {
            setTop(30);
        }
    }

    return (
        <div>
            <CookieBanner changeTop={handleChangeTop} show={cookieBanner} />
            <div style={{ ...NavBarStyles.body, backgroundColor: navColor, top: `${top}px` }}>
                <Row>
                    <Col sm={1} style={NavBarStyles.logoContainer}>
                        <Image src={NavBarSettings.textColor == "white" ? CrossImageWhite : CrossImageBlack} style={NavBarStyles.logo} />
                    </Col>
                    <Col sm={2}>
                        <div className="titleFont" style={{ color: NavBarSettings.textColor }}>
                            St Giles&rsquo;
                        </div>
                    </Col>
                    <Col
                        sm={1}
                        style={{ ...NavBarStyles.buttonContainer, color: NavBarSettings.textColor }}
                        onClick={handleLinkClick.bind(this, "home")}
                    >
                        Home
                    </Col>
                    <Col
                        ref={aboutMenu}
                        sm={1}
                        style={{ ...NavBarStyles.buttonContainer, color: NavBarSettings.textColor }}
                        onMouseEnter={handleAboutOnMouseEnter}
                        onMouseLeave={handleAboutOnMouseLeave}
                    >
                        About
                        <div
                            style={{
                                ...NavBarStyles.menuTriangle,
                                borderTop: `5px solid ${NavBarSettings.textColor}`,
                                transform: `rotate(${aboutTriangle}deg)`
                            }}
                        ></div>
                    </Col>
                    <Col
                        sm={1}
                        style={{ ...NavBarStyles.buttonContainer, color: NavBarSettings.textColor }}
                        onClick={handleLinkClick.bind(this, "events")}
                    >
                        Events
                    </Col>
                    <Col
                        ref={photoGalleryMenu}
                        sm={2}
                        style={{ ...NavBarStyles.buttonContainer, color: NavBarSettings.textColor }}
                        onClick={photoGalleryMenuShow ? null : handleLinkClick.bind(this, "photo-gallery")}
                        onMouseEnter={photoGalleryMenuShow ? handlePhotoGalleryOnMouseEnter : null}
                        onMouseLeave={photoGalleryMenuShow ? handlePhotoGalleryOnMouseLeave : null}
                    >
                        Photo Gallery
                        {photoGalleryMenuShow && (
                            <div
                                style={{
                                    ...NavBarStyles.menuTriangle,
                                    borderTop: `5px solid ${NavBarSettings.textColor}`,
                                    transform: `rotate(${photoGalleryTriangle}deg)`
                                }}
                            ></div>
                        )}
                    </Col>
                    {GS_generalSettings.mainContactPageVisible && (
                        <Col
                            sm={1}
                            style={{ ...NavBarStyles.buttonContainer, color: NavBarSettings.textColor }}
                            onClick={handleLinkClick.bind(this, "contactUs")}
                        >
                            Contact Us
                        </Col>
                    )}

                    <Col style={{ ...NavBarStyles.telContainer, color: NavBarSettings.textColor }}>
                        <i className="fa-solid fa-phone"></i>&nbsp;&nbsp; 01978 355808
                    </Col>
                    <Col
                        ref={donateMenu}
                        style={NavBarStyles.buttonContainer}
                        onMouseEnter={handleDonateOnMouseEnter}
                        onMouseLeave={handleDonateOnMouseLeave}
                    >
                        <div style={{ ...NavBarStyles.donateButton, border: `3px solid ${NavBarSettings.textColor}` }}>Donate</div>
                    </Col>
                </Row>
                {/* SOCIAL LINKS */}
                <div style={NavBarStyles.socalSide}>
                    <div
                        style={{ ...NavBarStyles.socialIcon, backgroundColor: "blue" }}
                        onClick={handleOpenLink.bind(this, data.socialLinks_facebook)}
                    >
                        <i className="fa-brands fa-facebook"></i>
                    </div>
                    <div style={{ ...NavBarStyles.socialIcon, backgroundColor: "red" }} onClick={handleOpenLink.bind(this, data.socialLinks_youtube)}>
                        <i className="fa-brands fa-youtube"></i>
                    </div>
                </div>
                {/* SUB MENUS */}
                {aboutMenu != null && (
                    <div
                        style={{
                            ...NavBarStyles.menu,
                            top: `${aboutMenuPosition.y}px`,
                            left: `${aboutMenuPosition.x}px`,
                            transform: `scale(${aboutMenuScale})`
                        }}
                        onMouseEnter={handleAboutOnMouseEnter}
                        onMouseLeave={handleAboutOnMouseLeave}
                    >
                        <div style={NavBarStyles.menuCover}>
                            <div style={NavBarStyles.menuTriangleHolder}>
                                <div style={NavBarStyles.triangle}></div>
                            </div>
                            <Row>
                                <Col
                                    style={aboutMenuItems[0].hovering ? NavBarStyles.subMenuItemHover : NavBarStyles.subMenuItem}
                                    onMouseEnter={handleOnMouseEnter_AboutMenu.bind(this, 0)}
                                    onMouseLeave={handleOnMouseLeave_AboutMenu.bind(this, 0)}
                                    onClick={handleLinkClick_about.bind(this, 0)}
                                >
                                    {aboutMenuItems[0].title}
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={aboutMenuItems[1].hovering ? NavBarStyles.subMenuItemHover : NavBarStyles.subMenuItem}
                                    onMouseEnter={handleOnMouseEnter_AboutMenu.bind(this, 1)}
                                    onMouseLeave={handleOnMouseLeave_AboutMenu.bind(this, 1)}
                                    onClick={handleLinkClick_about.bind(this, 1)}
                                >
                                    {aboutMenuItems[1].title}
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={aboutMenuItems[2].hovering ? NavBarStyles.subMenuItemHover : NavBarStyles.subMenuItem}
                                    onMouseEnter={handleOnMouseEnter_AboutMenu.bind(this, 2)}
                                    onMouseLeave={handleOnMouseLeave_AboutMenu.bind(this, 2)}
                                    onClick={handleLinkClick_about.bind(this, 2)}
                                >
                                    {aboutMenuItems[2].title}
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={aboutMenuItems[3].hovering ? NavBarStyles.subMenuItemHover : NavBarStyles.subMenuItem}
                                    onMouseEnter={handleOnMouseEnter_AboutMenu.bind(this, 3)}
                                    onMouseLeave={handleOnMouseLeave_AboutMenu.bind(this, 3)}
                                    onClick={handleLinkClick_about.bind(this, 3)}
                                >
                                    {aboutMenuItems[3].title}
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
                {donateMenu != null && (
                    <div
                        style={{
                            ...NavBarStyles.donateMenu,
                            top: `${donateMenuPosition.y}px`,
                            right: `${donateMenuPosition.x}px`,
                            transform: `scale(${donateMenuScale})`
                        }}
                        onMouseEnter={handleDonateOnMouseEnter}
                        onMouseLeave={handleDonateOnMouseLeave}
                    >
                        <div style={NavBarStyles.menuCover}>
                            <div style={NavBarStyles.donateTriangleHolder}>
                                <div style={NavBarStyles.donateTriangle}></div>
                            </div>
                            <Row>
                                <Col style={NavBarStyles.donateTextBody}>{HTMLParse(DOMPurify.sanitize(data.donateMenuText))}</Col>
                            </Row>
                            <Row>
                                <Col
                                    style={donateMenuItems[0].hovering ? NavBarStyles.subMenuItemHover : NavBarStyles.subMenuItem}
                                    onMouseEnter={handleOnMouseEnter_DonateMenu.bind(this, 0)}
                                    onMouseLeave={handleOnMouseLeave_DonateMenu.bind(this, 0)}
                                    onClick={handleOpenLink.bind(this, data.donate_option1URL)}
                                >
                                    {data.donate_option1Name}
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={donateMenuItems[1].hovering ? NavBarStyles.subMenuItemHover : NavBarStyles.subMenuItem}
                                    onMouseEnter={handleOnMouseEnter_DonateMenu.bind(this, 1)}
                                    onMouseLeave={handleOnMouseLeave_DonateMenu.bind(this, 1)}
                                    onClick={handleOpenLink.bind(this, data.donate_option2URL)}
                                >
                                    {data.donate_option2Name}
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
                {photoGalleryMenuShow && photoGalleryMenu != null && (
                    <div
                        style={{
                            ...NavBarStyles.menu,
                            top: `${photoGalleryMenuPosition.y}px`,
                            left: `${photoGalleryMenuPosition.x}px`,
                            transform: `scale(${photoGalleryMenuScale})`
                        }}
                        onMouseEnter={handlePhotoGalleryOnMouseEnter}
                        onMouseLeave={handlePhotoGalleryOnMouseLeave}
                    >
                        <div style={NavBarStyles.menuCover}>
                            <div style={NavBarStyles.menuTriangleHolder}>
                                <div style={NavBarStyles.triangle}></div>
                            </div>
                            {photoGalleryItems.albums.length > 0 && (
                                <div>
                                    <Row>
                                        <Col style={NavBarStyles.subMenuItem}>
                                            <strong>Albums</strong>:
                                        </Col>
                                    </Row>
                                    {photoGalleryItems.albums.map((album, index) => {
                                        return (
                                            <Row key={index}>
                                                <Col
                                                    style={album.hovering ? NavBarStyles.subMenuItemHover : NavBarStyles.subMenuItem}
                                                    onMouseEnter={handleOnMouseEnter_PhotoGalleryMenu.bind(this, "albums", index)}
                                                    onMouseLeave={handleOnMouseLeave_PhotoGalleryMenu.bind(this, "albums", index)}
                                                    onClick={handleMenuItemAlbumClick_PhotoGallery.bind(this, album.nav)}
                                                >
                                                    {album.title}
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </div>
                            )}
                            {photoGalleryItems.albums.length > 0 && photoGalleryItems.links.length > 0 && <hr style={NavBarStyles.hrLine} />}
                            {photoGalleryItems.links.length > 0 && (
                                <div>
                                    <Row>
                                        <Col style={NavBarStyles.subMenuItem}>
                                            <strong>Links</strong>:
                                        </Col>
                                    </Row>
                                    {photoGalleryItems.links.map((link, index) => {
                                        console.log(link);
                                        return (
                                            <Row key={index}>
                                                <Col
                                                    style={link.hovering ? NavBarStyles.subMenuItemHover : NavBarStyles.subMenuItem}
                                                    onMouseEnter={handleOnMouseEnter_PhotoGalleryMenu.bind(this, "links", index)}
                                                    onMouseLeave={handleOnMouseLeave_PhotoGalleryMenu.bind(this, "links", index)}
                                                    onClick={handleMenuItemLinkClick_PhotoGallery.bind(this, link.nav)}
                                                >
                                                    {link.title}
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </div>
                            )}

                            {/* <Col
                            // onClick={handleLinkClick_about.bind(this, 0)}
                            >
                                {aboutMenuItems[0].title}
                            </Col> */}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default NavBar;

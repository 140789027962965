import * as Colors from "../../../../globalStyles/administratorColors";

export const body = {
    paddingRight: "30px"
};

export const newEventBtn = {
    textAlign: "right"
};

export const formTitles = {
    fontSize: "14px"
};

export const cardBody = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const cardBodyHeadings = {
    backgroundColor: Colors.primary,
    color: "white",
    fontWeight: "bold"
};

export const topRow = {
    paddingTop: "20px"
};

export const eventsBox = {
    height: "500px",
    overflow: "auto"
};

export const radioBtnCenter = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
};

export const numOfWeeksTextBox = {
    width: "50px"
};

export const numOfWeeksContainer = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "5px"
};

import React from "react";
import { Image } from "react-bootstrap";

//STYLES
import * as PodViewsStyles from "../styles/podViews";

//COMPONETS
import AlbumSlideShow from "./albumSlideShow";

function PodViews(props) {
    return (
        <div>
            {props.podCodeExtra == "_IMAGE" && (
                <div>
                    <Image src={`/content/${props.addInfo}`} style={PodViewsStyles.image} />
                </div>
            )}
            {props.podCodeExtra == "_ALBUM" && <AlbumSlideShow album={props.addInfo} />}
        </div>
    );
}

export default PodViews;

export const cardBody = {
    width: "170px",
    height: "150px",
    padding: "10px",
    textAlign: "center"
};

export const image = {
    width: "130px",
    height: "130px",
    cursor: "pointer"
};

import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as ViewerStyles from "../styles/viewer";

function PDFLibraryViewer() {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        onStart();
    }, []);

    function onStart() {
        Axios.post("/pods/pdfLibraryFE/getFiles")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setFiles(data.files);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleOnClick(filename) {
        window.open(`./content/PDFLibrary/${filename}`, "_blank");
    }

    function handleMoveHover(uuid) {
        console.log(uuid);
    }

    return (
        <div>
            <Row>
                <Col sm={3} style={ViewerStyles.headingDate}>
                    Date
                </Col>
                <Col style={ViewerStyles.headingName}>Name</Col>
            </Row>
            {files.map((file, index) => {
                return (
                    <Row key={index}>
                        <Col sm={3} className="pdfLibary_dateCell" onClick={handleOnClick.bind(this, file.filename)}>
                            {file.date}
                        </Col>
                        <Col className="pdfLibary_fileCell" onClick={handleOnClick.bind(this, file.filename)}>
                            {file.filename}
                        </Col>
                    </Row>
                );
            })}
        </div>
    );
}

export default PDFLibraryViewer;

import React from "react";
import { Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

//STYLES
import * as SlotStyles from "../styles/slot";

//IMAGES
import albumImage from "../../../../../public/environmentPods/photoGallery/Ablum icon.png";

function AlbumSlot(props) {
    const navigate = useNavigate();

    function handleAlbumClick(name) {
        navigate(`./album/${name}`);
    }

    function handlePinClicked(name) {
        const data = { name: name };
        Axios.post("/pods/CPOD_PHOTOGALLERY_ADMIN/changePin", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    props.reloadAlbums();
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={SlotStyles.cardBody}>
            <div style={SlotStyles.cardTopBackground} onClick={handleAlbumClick.bind(this, props.title)}></div>
            <div style={SlotStyles.cardPinBackground} onClick={handlePinClicked.bind(this, props.title)}>
                <div style={props.pinned ? SlotStyles.pinned : SlotStyles.pin}></div>
            </div>
            <div style={SlotStyles.cardMainBackground} onClick={handleAlbumClick.bind(this, props.title)}>
                <div style={SlotStyles.cardPicture}>
                    <Image src={albumImage} style={SlotStyles.albumImage} />
                </div>
                <div style={SlotStyles.cardText}>{props.title.replace("_", " ")}</div>
            </div>
        </div>
    );
}

export default AlbumSlot;

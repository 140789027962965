import React, { useState, useEffect } from "react";
import Axios from "axios";
import DOMPurify from "dompurify";
import HTMLParse from "html-react-parser";

//STYLES
import * as Styles from "./styles";

function SimpleSectionCenteredText() {
    const [editorialUUID, setEditorialUUID] = useState(0);
    const [editorial, setEditorial] = useState([]);
    const [images, setImages] = useState([]);

    useEffect(() => {
        Axios.post("/style/simpleSectionCenteredText/frontEnd/load")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    console.log(data);
                    setEditorial(data.editorial);
                    setImages(data.images);
                    setEditorialUUID(data.editorialUUID);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    function LoadEditorial(id) {
        let editorialText = editorial.content;

        if (images != null) {
            for (const image of images) {
                console.log(image.imageID);
                editorialText = editorialText.replace(
                    `{${image.imageID}}`,
                    `<img style="max-width:100%; max-height:100%; float:${image.positioning}; margin-right:10px; margin-bottom:10px;" src="/content/${image.url}" width=${image.width} height=${image.height} />`
                );
            }
        }
        return editorialText;
    }

    return (
        <div style={Styles.body}>{editorialUUID != 0 && <div style={Styles.innerText}>{HTMLParse(DOMPurify.sanitize(LoadEditorial()))}</div>}</div>
    );
}

export default SimpleSectionCenteredText;

export const body = {
    overflow: "hidden",
    width: "100%",
    border: "1px solid black",
    position: "relative"
};

export const imageContainer = {
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
    position: "absolute"
};
